import {
  InputLabel,
  Select,
  InputAdornment,
  FormControl,
  TextField,
} from "@material-ui/core";
import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  besA,
  besB,
  besC,
  besP,
  besW,
  stufenA,
  stufenB,
  stufenC,
  stufenW,
  stufenP,
} from "../helper/besoldung";
import { grundg, zulage } from "../helper/functions";
import { useState, useRef, useEffect } from "react";
import NumberFormat from "react-number-format";
import moment from "moment";

const Sonderzahlung = () => {
  const [detail, setDetail] = useState([
    {
      status: "Pfarrer",
      beg: "",
      besO: "A",
      besG: "14",
      stufe: 11,
      umfang: 100,
      monate: 12,
      einmal: "0",
      umlageP: 40,
      fzE: "0",
      rv: "0",
      guthaben: "0",
      abschlag: "0",
      neuerAbschlag: "0",
    },
  ]);

  const [bezug, setBezug] = useState([
    {
      grundg: 0,
      fz: 0,
      struk: 0,
      rgfB: 0,
      rgfB_Teil: 0,
      zulagen: [],
      sumZulagen: 0,
      anzZul: 1,
    },
  ]);

  const [data, setData] = useState([
    {
      id: 0,
      name: "",
      fzPer: "0",
      fzK: "0",
      titel: "",
      birthDate: "",
      fam: "",
      geschl: "",
      bearbeiter: "Grotzki",
      bei: "",
    },
  ]);

  const [abrechnung, setAbrechnung] = useState({
    insgesamt: 0,
    jahreswert: 0,
    umlageE: 0,
    nettoUmlage: 0,
    rest: 0,
  });

  const [anzahlSpalten, setAnzahlSpalten] = useState([{ id: 0 }]);
  const [changeDetail, setChangeDetail] = useState(false);
  const [teilzeitVorhanden, setTeilzeitVorhanden] = useState(false);
  const [weitereZulagenVorhanden, setWeitereZulagenVorhanden] = useState(false);
  const [kinderVorhanden, setKinderVorhanden] = useState(false);

  const handleDetailChange = (e) => {
    let id = 0;
    if (e.target.id !== "0") {
      id = +e.target.id;
    } else {
      id = 0;
    }
    if (e.target.name === "umlageP") {
      detail[0] = { ...detail[0], umlageP: +e.target.value };
      setDetail([...detail]);
    } else if (e.target.name === "stufe") {
      detail[id] = { ...detail[id], stufe: +e.target.value };
      setDetail([...detail]);
    } else if (e.target.name === "umfang") {
      if (+e.target.value > 0 && +e.target.value < 100) {
        setTeilzeitVorhanden(true);
      } else {
        setTeilzeitVorhanden(false);
      }
      detail[id] = { ...detail[id], umfang: +e.target.value };
      setDetail([...detail]);
    } else if (e.target.name === "monate") {
      detail[id] = { ...detail[id], monate: +e.target.value };
      setDetail([...detail]);
    } else if (e.target.name === "besO") {
      if (e.target.value === "B") {
        detail[id] = { ...detail[id], [e.target.name]: e.target.value };
        detail[id] = { ...detail[id], stufe: 1 };
        setDetail([...detail]);
      } else {
        detail[id] = { ...detail[id], [e.target.name]: e.target.value };
        detail[id] = { ...detail[id], stufe: 0 };
        setDetail([...detail]);
      }
    } else {
      detail[id] = { ...detail[id], [e.target.name]: e.target.value };
      setDetail([...detail]);
    }
  };

  const handleBezugChange = (e) => {
    const { name, value, id } = e.target;

    bezug[id].zulagen = { [name]: +value };
    const newBezug = [...bezug];
    setBezug(newBezug);
    if (+value > 0) {
      setWeitereZulagenVorhanden(true);
    } else {
      setWeitereZulagenVorhanden(false);
    }
  };

  const handleDataChange = (e) => {
    let id = 0;
    if (e.target.id !== "0") {
      id = +e.target.id;
    } else {
      id = 0;
    }
    if (e.target.name === "fzK") {
      data[id] = { ...data[id], fzK: e.target.value };
      setData([...data]);
      if (e.target.value > "0") {
        setKinderVorhanden(true);
      } else {
        setKinderVorhanden(false);
      }
    } else if (e.target.name === "name") {
      data[0] = { ...data[0], name: e.target.value };
      setData([...data]);
    } else if (e.target.name === "bei") {
      data[0] = { ...data[0], bei: e.target.value };
      setData([...data]);
    } else if (e.target.name === "bearbeiter") {
      data[0] = { ...data[0], bearbeiter: e.target.value };
      setData([...data]);
    } else {
      data[id] = { ...data[id], [e.target.name]: e.target.value };
      setData([...data]);
    }
  };

  const getStufen = (id) => {
    let Stufen = "";
    if (detail[id].besO === "A") {
      Stufen = stufenA.filter((value, index) => {
        return value.gruppe === detail[id].besG;
      });
    } else if (detail[id].besO === "B") {
      Stufen = stufenB.filter((value, index) => {
        return value.gruppe === detail[id].besG;
      });
    } else if (detail[id].besO === "C") {
      Stufen = stufenC.filter((value, index) => {
        return value.gruppe === detail[id].besG;
      });
    } else if (detail[id].besO === "W") {
      Stufen = stufenW.filter((value, index) => {
        return value.gruppe === detail[id].besG;
      });
    } else if (detail[id].besO === "P") {
      Stufen = stufenP.filter((value, index) => {
        return value.gruppe === detail[id].besG;
      });
    }

    return Stufen.length > 0 ? Stufen[0].stufe : 0;
  };

  const sumZulage = useRef(0);

  const calcBez = (id) => {
    const grundgehalt = grundg(
      detail[id].besO + detail[id].besG,
      detail[id].stufe,
      detail[id].beg
    );
    const fam = zulage(
      "fz1",
      detail[id].beg,
      detail[id].besO + detail[id].besG
    );
    const strZulage = zulage(
      "struk",
      detail[id].beg,
      detail[id].besO + detail[id].besG
    );
    const newFzPer = data[id].fzPer.replace(",", ".");

    bezug[id] = {
      ...bezug[id],
      grundg: grundgehalt ? +grundgehalt["betrag"] : 0,
      fz: Math.round(fam["betrag"] * newFzPer) / 100,
      struk:
        strZulage &&
        detail[id].besO === "A" &&
        +detail[id].besG < 12 &&
        detail[id].status === "RelPäd"
          ? strZulage["betrag"]
          : strZulage &&
            detail[id].besO === "A" &&
            +detail[id].besG < 14 &&
            detail[id].status !== "RelPäd"
          ? strZulage["betrag"]
          : 0,
    };
    setBezug([...bezug]);
  };

  const calcSZ = (id, rgfB_Teil) => {
    const fzE = detail[0].fzE.replace(",", ".");
    let faktor = 65;
    let grundbetrag = 0;
    let erhöhung = 0;
    let sonder = 0;

    if (detail[id].besO === "A" && +detail[id].besG < 12) {
      faktor = 70;
    }

    faktor = faktor / 100;

    grundbetrag =
      Math.round(
        (((rgfB_Teil - +fzE) * faktor * detail[id].monate) / 12) * 100
      ) / 100;
    grundbetrag =
      grundbetrag +
      Math.round(((+fzE * 0.8429 * detail[id].monate) / 12) * 100) / 100;

    if (detail[id].besO === "A" && +detail[id].besG < 9) {
      erhöhung = 8.33;
    }

    erhöhung = erhöhung * 12;

    sonder = data[id].fzK * detail[id].monate * 2.13;

    return grundbetrag + erhöhung + sonder;
  };

  const calc = (id) => {
    //summe aller zulagen bilden
    for (let index = 0; index < bezug[id].anzZul; index++) {
      //string in zahl umwandeln
      const string = bezug[id].zulagen[`betrag${index + 1}`];
      const numbString = string && string.replace(",", ".");
      sumZulage.current += +numbString;
    }
    const fzE = detail[id].fzE.replace(",", ".");
    const rgfB =
      Math.round(
        (bezug[id].grundg +
          +fzE +
          bezug[id].struk +
          (!sumZulage.current ? 0 : sumZulage.current)) *
          100
      ) / 100;
    const rgfB_Teil =
      Math.round(
        (Math.round((rgfB - +fzE) * detail[id].umfang * 100) / 100 / 100 +
          +fzE) *
          100
      ) / 100;
    const summeMitMonate =
      Math.round(rgfB_Teil * detail[id].monate * 100) / 100;
    const sz = calcSZ(id, rgfB_Teil);
    const insgesamt = Math.round((summeMitMonate + sz) * 100) / 100;

    bezug[id].rgfB = rgfB;
    bezug[id].rgfB_Teil = rgfB_Teil;
    bezug[id].sumZulagen = !sumZulage.current ? 0 : sumZulage.current;
    bezug[id].summeMitMonate = summeMitMonate;
    bezug[id].sz = sz;
    bezug[id].insgesamt = insgesamt;
    const newBezug = [...bezug];
    setBezug(newBezug);
    sumZulage.current = 0;
  };

  //Bezüge berechnen wenn sich was in details und/oder data geändert hat
  useEffect(() => {
    for (let index = 0; index < anzahlSpalten.length; index++) {
      calcBez(index);
    }
    setChangeDetail(!changeDetail);
  }, [detail, data]);

  //Wenn sich etwas bei den Bezügen geändert hat neue rgf. Bezüge (Summe) berechnen
  useEffect(() => {
    for (let index = 0; index < anzahlSpalten.length; index++) {
      calc(index);
    }
  }, [
    changeDetail,
    detail[0].rv,
    detail[0].umlageP,
    detail[0].einmal,
    detail[0].guthaben,
    detail[0].abschlag,
  ]);

  //Gesamtsummen bilden
  useEffect(() => {
    let insgesamt = 0;
    for (let index = 0; index < anzahlSpalten.length; index++) {
      insgesamt += bezug[index].insgesamt;
    }
    const einmal = detail[0].einmal.replace(",", ".");
    const jahreswert = +insgesamt + +einmal;
    const umlageE =
      Math.round(((jahreswert * detail[0].umlageP) / 100) * 100) / 100;
    const rv = detail[0].rv.replace(",", ".");
    const nettoUmlage = umlageE - rv;
    const guthaben = detail[0].guthaben.replace(",", ".");
    const abschlag = detail[0].abschlag.replace(",", ".");
    const rest = nettoUmlage - guthaben - abschlag;
    const neuerAbschlag = detail[0].neuerAbschlag.replace(",", ".");
    setAbrechnung({
      ...abrechnung,
      insgesamt: Math.round(insgesamt * 100) / 100,
      jahreswert: jahreswert,
      umlageE: umlageE,
      nettoUmlage: nettoUmlage,
      rest: rest,
    });
  }, [anzahlSpalten, bezug]);

  return (
    <div>
      {console.log(teilzeitVorhanden)}
      <Row className="print-bottom mb-2">
        <Col>Beginn</Col>
        <Col>
          <TextField
            className="d-print-none"
            size="small"
            //disabled={view}
            error={
              detail[0].beg === "" ||
              detail[0].beg === "Invalid date" ||
              detail[0].beg === "0000-00-00"
                ? true
                : false
            }
            required
            variant="outlined"
            id="0"
            label="Beginn"
            type="date"
            name="beg"
            value={detail[0].beg}
            onChange={handleDetailChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <div className="d-none d-print-flex">
            {moment(detail[0].beg).format("DD.MM.YYYY")}
          </div>
        </Col>
        {anzahlSpalten.map((spalte) => {
          if (spalte.id >= 1) {
            return (
              <Col key={spalte.id}>
                <TextField
                  className="d-print-none"
                  size="small"
                  //disabled={view}
                  error={
                    detail[spalte.id].beg === "" ||
                    detail[spalte.id].beg === "Invalid date" ||
                    detail[spalte.id].beg === "0000-00-00"
                      ? true
                      : false
                  }
                  required
                  variant="outlined"
                  id={`${spalte.id}`}
                  label="Beginn"
                  type="date"
                  name="beg"
                  value={detail[spalte.id].beg}
                  onChange={handleDetailChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <div className="d-none d-print-flex">
                  {moment(detail[spalte.id].beg).format("DD.MM.YYYY")}
                </div>
              </Col>
            );
          }
        })}
      </Row>
      <Row className="print-bottom mb-2">
        <Col>Besoldungsordnung</Col>
        <Col>
          <FormControl
            className="d-print-none"
            size="small"
            required
            style={{ minWidth: "200px" }}
            variant="outlined"
          >
            <InputLabel>Besoldungsordnung</InputLabel>
            <Select
              id="0"
              //disabled={view}
              error={!detail[0].besO ? true : false}
              native
              label="Besoldungsordnung"
              name="besO"
              value={detail[0].besO}
              onChange={handleDetailChange}
            >
              <option aria-label="None" value="" />
              <option value={"A"}>A</option>
              <option value={"B"}>B</option>
              <option value={"C"}>C</option>
              <option value={"P"}>P</option>
              <option value={"W"}>W</option>
            </Select>
          </FormControl>
          <div className="d-none d-print-flex">{detail[0].besO}</div>
        </Col>
        {anzahlSpalten.map((spalte) => {
          if (spalte.id >= 1) {
            return (
              <Col key={spalte.id}>
                <FormControl
                  className="d-print-none"
                  size="small"
                  required
                  style={{ minWidth: "200px" }}
                  variant="outlined"
                >
                  <InputLabel>Besoldungsordnung</InputLabel>
                  <Select
                    id={`${spalte.id}`}
                    //disabled={view}
                    error={!detail[spalte.id].besO ? true : false}
                    native
                    label="Besoldungsordnung"
                    name="besO"
                    value={detail[spalte.id].besO}
                    onChange={handleDetailChange}
                  >
                    <option aria-label="None" value="" />
                    <option value={"A"}>A</option>
                    <option value={"B"}>B</option>
                    <option value={"C"}>C</option>
                    <option value={"P"}>P</option>
                    <option value={"W"}>W</option>
                  </Select>
                </FormControl>
                <div className="d-none d-print-flex">
                  {detail[spalte.id].besO}
                </div>
              </Col>
            );
          }
        })}
      </Row>
      <Row className="print-bottom mb-2">
        <Col>Besoldungsgruppe</Col>
        <Col>
          <FormControl
            className="d-print-none"
            size="small"
            style={{ minWidth: "200px" }}
            required
            variant="outlined"
          >
            <InputLabel>Besoldungsgruppe</InputLabel>
            <Select
              id="0"
              //disabled={view}
              error={!detail[0].besG ? true : false}
              native
              label="Besoldungsgruppe"
              name="besG"
              value={detail[0].besG}
              onChange={handleDetailChange}
            >
              <option aria-label="None" value="" />
              {detail[0].besO === "A" ? (
                besA.map((x, y) => (
                  <option value={x < 10 ? "0" + x : x} key={y}>
                    {x}
                  </option>
                ))
              ) : detail[0].besO === "B" ? (
                besB.map((x, y) => (
                  <option value={x < 10 ? "0" + x : x} key={y}>
                    {x}
                  </option>
                ))
              ) : detail[0].besO === "C" ? (
                besC.map((x, y) => (
                  <option value={x < 10 ? "0" + x : x} key={y}>
                    {x}
                  </option>
                ))
              ) : detail[0].besO === "P" ? (
                besP.map((x, y) => (
                  <option value={x < 10 ? "0" + x : x} key={y}>
                    {x}
                  </option>
                ))
              ) : detail[0].besO === "W" ? (
                besW.map((x, y) => (
                  <option value={x < 10 ? "0" + x : x} key={y}>
                    {x}
                  </option>
                ))
              ) : (
                <option aria-label="None" value="" />
              )}
            </Select>
          </FormControl>
          <div className="d-none d-print-flex">{detail[0].besG}</div>
        </Col>
        {anzahlSpalten.map((spalte) => {
          if (spalte.id >= 1) {
            return (
              <Col key={spalte.id}>
                <FormControl
                  className="d-print-none"
                  size="small"
                  style={{ minWidth: "200px" }}
                  required
                  variant="outlined"
                >
                  <InputLabel>Besoldungsgruppe</InputLabel>
                  <Select
                    id={`${spalte.id}`}
                    //disabled={view}
                    error={!detail[spalte.id].besG ? true : false}
                    native
                    label="Besoldungsgruppe"
                    name="besG"
                    value={detail[spalte.id].besG}
                    onChange={handleDetailChange}
                  >
                    <option aria-label="None" value="" />
                    {detail[spalte.id].besO === "A" ? (
                      besA.map((x, y) => (
                        <option value={x < 10 ? "0" + x : x} key={y}>
                          {x}
                        </option>
                      ))
                    ) : detail[spalte.id].besO === "B" ? (
                      besB.map((x, y) => (
                        <option value={x < 10 ? "0" + x : x} key={y}>
                          {x}
                        </option>
                      ))
                    ) : detail[spalte.id].besO === "C" ? (
                      besC.map((x, y) => (
                        <option value={x < 10 ? "0" + x : x} key={y}>
                          {x}
                        </option>
                      ))
                    ) : detail[spalte.id].besO === "P" ? (
                      besP.map((x, y) => (
                        <option value={x < 10 ? "0" + x : x} key={y}>
                          {x}
                        </option>
                      ))
                    ) : detail[spalte.id].besO === "W" ? (
                      besW.map((x, y) => (
                        <option value={x < 10 ? "0" + x : x} key={y}>
                          {x}
                        </option>
                      ))
                    ) : (
                      <option aria-label="None" value="" />
                    )}
                  </Select>
                </FormControl>
                <div className="d-none d-print-flex">
                  {detail[spalte.id].besG}
                </div>
              </Col>
            );
          }
        })}
      </Row>
      <Row className="print-bottom mb-2 border-bottom">
        <Col>Stufe</Col>
        <Col>
          <FormControl
            className="d-print-none"
            size="small"
            required
            variant="outlined"
          >
            <InputLabel>Stufe</InputLabel>
            <Select
              id="0"
              error={!detail[0].stufe ? true : false}
              native
              label="Stufe"
              name="stufe"
              value={detail[0].stufe}
              onChange={handleDetailChange}
              //disabled={detail.besO === "B" || view === true ? true : false}
              disabled={detail[0].besO === "B"}
            >
              <option aria-label="None" value="" />
              {getStufen(0) !== 0 &&
                getStufen(0).map((x, y) => <option key={y}>{x}</option>)}
            </Select>
          </FormControl>
          <div className="d-none d-print-flex">{detail[0].stufe}</div>
        </Col>
        {anzahlSpalten.map((spalte) => {
          if (spalte.id >= 1) {
            return (
              <Col key={spalte.id}>
                <FormControl
                  className="d-print-none"
                  size="small"
                  required
                  variant="outlined"
                >
                  <InputLabel>Stufe</InputLabel>
                  <Select
                    id={`${spalte.id}`}
                    error={!detail[spalte.id].stufe ? true : false}
                    native
                    label="Stufe"
                    name="stufe"
                    value={detail[spalte.id].stufe}
                    onChange={handleDetailChange}
                    //disabled={detail.besO === "B" || view === true ? true : false}
                    disabled={detail[spalte.id].besO === "B"}
                  >
                    <option aria-label="None" value="" />
                    {getStufen(spalte.id) !== 0 &&
                      getStufen(spalte.id).map((x, y) => (
                        <option key={y}>{x}</option>
                      ))}
                  </Select>
                </FormControl>
                <div className="d-none d-print-flex">
                  {detail[spalte.id].stufe}
                </div>
              </Col>
            );
          }
        })}
      </Row>
      <Row className="print-bottom mb-2">
        <Col>Grundgehalt:</Col>
        <Col>
          <NumberFormat
            decimalScale="2"
            fixedDecimalScale={true}
            value={bezug[0].grundg}
            displayType={"text"}
            thousandSeparator="."
            decimalSeparator=","
            suffix={" €"}
          />
        </Col>
        {anzahlSpalten.map((spalte) => {
          if (spalte.id >= 1) {
            return (
              <Col key={spalte.id}>
                <NumberFormat
                  decimalScale="2"
                  fixedDecimalScale={true}
                  value={bezug[spalte.id].grundg}
                  displayType={"text"}
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix={" €"}
                />
              </Col>
            );
          }
        })}
      </Row>
      <Row className="print-bottom mb-2">
        <Col>Familienzuschlag (%)</Col>
        <Col>
          <TextField
            className="d-print-none"
            size="small"
            //disabled={view}
            id="0"
            label="Familienzuschlag Stufe 1 (%)"
            name="fzPer"
            value={data[0].fzPer}
            onChange={handleDataChange}
            variant="outlined"
            error={data[0].fzPer > 100}
          />
          <div className="d-none d-print-flex">{data[0].fzPer} %</div>
        </Col>
        {anzahlSpalten.map((spalte) => {
          if (spalte.id >= 1) {
            return (
              <Col key={spalte.id}>
                <TextField
                  className="d-print-none"
                  size="small"
                  //disabled={view}
                  id={`${spalte.id}`}
                  label="Familienzuschlag Stufe 1 (%)"
                  name="fzPer"
                  value={data[spalte.id].fzPer}
                  onChange={handleDataChange}
                  variant="outlined"
                  error={data[spalte.id].fzPer > 100}
                />
                <div className="d-none d-print-flex">
                  {data[spalte.id].fzPer}
                </div>
              </Col>
            );
          }
        })}
      </Row>
      <Row className="print-bottom mb-2">
        <Col>Familienzuschlag (€):</Col>
        <Col>
          <TextField
            size="small"
            className="d-print-none"
            //disabled={view}
            label="Familienzuschlag (€)"
            id="0"
            value={detail[0].fzE ? detail[0].fzE : ""}
            onChange={handleDetailChange}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
            variant="outlined"
            name="fzE"
          />
          <NumberFormat
            className="d-none d-print-flex"
            decimalScale="2"
            fixedDecimalScale={true}
            value={detail[0].fzE}
            displayType={"text"}
            thousandSeparator="."
            decimalSeparator=","
            suffix={" €"}
          />
        </Col>
        {anzahlSpalten.map((spalte) => {
          if (spalte.id >= 1) {
            return (
              <Col key={spalte.id}>
                <TextField
                  size="small"
                  className="d-print-none"
                  //disabled={view}
                  label="Familienzuschlag (€)"
                  id={`${spalte.id}`}
                  value={detail[spalte.id].fzE ? +detail[spalte.id].fzE : ""}
                  onChange={handleDetailChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  name="fzE"
                />
                <NumberFormat
                  className="d-none d-print-flex"
                  decimalScale="2"
                  fixedDecimalScale={true}
                  value={detail[spalte.id].fzE}
                  displayType={"text"}
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix={" €"}
                />
              </Col>
            );
          }
        })}
      </Row>
      <Row className="print-bottom mb-2">
        <Col>Strukturzulage:</Col>
        <Col>
          <NumberFormat
            decimalScale="2"
            fixedDecimalScale={true}
            value={bezug[0].struk}
            displayType={"text"}
            thousandSeparator="."
            decimalSeparator=","
            suffix={" €"}
          />
        </Col>
        {anzahlSpalten.map((spalte) => {
          if (spalte.id >= 1) {
            return (
              <Col key={spalte.id}>
                <NumberFormat
                  decimalScale="2"
                  fixedDecimalScale={true}
                  value={bezug[spalte.id].struk}
                  displayType={"text"}
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix={" €"}
                />
              </Col>
            );
          }
        })}
      </Row>
      <Row
        className={
          weitereZulagenVorhanden === false
            ? "mb-2 d-print-none"
            : "print-bottom mb-2"
        }
      >
        <Col>weitere Zulagen:</Col>
        <Col>
          <TextField
            size="small"
            className="d-print-none"
            //disabled={view}
            label="Betrag"
            id="0"
            value={bezug[0].zulagen.betrag1 ? bezug[0].zulagen.betrag1 : "0"}
            onChange={handleBezugChange}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
            variant="outlined"
            name="betrag1"
          />
          <NumberFormat
            className="d-none d-print-block"
            decimalScale="2"
            fixedDecimalScale={true}
            value={bezug[0].zulagen.betrag1 ? bezug[0].zulagen.betrag1 : 0}
            displayType={"text"}
            thousandSeparator="."
            decimalSeparator=","
            suffix={" €"}
          />
        </Col>
        {anzahlSpalten.map((spalte) => {
          if (spalte.id >= 1) {
            return (
              <Col key={spalte.id}>
                <TextField
                  size="small"
                  className="d-print-none"
                  //disabled={view}
                  label="Betrag"
                  id={`${spalte.id}`}
                  value={
                    bezug[spalte.id].zulagen.betrag1
                      ? bezug[spalte.id].zulagen.betrag1
                      : "0"
                  }
                  onChange={handleBezugChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  name="betrag1"
                />
                <NumberFormat
                  className="d-none d-print-block"
                  decimalScale="2"
                  fixedDecimalScale={true}
                  value={
                    bezug[spalte.id].zulagen.betrag1
                      ? bezug[spalte.id].zulagen.betrag1
                      : 0
                  }
                  displayType={"text"}
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix={" €"}
                />
              </Col>
            );
          }
        })}
      </Row>
      <Row className="print-bottom mb-2 border-top">
        <Col>rgf. Bezüge:</Col>
        <Col>
          <NumberFormat
            decimalScale="2"
            fixedDecimalScale={true}
            value={bezug[0].rgfB}
            displayType={"text"}
            thousandSeparator="."
            decimalSeparator=","
            suffix={" €"}
          />
        </Col>
        {anzahlSpalten.map((spalte) => {
          if (spalte.id >= 1) {
            return (
              <Col key={spalte.id}>
                <NumberFormat
                  decimalScale="2"
                  fixedDecimalScale={true}
                  value={bezug[spalte.id].rgfB}
                  displayType={"text"}
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix={" €"}
                />
              </Col>
            );
          }
        })}
      </Row>
      <Row
        className={
          teilzeitVorhanden ? "print-bottom mb-2" : "mb-2 d-print-none"
        }
      >
        <Col>ggf. anteilig bei Teilzeit</Col>
        <Col>
          <NumberFormat
            className="d-print-none"
            decimalScale="2"
            fixedDecimalScale={true}
            value={bezug[0].rgfB_Teil}
            displayType={"text"}
            thousandSeparator="."
            decimalSeparator=","
            suffix={" €"}
          />
          <div className="d-none d-print-flex">{bezug[0].rgfB_Teil} €</div>
        </Col>
        {anzahlSpalten.map((spalte) => {
          if (spalte.id >= 1) {
            return (
              <Col key={spalte.id}>
                <NumberFormat
                  className="d-print-none"
                  decimalScale="2"
                  fixedDecimalScale={true}
                  value={bezug[spalte.id].rgfB_Teil}
                  displayType={"text"}
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix={" €"}
                />
                <div className="d-none d-print-flex">
                  {bezug[spalte.id].rgfB_Teil} €
                </div>
              </Col>
            );
          }
        })}
      </Row>
      <Row className="print-bottom mb-2">
        <Col>Anzahl der Monate:</Col>
        <Col>
          <TextField
            className="d-print-none"
            //disabled={view}
            size="small"
            id="0"
            label="Monate"
            name="monate"
            value={detail[0].monate}
            onChange={handleDetailChange}
            variant="outlined"
            error={detail[0].monate > 12}
          />
          <NumberFormat
            className="d-none d-print-block"
            value={detail[0].monate}
            displayType={"text"}
            thousandSeparator="."
            decimalSeparator=","
          />
        </Col>
        {anzahlSpalten.map((spalte) => {
          if (spalte.id >= 1) {
            return (
              <Col key={spalte.id}>
                <TextField
                  className="d-print-none"
                  //disabled={view}
                  size="small"
                  id={`${spalte.id}`}
                  label="Monate"
                  name="monate"
                  value={detail[spalte.id].monate}
                  onChange={handleDetailChange}
                  variant="outlined"
                  error={detail[spalte.id].monate > 12}
                />
                <NumberFormat
                  className="d-none d-print-block"
                  value={detail[spalte.id].monate}
                  displayType={"text"}
                  thousandSeparator="."
                  decimalSeparator=","
                />
              </Col>
            );
          }
        })}
      </Row>
    </div>
  );
};

export default Sonderzahlung;
