import { SET_VA, ADD_VA, SET_VA_INITIAL } from "../constants/actionTypes";

const initialState = {
  va1: {
    besO: "A",
    besG: "14",
    stufe: 11,
    besO2009: "",
    besG2009: "",
    stufe2009: 0,
    zulagen2009: 0,
    besO1990: "",
    besG1990: "",
    stufe1990: 0,
    zulagen1990: 0,
    eheEnde: "",
    betrag: 0,
    waehrung: "DM",
    drv: "0",
    rentenWertEheEnde: 0,
    entgeltPunkte: 0,
    kuerzung: 0,
    anpFak: true,
  },
  count: 1,
};

const va = (state = initialState, action) => {
  switch (action.type) {
    case SET_VA_INITIAL:
      return action.payload;
    case SET_VA:
      return {
        ...state,
        [action.va]: action.payload,
      };
    case ADD_VA:
      return {
        ...state,
        ["va" + (state.count + 1)]: {
          besO: "A",
          besG: "14",
          stufe: 11,
          besO2009: "",
          besG2009: "",
          stufe2009: 0,
          zulagen2009: 0,
          besO1990: "",
          besG1990: "",
          stufe1990: 0,
          zulagen1990: 0,
          eheEnde: "",
          betrag: 0,
          waehrung: "DM",
          drv: "0",
          rentenWertEheEnde: 0,
          entgeltPunkte: 0,
          kuerzung: 0,
          anpFak: true,
        },
        count: state.count + 1,
      };
    default:
      return state;
  }
};

export default va;
