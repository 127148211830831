//Besoldungsordnung für Sockelerhöhung zum VA um 60DM zum 01.01.1990
//Tabelle Bayern 01.01.1990

export const besA1990 = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, "14A", 15, 16
];

export const besB1990 = [
    3, 6, 9
];

export const besP1990 = [
    1, 2
]

export const stufenA1990 = [
    {gruppe:"01", stufe:[1,2,3,4,5,6,7,8]},
    {gruppe:"02", stufe:[1,2,3,4,5,6,7,8]},
    {gruppe:"03", stufe:[1,2,3,4,5,6,7,8]},
    {gruppe:"04", stufe:[1,2,3,4,5,6,7,8]},
    {gruppe:"05", stufe:[1,2,3,4,5,6,7,8,9]},
    {gruppe:"06", stufe:[1,2,3,4,5,6,7,8,9,10]},
    {gruppe:"07", stufe:[1,2,3,4,5,6,7,8,9,10,11,12,13]},
    {gruppe:"08", stufe:[1,2,3,4,5,6,7,8,9,10,11,12,13]},
    {gruppe:"09", stufe:[1,2,3,4,5,6,7,8,9,10,11,12,13]},
    {gruppe:"10", stufe:[1,2,3,4,5,6,7,8,9,10,11,12,13]},
    {gruppe:"11", stufe:[1,2,3,4,5,6,7,8,9,10,11,12,13,14]},
    {gruppe:"12", stufe:[1,2,3,4,5,6,7,8,9,10,11,12,13,14]},
    {gruppe:"13", stufe:[1,2,3,4,5,6,7,8,9,10,11,12,13,14]},
    {gruppe:"14", stufe:[1,2,3,4,5,6,7,8,9,10,11,12,13,14]},
    {gruppe:"14A", stufe:[1,2,3,4,5,6,7,8,9,10,11,12,13,14]},
    {gruppe:"15", stufe:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]},
    {gruppe:"16", stufe:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]},
]

export const stufenB1990 = [
    {gruppe:"03", stufe:[1]},
    {gruppe:"06", stufe:[1]},
    {gruppe:"09", stufe:[1]}
]

export const stufenP1990 = [
    {gruppe:"01", stufe:[2,3,45,6,7,8,9,10,11,12,13,14]},
    {gruppe:"02", stufe:[1,2,3,4,5,6,7,8,9,10,11,12,13,14]}
]