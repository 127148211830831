import React, { useState } from "react";
import moment from "moment";
import { Table } from "react-bootstrap";
import NumberFormat from "react-number-format";
import { InputAdornment, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

const NeuesRecht = (props) => {
  const zeiten = props.zeiten;
  const anzZei = props.anzZei;
  const gesamtzeit = props.gesamtzeit;
  const view = useSelector((state) => state.Vorgang.view);
  const misch = useSelector(
    (state) => state.Zeiten.gesamtzeit.gesamtZMisch[10]
  );
  const [manRGS, setManRGS] = useState(
    gesamtzeit[4] >= misch ? gesamtzeit[4] : misch
  );
  const reduxManRGS = useSelector((state) => state.Zeiten.manRGS);
  const convReduxManRGS = reduxManRGS.replace(",", ".");
  const iteration = anzZei;
  const inputElements = [];

  for (let index = 0; index < iteration; index++) {
    //underline the last element (iteration-index) === 1
    const style_underline = "align-self-end border-bottom border-dark";

    inputElements.push(
      <tr key={index}>
        <td>
          {zeiten[`z${index + 1}`] && zeiten[`z${index + 1}`].ze
            ? zeiten[`z${index + 1}`].ze
            : 0}
        </td>
        <td>
          {zeiten[`z${index + 1}`]
            ? moment(zeiten[`z${index + 1}`].begDa).format("DD.MM.YYYY")
            : ""}
        </td>
        <td>
          {zeiten[`z${index + 1}`]
            ? moment(zeiten[`z${index + 1}`].endDa).format("DD.MM.YYYY")
            : ""}
        </td>
        <td>{zeiten[`z${index + 1}`] ? zeiten[`z${index + 1}`].za : ""}</td>
        <td>{zeiten[`z${index + 1}`] ? zeiten[`z${index + 1}`].n : ""}</td>
        {gesamtzeit[8] &&
        gesamtzeit[8][`z${index + 1}`] &&
        gesamtzeit[8][`z${index + 1}`].length > 0 ? (
          <>
            <td className={style_underline}>
              {gesamtzeit[8][`z${index + 1}`][0] + " Jahre "}
            </td>
            <td className={style_underline}>
              {gesamtzeit[8][`z${index + 1}`][1] + " Tage"}
            </td>
          </>
        ) : (
          <>
            <td></td>
            <td></td>
          </>
        )}
      </tr>
    );
  }

  const dispatch = useDispatch();
  const handleManRGSChange = (e) => {
    dispatch({ type: "SET_MAN_RGS", payload: e.target.value });
    setManRGS(e.target.value);
  };
  return (
    <div>
      <Table borderless striped>
        <thead>
          <tr className="border-bottom border-dark">
            <td>Typ</td>
            <td>Beginn</td>
            <td>Ende</td>
            <td>Zähler</td>
            <td>Nenner</td>
            <td></td>
            <td></td>
          </tr>
        </thead>
        <tbody>{inputElements}</tbody>
      </Table>

      <div className="mt-1 mb-1 pt-1 pb-1">
        Neues Recht{" "}
        {gesamtzeit[6] && (
          <span className="text-danger">
            (rgf. Dienstzeit erst ab dem 21. Lebensjahr (
            {moment(gesamtzeit[7]).format("DD.MM.YYYY")})
          </span>
        )}
      </div>
      <div className="row justify-content-end">
        <div className="col">Gesamtzeit</div>
        <div className="col">{gesamtzeit[0] + " Jahre"}</div>
        <div className="col">{gesamtzeit[1] + " Tage"}</div>
      </div>
      <div className="row justify-content-end">
        <div className="col">Dezimal</div>
        <div className="col"></div>
        <div className="col">
          <NumberFormat
            decimalScale="2"
            fixedDecimalScale={true}
            value={gesamtzeit[2]}
            displayType={"text"}
            thousandSeparator="."
            decimalSeparator=","
          />
        </div>
      </div>
      <div className="row justify-content-end">
        <div className="col">Sockel</div>
        <div className="col"></div>
        <div className="col">7,175 %</div>
      </div>
      <div className="row justify-content-end">
        <div className="col">Gesamtzeit x Faktor (1,79375 %)</div>
        <div className="col"></div>
        <div className="col">
          <NumberFormat
            decimalScale="2"
            fixedDecimalScale={true}
            value={gesamtzeit[3]}
            displayType={"text"}
            thousandSeparator="."
            decimalSeparator=","
            suffix={" %"}
          />
        </div>
      </div>
      {gesamtzeit[5] > 71.75 ? (
        <div>
          <div className="row justify-content-end">
            <div className="col">Ruhegehaltsatz</div>
            <div className="col"></div>
            <div className="col">
              <NumberFormat
                decimalScale="2"
                fixedDecimalScale={true}
                value={gesamtzeit[5]}
                displayType={"text"}
                thousandSeparator="."
                decimalSeparator=","
                suffix={" %"}
              />
            </div>
          </div>
          <div className="row justify-content-end">
            <div className="col">begrentzt auf</div>
            <div className="col"></div>
            <div className="col">
              <NumberFormat
                decimalScale="2"
                fixedDecimalScale={true}
                value={gesamtzeit[4]}
                displayType={"text"}
                thousandSeparator="."
                decimalSeparator=","
                suffix={" %"}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="row justify-content-end">
          <div className="col">Ruhegehaltsatz</div>
          <div className="col"></div>
          <div className="col">
            <NumberFormat
              decimalScale="2"
              fixedDecimalScale={true}
              value={gesamtzeit[4]}
              displayType={"text"}
              thousandSeparator="."
              decimalSeparator=","
              suffix={" %"}
            />
          </div>
        </div>
      )}
      {props.viewManRGS === true && (
        <div>
          <div>manueller RGS?</div>
          <div>
            <TextField
              size="small"
              disabled={view}
              id="outlined-error-helper-text"
              label="man. RGS"
              name="manRGS"
              value={manRGS < convReduxManRGS ? reduxManRGS : manRGS}
              onChange={handleManRGSChange}
              variant="outlined"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default NeuesRecht;
