import { SET_ANSCHREIBEN, SET_ANLAGEN } from "../constants/actionTypes";
const initialState = {
  data: {
    strasse: "",
    plz: 99999,
    nummer: 99,
    ort: "",
    az: "",
    gericht: {
      ags: 0,
      pf_plz: 99999,
      postfach: "",
      ort: "",
    },
  },
  anlagen: {
    fz: true,
    merkA: true,
    merkS: true,
    empf: true,
    vb: true,
    anlageA: true,
    anlageB: false,
    anlageVA: false,
  },
};

const Anschreiben = (state = initialState, action) => {
  switch (action.type) {
    case SET_ANSCHREIBEN:
      return {
        ...state,
        data: action.payload,
      };
    case SET_ANLAGEN:
      return {
        ...state,
        anlagen: action.payload,
      };
    default:
      return state;
  }
};

export default Anschreiben;
