import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router-dom";

const OverViewStabgz = () => {
  const [loading, setLoading] = useState();
  const [GroupA, setGroupA] = useState();
  const [GroupB, setGroupB] = useState();
  const [GroupC, setGroupC] = useState();
  const [year, setYear] = useState(2022);
  const [group, setGroup] = useState("a");

  const navigate = useHistory();

  const getData = async () => {
    try {
      setLoading(true);
      const aG = await fetch(
        //`http://localhost/versorgung/rest-api/functions_stabgz.php?year=${year}&group=a`,
        `https://versorgung.hosting142616.a2e76.netcup.net/rest-api/functions_stabgz.php?year=${year}&group=a`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
          },
        }
      );
      if (aG) {
        const a = await aG.json();
        setGroupA(a);
      }
      const bG = await fetch(
        //`http://localhost/versorgung/rest-api/functions_stabgz.php?year=${year}&group=b`,
        `https://versorgung.hosting142616.a2e76.netcup.net/rest-api/functions_stabgz.php?year=${year}&group=b`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
          },
        }
      );
      if (bG) {
        const b = await bG.json();
        setGroupB(b);
      }
      const cG = await fetch(
        //`http://localhost/versorgung/rest-api/functions_stabgz.php?year=${year}&group=c`,
        `https://versorgung.hosting142616.a2e76.netcup.net/rest-api/functions_stabgz.php?year=${year}&group=c`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
          },
        }
      );
      if (cG) {
        const c = await cG.json();
        setGroupC(c);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [year, group]);

  return (
    <div>
      <Row className="d-print-none">
        <Col>
          <ButtonGroup>
            <Button variant="outline-primary" onClick={() => setYear(2022)}>
              2022
            </Button>
            <Button variant="outline-primary" onClick={() => setYear(2021)}>
              2021
            </Button>
            <Button variant="outline-primary" onClick={() => setYear(2020)}>
              2020
            </Button>
          </ButtonGroup>
        </Col>
        <Col className="text-end">
          <Button
            onClick={() => navigate.push("/stabgz/detail")}
            variant="outline-primary"
          >
            Detailberechnungen
          </Button>
        </Col>
      </Row>
      <div className="mb-3">
        <b>Evangelisch-Lutherische Kirche in Bayern KdöR</b>
      </div>
      <div className="mb-5">
        <b>Steuerabgeltungszuschlag {year}</b>
      </div>
      <Row className="text-center" style={{ fontWeight: "bolder" }}>
        <Col xs="4">Gehaltsgruppe</Col>
        <Col>Erstattungsgruppe</Col>
        <Col>Erstattungsgruppe</Col>
        <Col>Erstattungsgruppe</Col>
      </Row>
      <Row
        className="text-center mt-4 pb-4 border-bottom border-dark"
        style={{ fontWeight: "bolder" }}
      >
        <Col xs="2">von</Col>
        <Col xs="2">bis</Col>
        <Col>A</Col>
        <Col>B</Col>
        <Col>C</Col>
      </Row>
      {loading === false &&
        Object.values(GroupA).map((row, id) => {
          return (
            <Row className="text-center" key={id}>
              <Col>
                <NumberFormat
                  displayType="text"
                  decimalSeparator=","
                  thousandSeparator="."
                  suffix=" €"
                  value={row[0]}
                  fixedDecimalScale
                  decimalScale={2}
                />
              </Col>
              <Col>
                <NumberFormat
                  displayType="text"
                  decimalSeparator=","
                  thousandSeparator="."
                  suffix=" €"
                  value={row[1]}
                  fixedDecimalScale
                  decimalScale={2}
                />
              </Col>
              <Col>
                <NumberFormat
                  displayType="text"
                  decimalSeparator=","
                  thousandSeparator="."
                  suffix=" %"
                  value={row[13]}
                />
              </Col>
              <Col>
                <NumberFormat
                  displayType="text"
                  decimalSeparator=","
                  thousandSeparator="."
                  suffix=" %"
                  value={GroupB[id + 1][13]}
                />
              </Col>
              <Col>
                <NumberFormat
                  displayType="text"
                  decimalSeparator=","
                  thousandSeparator="."
                  suffix=" %"
                  value={GroupC[id + 1][13]}
                />
              </Col>
            </Row>
          );
        })}
    </div>
  );
};

export default OverViewStabgz;
