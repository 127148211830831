import { SET_DETAIL } from "../constants/actionTypes";

const initialState = {
  data: {
    schule: "",
    schuldienst: false,
    herr: "ELKB",
    status: "Pfarrer",
    grund: "",
    dUnfall: false,
    beg: "",
    va: false,
    besch: false,
    wvers: false,
    rente: false,
    besO: "A",
    besG: "14",
    stufe: 11,
    begBeamt: "",
  },
};

const detail = (state = initialState, action) => {
  switch (action.type) {
    case SET_DETAIL:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default detail;
