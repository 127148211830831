import React from "react";
import {
  besA,
  besB,
  besC,
  besP,
  besW,
  stufenA,
  stufenB,
  stufenC,
  stufenW,
  stufenP,
} from "./helper/besoldung";
import { useSelector, useDispatch } from "react-redux";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const Detail = () => {
  const dispatch = useDispatch();
  const detail = useSelector((state) => state.detail.data);
  const hgr85 = useSelector((state) => state.HGR85.data);
  const geschl = useSelector((state) => state.person.data.geschl);
  const view = useSelector((state) => state.Vorgang.view);
  const anlagen = useSelector((state) => state.Anschreiben.anlagen);

  const handleDetailChange = (e) => {
    if (e.target.name === "va") {
      dispatch({ type: "SET_DETAIL", payload: { ...detail, va: !detail.va } });
      dispatch({
        type: "SET_ANLAGEN",
        payload: { ...anlagen, anlageVA: !detail.va },
      });
    } else if (e.target.name === "besch") {
      dispatch({
        type: "SET_DETAIL",
        payload: { ...detail, besch: !detail.besch },
      });
    } else if (e.target.name === "wvers") {
      dispatch({
        type: "SET_DETAIL",
        payload: { ...detail, wvers: !detail.wvers },
      });
    } else if (e.target.name === "rente") {
      dispatch({
        type: "SET_DETAIL",
        payload: { ...detail, rente: !detail.rente },
      });
    } else if (e.target.name === "schuldienst") {
      dispatch({
        type: "SET_DETAIL",
        payload: { ...detail, schuldienst: !detail.schuldienst },
      });
    } else if (e.target.name === "stufe") {
      dispatch({
        type: "SET_DETAIL",
        payload: { ...detail, stufe: +e.target.value },
      });
    } else if (e.target.name === "besO") {
      if (e.target.value === "B") {
        dispatch({
          type: "SET_DETAIL",
          payload: { ...detail, [e.target.name]: e.target.value, stufe: 1 },
        });
      } else {
        dispatch({
          type: "SET_DETAIL",
          payload: { ...detail, [e.target.name]: e.target.value, stufe: 0 },
        });
      }
    } else {
      dispatch({
        type: "SET_DETAIL",
        payload: { ...detail, [e.target.name]: e.target.value },
      });
    }
  };

  const handle85Change = (e) => {
    dispatch({
      type: "SET_HGR85",
      payload: {
        ...hgr85,
        [e.target.name]: e.target.value,
      },
    });
  };

  const getStufen = () => {
    let Stufen = "";
    if (detail.besO === "A") {
      Stufen = stufenA.filter((value, index) => {
        return value.gruppe === detail.besG;
      });
    } else if (detail.besO === "B") {
      Stufen = stufenB.filter((value, index) => {
        return value.gruppe === detail.besG;
      });
    } else if (detail.besO === "C") {
      Stufen = stufenC.filter((value, index) => {
        return value.gruppe === detail.besG;
      });
    } else if (detail.besO === "W") {
      Stufen = stufenW.filter((value, index) => {
        return value.gruppe === detail.besG;
      });
    } else if (detail.besO === "P") {
      Stufen = stufenP.filter((value, index) => {
        return value.gruppe === detail.besG;
      });
    }

    return Stufen.length > 0 ? Stufen[0].stufe : 0;
  };

  //array mit den stufen
  const stufen = getStufen();

  return (
    <div>
      <Row>
        <Col className="mt-2">
          <TextField
            disabled={view}
            error={!detail.herr ? true : false}
            id="outlined-basic"
            label="Dienstherr"
            variant="outlined"
            required
            name="herr"
            value={detail.herr}
            onChange={handleDetailChange}
          />
        </Col>

        <Col className="mt-2">
          <FormControl
            required
            style={{ minWidth: "200px" }}
            variant="outlined"
          >
            <InputLabel>Rechtsstatus</InputLabel>
            <Select
              disabled={view}
              error={!detail.status ? true : false}
              native
              label="Rechtsstatus"
              name="status"
              value={detail.status}
              onChange={handleDetailChange}
            >
              <option aria-label="None" value="" />
              <option value={"Pfarrer"}>
                {geschl === 1
                  ? "Pfarrer"
                  : geschl === 2
                  ? "Pfarrerin"
                  : "Pfarrer"}
              </option>
              <option value={"Beamter"}>
                {geschl === 1
                  ? "Kirchenbeamter"
                  : geschl === 2
                  ? "Kirchenbeamtin"
                  : "Kirchenbeamter"}
              </option>
              <option value={"Diakon"}>
                {geschl === 1 ? "Diakon" : geschl === 2 ? "Diakonin" : "Diakon"}
              </option>
              <option value={"RelPäd"}>
                {geschl === 1
                  ? "Religionspädagoge"
                  : geschl === 2
                  ? "Religionspädagogin"
                  : "Religionspädagoge"}
              </option>
            </Select>
          </FormControl>
        </Col>

        <Col className="mt-2">
          <TextField
            disabled={view}
            error={
              detail.begBeamt === "0000-00-00" ||
              detail.begBeamt === 0 ||
              detail.begBeamt === ""
                ? true
                : false
            }
            required
            variant="outlined"
            id="date"
            label="Beginn Beamtv."
            type="date"
            name="begBeamt"
            value={detail.begBeamt}
            onChange={handleDetailChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Col>
        <Col className="mt-2">
          <FormControl required variant="outlined">
            <InputLabel>Grund</InputLabel>
            <Select
              disabled={view}
              error={!detail.grund ? true : false}
              native
              label="Grund"
              name="grund"
              value={detail.grund}
              onChange={handleDetailChange}
            >
              <option aria-label="None" value="" />
              <option value={1}>Regelaltersgrenze</option>
              <option value={2}>auf Antrag</option>
              <option value={3}>auf Antrag (GdB &gt; 50)</option>
              <option value={4}>Dienstunfähigkeit</option>
              <option value={5}>Dienstunfähigkeit (Dienstunfall)</option>
              <option value={6}>aus dem Wartestand</option>
              <option value={7}>Urteil d. Disziplinargerichts</option>
            </Select>
          </FormControl>
          {detail.grund === "5" && (
            <FormControl
              className="mt-2"
              required
              variant="outlined"
              size="small"
            >
              <InputLabel>MdE</InputLabel>
              <Select
                disabled={view}
                native
                label="MdE"
                name="mde"
                value={hgr85.mde}
                onChange={handle85Change}
              >
                <option aria-label="None" value="" />
                <option value={10}>10 %</option>
                <option value={20}>20 %</option>
                <option value={30}>30 %</option>
                <option value={40}>40 %</option>
                <option value={50}>50 %</option>
                <option value={60}>60 %</option>
                <option value={70}>70 %</option>
                <option value={80}>80 %</option>
                <option value={90}>90 %</option>
                <option value={100}>100 %</option>
              </Select>
            </FormControl>
          )}
        </Col>
        <Col className="mt-2">
          <TextField
            disabled={view}
            error={
              detail.beg === "" ||
              detail.beg === "Invalid date" ||
              detail.beg === "0000-00-00"
                ? true
                : false
            }
            required
            variant="outlined"
            id="date"
            label="Beginn"
            type="date"
            name="beg"
            value={detail.beg}
            onChange={handleDetailChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col className="mt-2">
          <TextField
            disabled={view}
            variant="outlined"
            id="date"
            label="abw. Altersgrenze"
            type="date"
            name="schule"
            value={detail.schule}
            onChange={handleDetailChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Col>

        <Col className="mt-2">
          <FormControl
            required
            style={{ minWidth: "200px" }}
            variant="outlined"
          >
            <InputLabel>Besoldungsordnung</InputLabel>
            <Select
              disabled={view}
              error={!detail.besO ? true : false}
              native
              label="Besoldungsordnung"
              name="besO"
              value={detail.besO}
              onChange={handleDetailChange}
            >
              <option aria-label="None" value="" />
              <option value={"A"}>A</option>
              <option value={"B"}>B</option>
              <option value={"C"}>C</option>
              <option value={"P"}>P</option>
              <option value={"W"}>W</option>
            </Select>
          </FormControl>
        </Col>

        <Col className="mt-2">
          <FormControl
            style={{ minWidth: "200px" }}
            required
            variant="outlined"
          >
            <InputLabel>Besoldungsgruppe</InputLabel>
            <Select
              disabled={view}
              error={!detail.besG ? true : false}
              native
              label="Besoldungsgruppe"
              name="besG"
              value={detail.besG}
              onChange={handleDetailChange}
            >
              <option aria-label="None" value="" />
              {detail.besO === "A" ? (
                besA.map((x, y) => (
                  <option value={x < 10 ? "0" + x : x} key={y}>
                    {x}
                  </option>
                ))
              ) : detail.besO === "B" ? (
                besB.map((x, y) => (
                  <option value={x < 10 ? "0" + x : x} key={y}>
                    {x}
                  </option>
                ))
              ) : detail.besO === "C" ? (
                besC.map((x, y) => (
                  <option value={x < 10 ? "0" + x : x} key={y}>
                    {x}
                  </option>
                ))
              ) : detail.besO === "P" ? (
                besP.map((x, y) => (
                  <option value={x < 10 ? "0" + x : x} key={y}>
                    {x}
                  </option>
                ))
              ) : detail.besO === "W" ? (
                besW.map((x, y) => (
                  <option value={x < 10 ? "0" + x : x} key={y}>
                    {x}
                  </option>
                ))
              ) : (
                <option aria-label="None" value="" />
              )}
            </Select>
          </FormControl>
        </Col>

        <Col className="mt-2">
          <FormControl required variant="outlined">
            <InputLabel>Stufe</InputLabel>
            <Select
              error={!detail.stufe ? true : false}
              native
              label="Stufe"
              name="stufe"
              value={detail.stufe}
              onChange={handleDetailChange}
              disabled={detail.besO === "B" || view === true ? true : false}
            >
              <option aria-label="None" value="" />
              {stufen !== 0 &&
                stufen.map((x, y) => <option key={y}>{x}</option>)}
            </Select>
          </FormControl>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormControlLabel
            disabled={view}
            control={
              <Checkbox
                color="primary"
                checked={detail.schuldienst}
                onChange={handleDetailChange}
                name="schuldienst"
              />
            }
            label="Abweichender Zeitpunkt wg. Schuldienst?"
          />
        </Col>

        <Col>
          <FormControlLabel
            disabled={view}
            control={
              <Checkbox
                color="primary"
                checked={detail.va}
                onChange={handleDetailChange}
                name="va"
              />
            }
            label="Versorgungsausgleich?"
          />
        </Col>

        <Col>
          <FormControlLabel
            disabled={view}
            control={
              <Checkbox
                color="primary"
                checked={detail.besch}
                onChange={handleDetailChange}
                name="besch"
              />
            }
            label="weiteres Beschäftigungsverhältnis?"
          />
        </Col>

        <Col>
          <FormControlLabel
            disabled={view}
            control={
              <Checkbox
                color="primary"
                checked={detail.wvers}
                onChange={handleDetailChange}
                name="wvers"
              />
            }
            label="weiterer Versorgungsbezug?"
          />
        </Col>

        <Col>
          <FormControlLabel
            disabled={view}
            control={
              <Checkbox
                color="primary"
                checked={detail.rente}
                onChange={handleDetailChange}
                name="rente"
              />
            }
            label="Rentenanrechnung?"
          />
        </Col>
      </Row>
    </div>
  );
};

export default Detail;
