import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  calc_21,
  calc_27,
  calc_62,
  calc_55,
  grundg,
  zulage,
  calcGesamtzeitMisch,
  calcGesamtzeitNeu,
  getAbschlag,
  getAufschlag,
  getUnabMind,
  calc_fz_ub,
  get_stva,
  calcGesamtzeit85Neu,
  splitMisch,
  calcGesamtzeit85Misch,
  fetchBVG,
} from "../helper/functions";
import Head from "../head";
import Detail from "../detail";
import Bezüge from "../bezuge";
import VersBezug from "../versBezuge";
import Zeiten from "../dienstzeiten";
import TabsNav from "../tabs";
import { TabPanel } from "../tabsPanel";
import NeuesRecht from "../neuesRecht";
import MischRecht from "../MischRecht";
import VA from "../va";
import Rente from "../rente";
import rentenwert from "../helper/rentenwert";
import { Col, Row } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Hgr85 from "../hgr85";

import moment from "moment";
import Bescheide from "../bescheide";

const Festsetzung = () => {
  const dispatch = useDispatch();

  const [tabVal, setTabVal] = useState(0);

  const data = useSelector((state) => state.person.data);
  const detail = useSelector((state) => state.detail.data);
  const bezug = useSelector((state) => state.bezug.data);
  const vaReducer = useSelector((state) => state.va);
  const va = vaReducer.va1;
  const rente = useSelector((state) => state.rente.data);
  const renteChange = useSelector((state) => state.rente.change);
  const reduxZeiten = useSelector((state) => state.Zeiten);
  const versB = useSelector((state) => state.versB.data);
  const mischNeeded = useSelector((state) => state.Zeiten.mischNeeded);
  const hgr85 = useSelector((state) => state.HGR85.data);
  const vorgang_id = useSelector((state) => state.Vorgang.data.id);
  const anschreiben = useSelector((state) => state.Anschreiben.data);
  const anlagen = useSelector((state) => state.Anschreiben.anlagen);
  const view = useSelector((state) => state.Vorgang.view);

  const [save, setSave] = useState(false);
  /*
  useEffect(() => {
    //Rentenwert
    const ArrayWert = rentenwert.filter(
      (value) => value.beg <= rente.beg && value.end > rente.beg
    );
    const filterWert = ArrayWert[0] ? ArrayWert[0].wert : 0;

    //Rundung auf 4 nachkommastellen
    const entgeltpunkteGes =
      +Math.round(
        (+rente.entgeltpunkte + +rente.entgeltpunkteFrei + +rente.va) * 10000
      ) / 10000;

    const punkteNachZugangsfaktor =
      Math.round(entgeltpunkteGes * +rente.zugangsfaktor * 10000) / 10000;

    const bisherPersPunkte =
      +rente.persPunkte > punkteNachZugangsfaktor
        ? +rente.persPunkte
        : punkteNachZugangsfaktor;

    const hinterblPunkte =
      +rente.zuschlagHinterbl * +rente.faktorZuschlagHinterbl;

    const summePersPunkte = bisherPersPunkte + hinterblPunkte;

    const renteVorEinkommen =
      Math.round(summePersPunkte * filterWert * +rente.rentenfaktor * 100) /
      100;

    const renteNachEinkommen = renteVorEinkommen - +rente.einkommen;

    const zahlbetrag = renteNachEinkommen + +rente.höherVers;

    const renteVNG =
      Math.round(
        (+rente.punkteVNG + +rente.freiwPunkteVNG) *
          filterWert *
          +rente.rentenfaktor *
          100
      ) / 100;

    const renteVNGnachEinkommen =
      Math.round((+renteVNG - +rente.einkommenVNG) * 100) / 100;

    const renteVNGnachHöher =
      Math.round((+renteVNGnachEinkommen + +rente.höherVersVNG) * 100) / 100;

    const rente85 =
      Math.round(
        (+rente.punkte85 + +rente.freiwPunkte85) *
          filterWert *
          +rente.rentenfaktor *
          100
      ) / 100;

    const rente85nachEinkommen =
      Math.round((+rente85 - +rente.einkommen85) * 100) / 100;

    const rente85nachHöher =
      Math.round((+rente85nachEinkommen + +rente.höherVers85) * 100) / 100;

    const renteFreiwNicht =
      -Math.round(+rente.freiwPunkteNicht * filterWert * 100) / 100;

    const renteZuschlagHinterblNicht =
      -Math.round(+rente.zuschlagHinterblNicht * filterWert * 100) / 100;

    const renteHöherNicht = -Math.round(+rente.höherVersNicht * 100) / 100;

    const vaWert = -Math.round(rente.va * filterWert * 100) / 100;

    const höchstbetragRente =
      zahlbetrag +
      +vaWert +
      +renteFreiwNicht +
      +renteZuschlagHinterblNicht +
      +renteHöherNicht +
      +rente.einkommenNicht;

    const anrechnungsGes =
      Math.round((renteVNGnachHöher + rente85nachHöher) * 100) / 100;

    let rundungsdiff = 0;
    //Rundungsdifferenz?
    if (
      höchstbetragRente !== anrechnungsGes &&
      rente.punkte85 + rente.punkteVNG === summePersPunkte
    ) {
      rundungsdiff = höchstbetragRente - anrechnungsGes;
    }

    const control =
      höchstbetragRente === anrechnungsGes + rundungsdiff ? true : false;

    dispatch({
      type: "SET_RENTE",
      payload: {
        ...rente,
        wert: filterWert,
        entgeltpunkteGes: +entgeltpunkteGes,
        punkteNachZugangsfaktor: punkteNachZugangsfaktor,
        summePersPunkte: summePersPunkte,
        renteVorEinkommen: renteVorEinkommen,
        renteNachEinkommen: renteNachEinkommen,
        zahlbetrag: zahlbetrag,
        renteVNG: renteVNG,
        renteVNGnachEinkommen: renteVNGnachEinkommen,
        rente85: rente85,
        rente85nachEinkommen: rente85nachEinkommen,
        renteVNGnachHöher: renteVNGnachHöher,
        rente85nachHöher:
          Math.round((rente85nachHöher + rundungsdiff) * 100) / 100,
        renteFreiwNicht: renteFreiwNicht,
        renteZuschlagHinterblNicht: renteZuschlagHinterblNicht,
        renteHöherNicht: renteHöherNicht,
        vaWert: vaWert,
        höchstbetragRente: höchstbetragRente,
        anrechnungsGes: anrechnungsGes + rundungsdiff,
        control: control,
      },
    });
  }, [renteChange]);
*/

  //Bezüge berechnen wenn sich was in details und/oder data geändert hat
  useEffect(() => {
    const grundgehalt = grundg(
      detail.besO + detail.besG,
      detail.stufe,
      detail.beg
    );
    const fam = zulage("fz1", detail.beg, detail.besO + detail.besG);
    const strZulage = zulage("struk", detail.beg, detail.besO + detail.besG);
    const newFzPer = data.fzPer.replace(",", ".");
    dispatch({
      type: "SET_BEZUG",
      payload: {
        ...bezug,
        grundg: grundgehalt ? +grundgehalt["betrag"] : 0,
        fz:
          fam &&
          (data.fam === "1" ||
            data.fam === "2" ||
            data.fam === "5" ||
            data.fam === "9")
            ? Math.round(fam["betrag"] * newFzPer) / 100
            : 0,
        struk:
          strZulage &&
          detail.besO === "A" &&
          +detail.besG < 12 &&
          detail.status === "RelPäd"
            ? strZulage["betrag"]
            : strZulage &&
              detail.besO === "A" &&
              +detail.besG < 14 &&
              detail.status !== "RelPäd"
            ? strZulage["betrag"]
            : 0,
      },
    });
  }, [detail, data]);

  //wenn drüber die state in zeiten gesetzt sind
  useEffect(() => {
    let newBegDa = null;
    const new21 = {};
    const new27 = {};
    const new21HGR = {};
    const new27HGR = {};

    //Dienstzeit für Höchstgrenze
    const endDa = moment(detail.beg);
    const newEndDa = endDa.subtract(1, "day").format("YYYY-MM-DD");
    new21HGR[`z1`] = {
      ze: "fiktive Gesamtzeit",
      begDa: calc_21(data.birthDate),
      endDa: newEndDa,
      za: 1,
      n: 1,
      rgfD: { jahre: 0, tage: 0 },
    };
    new27HGR[`z1`] = {
      ze: "fiktive Gesamtzeit",
      begDa: calc_27(data.birthDate),
      endDa: newEndDa,
      za: 1,
      n: 1,
      rgfD: { jahre: 0, tage: 0 },
    };

    for (
      let index = 1;
      index <= Object.keys(reduxZeiten.zeiten).length;
      index++
    ) {
      //filter21
      if (reduxZeiten.zeiten[`z${index}`].begDa < calc_21(data.birthDate)) {
        newBegDa = calc_21(data.birthDate);
      } else {
        newBegDa = reduxZeiten.zeiten[`z${index}`].begDa;
      }

      new21[`z${index}`] = {
        ...reduxZeiten.zeiten[`z${index}`],
        begDa: newBegDa,
      };

      //Falls zurechnungszeit bei HGR-Dienstzeit
      if (reduxZeiten.zeiten[`z${index}`].ze === "Z042") {
        new21HGR[`z${index}`] = {
          ...reduxZeiten.zeiten21HGR[`z${index}`],
          rgfD: { jahre: 0, tage: 0 },
        };
        new27HGR[`z${index}`] = {
          ...reduxZeiten.zeiten27HGR[`z${index}`],
          rgfD: { jahre: 0, tage: 0 },
        };
      }

      //filter27
      if (detail.begBeamt < "1992-01-01") {
        if (reduxZeiten.zeiten[`z${index}`].begDa < calc_27(data.birthDate)) {
          newBegDa = calc_27(data.birthDate);
        } else {
          newBegDa = reduxZeiten.zeiten[`z${index}`].begDa;
        }

        new27[`z${index}`] = {
          ...reduxZeiten.zeiten[`z${index}`],
          begDa: newBegDa,
        };
      }
    }

    //Splitten der Zeiten im Mischrecht
    const splittedMisch = splitMisch(new27);
    const splittedMisch85 = splitMisch(new27HGR);

    dispatch({ type: "SET_ZEIT21HGR", payload: new21HGR });

    dispatch({ type: "SET_ZEIT27HGR", payload: splittedMisch85 });

    dispatch({ type: "SET_ZEIT21", payload: new21 });

    dispatch({ type: "SET_ZEIT27", payload: splittedMisch });

    //Mischrecht
    const gesamtZMisch =
      detail.begBeamt < "1992-01-01"
        ? calcGesamtzeitMisch(splittedMisch, data.birthDate)
        : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    //neues Recht
    const gesamtZ = calcGesamtzeitNeu(new21, data.birthDate);

    //Dienstzeit HGR-Neu
    const gesamtZ85Neu = calcGesamtzeit85Neu(new21HGR);

    //Dienstzeit HGR-misch
    const gesamtZ85Misch = calcGesamtzeit85Misch(new27HGR);

    dispatch({
      type: "SET_GESAMTZEIT",
      payload: {
        gesamtZ: gesamtZ,
        gesamtZMisch: gesamtZMisch,
        gesamtZ85Neu: gesamtZ85Neu,
        gesamtZ85Misch: gesamtZ85Misch,
      },
    });
  }, [reduxZeiten.zeiten, data.birthDate]);

  useEffect(() => {
    let mindestversorgung = false;
    //Abschlag
    //Prüfung ob Abschlagsfrei nach 40/45 Jahren in der Funktion getAbschlag
    const abschlag = getAbschlag(data, detail, reduxZeiten.zeiten);
    const abschlagPro = abschlag[2] > abschlag[0] ? abschlag[0] : abschlag[2];
    const abschlagfreiJahre = abschlag[4] ? abschlag[4] : 0;
    let rgs =
      reduxZeiten.gesamtzeit.gesamtZ[4] >=
      reduxZeiten.gesamtzeit.gesamtZMisch[10]
        ? reduxZeiten.gesamtzeit.gesamtZ[4]
        : reduxZeiten.gesamtzeit.gesamtZMisch[10];

    const convManRGS = reduxZeiten.manRGS.replace(",", ".");
    if (+convManRGS > rgs) {
      rgs = +convManRGS;
    }

    const versBezug = reduxZeiten.gesamtzeit
      ? Math.round(((rgs * bezug.rgfB) / 100) * 100) / 100
      : 0;
    const abschlagEur =
      -Math.round(((versBezug * abschlagPro) / 100) * 100) / 100;

    //Aufschlag
    const aufschlag = getAufschlag(data, detail);
    const aufschlagEur =
      Math.round(((versBezug * aufschlag[1]) / 100) * 100) / 100;

    //Neuer Versorgungsbezug
    const versBezugAfterAbAuf =
      Math.round((versBezug + abschlagEur + aufschlagEur) * 100) / 100;

    //Familienzuschlag kind
    const newFzK = data.fzK.replace(",", ".");
    const fzK = calc_fz_ub(detail, newFzK);
    const versBezugAfterFz =
      Math.round((versBezugAfterAbAuf + fzK) * 100) / 100;

    //abgleich amtsunabh. Mindestversorgung
    const newFzPer = data.fzPer.replace(",", ".");
    const unabMind = getUnabMind(detail.beg, newFzPer, newFzK);

    //abgleich amtsabhän. Mindestversorgung
    const abhMind = Math.round(bezug.rgfB * 0.35 * 100) / 100 + fzK;

    //überschreiben der versorgungsbezüge
    let versBezugAfterMin = 0;
    if (versBezugAfterFz <= unabMind && unabMind > abhMind) {
      versBezugAfterMin = unabMind;
      mindestversorgung = true;
    } else if (versBezugAfterFz <= abhMind && abhMind > unabMind) {
      versBezugAfterMin = abhMind;
      mindestversorgung = true;
    } else {
      versBezugAfterMin = versBezugAfterFz;
    }

    //VA
    const versBezugAfterVA =
      Math.round((versBezugAfterMin + va.kuerzung) * 100) / 100;

    //Unfallausgleich
    let unfallausgleich = 0;
    if (detail.grund === "5") {
      unfallausgleich = fetchBVG(detail.beg, hgr85.mde);
    }
    const versBezugAfterUnfall =
      Math.round((versBezugAfterVA + unfallausgleich) * 100) / 100;

    //Stva
    let stva_eur = 0;
    let stva = 0;
    //wenn unabh. Mindestv. dann 0
    if (versBezugAfterMin === unabMind) {
      stva_eur = 0;
      stva = 0;
    } else {
      stva = get_stva(detail.beg, detail.besO + detail.besG, data.stkl).satz;
      stva_eur = -Math.round(((stva * versBezugAfterMin) / 100) * 100) / 100;
    }
    const versBezugAfterStva =
      Math.round((versBezugAfterUnfall + stva_eur) * 100) / 100;

    //Rente-VNG
    const versBezugAfterVNGRente =
      Math.round((versBezugAfterStva - rente.renteVNGnachHöher) * 100) / 100;

    //Rente-85
    const versBezugAfter85Rente =
      Math.round((versBezugAfterVNGRente + hgr85.ruhe85) * 100) / 100;

    //Rente-85-26abs6
    const versBezugAfter26abs6 =
      Math.round((versBezugAfter85Rente - hgr85.art26abs6) * 100) / 100;

    dispatch({
      type: "SET_VERSB",
      payload: {
        ...versB,
        mindestversorgung: mindestversorgung,
        versBezug: versBezug,
        abschlagPro: abschlagPro,
        abschlagProRech: abschlag[2],
        abschlagEur: abschlagEur,
        abschlagEnde: abschlag[1],
        abschlagfreiJahre: abschlagfreiJahre,
        versBezugAfterAbAuf: versBezugAfterAbAuf,
        deziJahreAb: abschlag[3],
        aufschlagPro: aufschlag[1],
        deziJahreAuf: aufschlag[2],
        aufschlagEnde: aufschlag[0],
        aufschlagEur: aufschlagEur,
        unabMind: unabMind,
        abhMind: abhMind,
        versBezugAfterMin: versBezugAfterMin,
        fzK: fzK,
        versBezugAfterFz: versBezugAfterFz,
        stva: stva,
        stva_eur: stva_eur,
        renteVNG: -rente.renteVNGnachHöher,
        versBezugAfterVNGRente: versBezugAfterVNGRente,
        kuerzungVA: va.kuerzung,
        rente85: hgr85.ruhe85,
        versBezugAfter85Rente: versBezugAfter85Rente,
        art26abs6: hgr85.art26abs6,
        versBezugAfter26abs6: versBezugAfter26abs6,
        versBezugAfterStva: versBezugAfterStva,
        versBezugAfterUnfall: versBezugAfterUnfall,
        unfallausgleich: unfallausgleich,
        versBezugAfterVA: versBezugAfterVA,
      },
    });
  }, [
    reduxZeiten.gesamtzeit,
    rente,
    hgr85,
    bezug,
    data.fzK,
    data.birthDate,
    detail.grund,
    detail.beg,
    va.kuerzung,
  ]);

  //Wenn sich Beginn Beamt ändert oder Maxi RGS erreicht
  useEffect(() => {
    if (detail.begBeamt >= "1992-01-01") {
      dispatch({ type: "SET_MISCH_NEEDED", payload: false });
      dispatch({
        type: "SET_GESAMTZEIT",
        payload: {
          ...reduxZeiten.gesamtzeit,
          gesamtZMisch: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
      });
    } else if (reduxZeiten.gesamtzeit.gesamtZ[4] === 71.75) {
      dispatch({ type: "SET_MISCH_NEEDED", payload: false });
      dispatch({
        type: "SET_GESAMTZEIT",
        payload: {
          ...reduxZeiten.gesamtzeit,
          gesamtZMisch: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
      });
    } else {
      dispatch({ type: "SET_ANLAGEN", payload: { ...anlagen, anlageB: true } });
      dispatch({ type: "SET_MISCH_NEEDED", payload: true });
    }
  }, [detail.begBeamt, reduxZeiten.gesamtzeit.gesamtZ[4]]);

  //Bei Dienstunfähigkeit - Zurechnungszeit
  useEffect(() => {
    if (detail.grund === "4") {
      let got_Zurechnung = false;

      for (
        let index = 1;
        index <= Object.keys(reduxZeiten.zeiten).length;
        index++
      ) {
        if (reduxZeiten.zeiten[`z${index}`].ze === "Z042") {
          got_Zurechnung = true;
        }
      }
      //wie viele Dienstzeiten haben wir?
      const length = Object.keys(reduxZeiten.zeiten).length;

      if (
        calc_62(data.birthDate) > detail.beg &&
        detail.beg > "1900-00-00" &&
        got_Zurechnung === false
      ) {
        //zurechnungszeit am ende dazu
        dispatch({
          type: "SET_ZEIT",
          payload: {
            ...reduxZeiten.zeiten,
            [`z${length + 1}`]: {
              ...reduxZeiten.zeiten[`z${length + 1}`],
              ze: "Z042",
              za: 2,
              n: 3,
              begDa: detail.beg,
              endDa: calc_62(data.birthDate),
            },
          },
        });

        //zurechnungszeit am ende dazu
        dispatch({
          type: "SET_ZEIT21",
          payload: {
            ...reduxZeiten.zeiten21,
            [`z${length + 1}`]: {
              ...reduxZeiten.zeiten21[`z${length + 1}`],
              ze: "Z042",
              za: 2,
              n: 3,
              begDa: detail.beg,
              endDa: calc_62(data.birthDate),
            },
          },
        });

        //zurechnungszeit am ende dazu
        dispatch({
          type: "SET_ZEIT21HGR",
          payload: {
            ...reduxZeiten.zeiten21HGR,
            [`z${length + 1}`]: {
              ...reduxZeiten.zeiten21[`z${length + 1}`],
              ze: "Z042",
              za: 2,
              n: 3,
              begDa: detail.beg,
              endDa: calc_62(data.birthDate),
            },
          },
        });

        if (calc_55(data.birthDate) > detail.beg) {
          dispatch({
            type: "SET_ZEIT27",
            payload: {
              ...reduxZeiten.zeiten27,
              [`z${length + 1}`]: {
                ...reduxZeiten.zeiten27[`z${length + 1}`],
                ze: "Z042",
                za: 1,
                n: 3,
                begDa: detail.beg,
                endDa: calc_55(data.birthDate),
              },
            },
          });

          //zurechnungszeit am ende dazu
          dispatch({
            type: "SET_ZEIT27HGR",
            payload: {
              ...reduxZeiten.zeiten27HGR,
              [`z${length + 1}`]: {
                ...reduxZeiten.zeiten27[`z${length + 1}`],
                ze: "Z042",
                za: 1,
                n: 3,
                begDa: detail.beg,
                endDa: calc_55(data.birthDate),
              },
            },
          });
        } else {
          dispatch({
            type: "SET_ZEIT27",
            payload: {
              ...reduxZeiten.zeiten27,
              [`z${length + 1}`]: {
                ...reduxZeiten.zeiten27[`z${length + 1}`],
                ze: "Z042",
                za: 0,
                n: 1,
                begDa: detail.beg,
                endDa: calc_55(data.birthDate),
              },
            },
          });

          //zurechnungszeit am ende dazu
          dispatch({
            type: "SET_ZEIT27HGR",
            payload: {
              ...reduxZeiten.zeiten27HGR,
              [`z${length + 1}`]: {
                ...reduxZeiten.zeiten27[`z${length + 1}`],
                ze: "Z042",
                za: 0,
                n: 1,
                begDa: detail.beg,
                endDa: calc_55(data.birthDate),
              },
            },
          });
        }

        dispatch({ type: "SET_ANZ_ZEITEN" });
        //trigeres useEffect to calcDateDiff
        dispatch({ type: "SET_ZEITEN_BOOL" });
        //set to the last edited zeit
        dispatch({ type: "SET_ZEITEN_CHANGE", payload: `z${length + 1}` });
      }
    }
  }, [
    detail.grund,
    detail.beg,
    reduxZeiten.zeiten,
    reduxZeiten.zeiten21,
    reduxZeiten.zeiten27,
  ]);

  const handleTabChange = (event, newValue) => {
    setTabVal(newValue);
  };

  const handleSave = async () => {
    const request = await fetch(
      //`http://localhost/versorgung/rest-api/save.php`,
      `https://versorgung.hosting142616.a2e76.netcup.net/rest-api/save.php`,
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify({
          person: data,
          zeiten: reduxZeiten,
          detail: detail,
          bezug: bezug,
          va: vaReducer,
          rente: rente,
          versb: versB,
          hgr85: hgr85,
          anschreiben: anschreiben,
          type: 2,
          id: vorgang_id,
          status: 1,
        }),
      }
    );
    if (request) {
      const response = await request.json();
      dispatch({ type: "SET_VORGANG", payload: { id: response.id } });
      setSave(true);
    }
  };

  const handleFinish = async () => {
    const request = await fetch(
      //`http://localhost/versorgung/rest-api/save.php`,
      `https://versorgung.hosting142616.a2e76.netcup.net/rest-api/save.php`,
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify({
          person: data,
          zeiten: reduxZeiten,
          detail: detail,
          bezug: bezug,
          va: vaReducer,
          rente: rente,
          versb: versB,
          hgr85: hgr85,
          anschreiben: anschreiben,
          type: 2,
          id: vorgang_id,
          status: view === true ? 1 : 2,
        }),
      }
    );
    if (request) {
      const response = await request.json();
      dispatch({ type: "SET_VORGANG", payload: { id: response.id } });
      setSave(true);
      dispatch({ type: "SET_VIEW" });
    }
  };

  return (
    <div>
      <TabsNav
        value={tabVal}
        handleTabChange={handleTabChange}
        mischNeeded={mischNeeded}
        vaNeeded={detail.va}
        renteNeeded={detail.rente}
      />
      <TabPanel value={tabVal} index={0}>
        Daten zur Person
        <Head />
        Details
        <Detail />
      </TabPanel>
      <TabPanel value={tabVal} index={4}>
        <Bezüge />
        <Row>
          <Col>Ruhegehaltsatz:</Col>
          <Col>
            {reduxZeiten.gesamtzeit.gesamtZ[4] >=
            reduxZeiten.gesamtzeit.gesamtZMisch[10]
              ? reduxZeiten.gesamtzeit.gesamtZ[4]
              : reduxZeiten.gesamtzeit.gesamtZMisch[10]}{" "}
            %
            {reduxZeiten.gesamtzeit.gesamtZ[4] >=
              reduxZeiten.gesamtzeit.gesamtZMisch[10] &&
            reduxZeiten.manRGS.replace(",", ".") >
              reduxZeiten.gesamtzeit.gesamtZ[4]
              ? " (man. RGS " + reduxZeiten.manRGS + " %)"
              : reduxZeiten.gesamtzeit.gesamtZMisch[10] >
                  reduxZeiten.gesamtzeit.gesamtZ[4] &&
                reduxZeiten.manRGS.replace(",", ".") >
                  reduxZeiten.gesamtzeit.gesamtZMisch[10] &&
                " (man. RGS " + reduxZeiten.manRGS + " %)"}
          </Col>
        </Row>
        <VersBezug />
      </TabPanel>
      <TabPanel value={tabVal} index={1}>
        <Zeiten />
      </TabPanel>
      <TabPanel value={tabVal} index={2}>
        <NeuesRecht
          gesamtzeit={reduxZeiten.gesamtzeit.gesamtZ}
          anzZei={Object.keys(reduxZeiten.zeiten21).length}
          zeiten={reduxZeiten.zeiten21}
        />
      </TabPanel>
      <TabPanel value={tabVal} index={3}>
        <MischRecht
          gesamtzeit={reduxZeiten.gesamtzeit.gesamtZMisch}
          anzZei={Object.keys(reduxZeiten.zeiten27).length}
          zeiten={reduxZeiten.zeiten27}
        />
      </TabPanel>
      <TabPanel value={tabVal} index={5}>
        <VA />
      </TabPanel>
      <TabPanel value={tabVal} index={6}>
        <Rente />
      </TabPanel>
      <TabPanel value={tabVal} index={7}>
        <Hgr85 />
      </TabPanel>
      <TabPanel value={tabVal} index={8}>
        {mischNeeded === false ? (
          <NeuesRecht
            viewManRGS={false}
            gesamtzeit={reduxZeiten.gesamtzeit.gesamtZ85Neu}
            anzZei={Object.keys(reduxZeiten.zeiten21HGR).length}
            zeiten={reduxZeiten.zeiten21HGR}
          />
        ) : (
          <MischRecht
            gesamtzeit={reduxZeiten.gesamtzeit.gesamtZ85Misch}
            anzZei={Object.keys(reduxZeiten.zeiten27HGR).length}
            zeiten={reduxZeiten.zeiten27HGR}
          />
        )}
      </TabPanel>
      <TabPanel value={tabVal} index={9}>
        <Bescheide />
      </TabPanel>
      <ButtonGroup
        className="no-print"
        color="primary"
        aria-label="outlined primary button group"
      >
        <Button disabled={view} onClick={handleSave}>
          speichern
        </Button>
        <Button onClick={handleFinish}>
          {view === true ? "bearbeiten" : "abschließen"}
        </Button>
      </ButtonGroup>
      {save === true && <div>speichern erfolgreich!</div>}
    </div>
  );
};

export default Festsetzung;
