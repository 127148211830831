import { FormControl, InputLabel, Select, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Modal, ModalBody, ModalTitle, Row } from "react-bootstrap";
import NumberFormat from "react-number-format";
import Button from "react-bootstrap/Button";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import rentenwert from "./helper/rentenwert.json";

const Rente = () => {
  const dispatch = useDispatch();
  const rente = useSelector((state) => state.rente.data);
  const renteChange = useSelector((state) => state.rente.change);
  const view = useSelector((state) => state.Vorgang.view);

  const [showControl, setShowControl] = useState(false);

  const handleControl = () => {
    setShowControl(true);
  };

  const handleRenteChange = (e) => {
    const value =
      e.target.name === "beg"
        ? e.target.value
        : e.target.valueAsNumber
        ? e.target.valueAsNumber
        : e.target.value;

    dispatch({
      type: "SET_RENTE",
      payload: { ...rente, [e.target.name]: value },
    });
    dispatch({
      type: "SET_RENTE_CHANGE",
    });
  };

  useEffect(() => {
    //Rentenwert
    const ArrayWert = rentenwert.filter(
      (value) => value.beg <= rente.beg && value.end > rente.beg
    );
    const filterWert = ArrayWert[0] ? ArrayWert[0].wert : 0;

    //Rundung auf 4 nachkommastellen
    const entgeltpunkteGes =
      +Math.round(
        (+rente.entgeltpunkte + +rente.entgeltpunkteFrei + +rente.va) * 10000
      ) / 10000;

    const punkteNachZugangsfaktor =
      Math.round(entgeltpunkteGes * +rente.zugangsfaktor * 10000) / 10000;

    const bisherPersPunkte =
      +rente.persPunkte > punkteNachZugangsfaktor
        ? +rente.persPunkte
        : punkteNachZugangsfaktor;

    const hinterblPunkte =
      +rente.zuschlagHinterbl * +rente.faktorZuschlagHinterbl;

    const summePersPunkte = bisherPersPunkte + hinterblPunkte;

    const renteVorEinkommen =
      Math.round(summePersPunkte * filterWert * +rente.rentenfaktor * 100) /
      100;

    const renteNachEinkommen = renteVorEinkommen - +rente.einkommen;

    const zahlbetrag = renteNachEinkommen + +rente.höherVers;

    const renteVNG =
      Math.round(
        (+rente.punkteVNG + +rente.freiwPunkteVNG) *
          filterWert *
          +rente.rentenfaktor *
          100
      ) / 100;

    const renteVNGnachEinkommen =
      Math.round((+renteVNG - +rente.einkommenVNG) * 100) / 100;

    const renteVNGnachHöher =
      Math.round((+renteVNGnachEinkommen + +rente.höherVersVNG) * 100) / 100;

    const rente85 =
      Math.round(
        (+rente.punkte85 + +rente.freiwPunkte85) *
          filterWert *
          +rente.rentenfaktor *
          100
      ) / 100;

    const rente85nachEinkommen =
      Math.round((+rente85 - +rente.einkommen85) * 100) / 100;

    const rente85nachHöher =
      Math.round((+rente85nachEinkommen + +rente.höherVers85) * 100) / 100;

    const renteFreiwNicht =
      -Math.round(+rente.freiwPunkteNicht * filterWert * 100) / 100;

    const renteZuschlagHinterblNicht =
      -Math.round(+rente.zuschlagHinterblNicht * filterWert * 100) / 100;

    const renteHöherNicht = -Math.round(+rente.höherVersNicht * 100) / 100;

    const vaWert = -Math.round(rente.va * filterWert * 100) / 100;

    const höchstbetragRente =
      zahlbetrag +
      +vaWert +
      +renteFreiwNicht +
      +renteZuschlagHinterblNicht +
      +renteHöherNicht +
      +rente.einkommenNicht;

    const anrechnungsGes =
      Math.round((renteVNGnachHöher + rente85nachHöher) * 100) / 100;

    let rundungsdiff = 0;
    //Rundungsdifferenz?
    if (
      höchstbetragRente !== anrechnungsGes &&
      rente.punkte85 + rente.punkteVNG === summePersPunkte
    ) {
      rundungsdiff = höchstbetragRente - anrechnungsGes;
    }

    const control =
      höchstbetragRente === anrechnungsGes + rundungsdiff ? true : false;

    dispatch({
      type: "SET_RENTE",
      payload: {
        ...rente,
        wert: filterWert,
        entgeltpunkteGes: +entgeltpunkteGes,
        punkteNachZugangsfaktor: punkteNachZugangsfaktor,
        summePersPunkte: summePersPunkte,
        renteVorEinkommen: renteVorEinkommen,
        renteNachEinkommen: renteNachEinkommen,
        zahlbetrag: zahlbetrag,
        renteVNG: renteVNG,
        renteVNGnachEinkommen: renteVNGnachEinkommen,
        rente85: rente85,
        rente85nachEinkommen: rente85nachEinkommen,
        renteVNGnachHöher: renteVNGnachHöher,
        rente85nachHöher:
          Math.round((rente85nachHöher + rundungsdiff) * 100) / 100,
        renteFreiwNicht: renteFreiwNicht,
        renteZuschlagHinterblNicht: renteZuschlagHinterblNicht,
        renteHöherNicht: renteHöherNicht,
        vaWert: vaWert,
        höchstbetragRente: höchstbetragRente,
        anrechnungsGes: anrechnungsGes + rundungsdiff,
        control: control,
      },
    });
  }, [renteChange]);

  return (
    <div>
      Daten aus dem Rentenbescheid
      <Row>
        <Col md="auto" className="mt-2">
          <TextField
            disabled={view}
            error={!rente.dateBescheid ? true : false}
            required
            variant="outlined"
            id="date"
            label="Datum Rentenbescheid"
            type="date"
            name="dateBescheid"
            value={rente.dateBescheid}
            onChange={handleRenteChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Col>
        <Col md="auto" className="mt-2">
          <TextField
            disabled={view}
            error={!rente.beg ? true : false}
            required
            variant="outlined"
            id="date"
            label="Rentenbeginn"
            type="date"
            name="beg"
            value={rente.beg}
            onChange={handleRenteChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Col>
        <Col md="auto" className="mt-2">
          <TextField
            disabled={view}
            error={!rente.pan ? true : false}
            id="outlined-error-helper-text"
            label="PAN"
            name="pan"
            value={rente.pan}
            onChange={handleRenteChange}
            variant="outlined"
          />
        </Col>
        <Col md="auto" className="mt-2">
          <TextField
            disabled={view}
            error={!rente.rentennummer ? true : false}
            id="outlined-error-helper-text"
            label="Postrentennummer"
            name="rentennummer"
            value={rente.rentennummer}
            onChange={handleRenteChange}
            variant="outlined"
          />
        </Col>
        <Col md="auto" className="mt-2">
          <FormControl
            required
            style={{ minWidth: "200px" }}
            variant="outlined"
          >
            <InputLabel>Rentenart</InputLabel>
            <Select
              disabled={view}
              error={!rente.rentenart ? true : false}
              native
              label="Rentenart"
              name="rentenart"
              value={rente.rentenart}
              onChange={handleRenteChange}
            >
              <option aria-label="None" value="" />
              <option value={1}>Altersrente für langjährig Versicherte</option>
              <option value={2}>
                Altersrente für besonders langjährig Versicherte
              </option>
              <option value={3}>Regelaltersrente</option>
              <option value={4}>Erwerbsminderungsrente</option>
              <option value={5}>große Witwenrente</option>
            </Select>
          </FormControl>
        </Col>
        <Col md="auto" className="mt-2">
          <TextField
            disabled={view}
            type="number"
            error={!rente.rentenfaktor ? true : false}
            id="outlined-error-helper-text"
            label="Rentenartfaktor"
            name="rentenfaktor"
            value={rente.rentenfaktor}
            onChange={handleRenteChange}
            variant="outlined"
          />
        </Col>
        <Col md="auto" className="mt-2">
          <TextField
            disabled={view}
            type="number"
            error={!rente.wert ? true : false}
            id="outlined-error-helper-text"
            label="Rentenwert"
            name="wert"
            value={rente.wert}
            onChange={handleRenteChange}
            variant="outlined"
          />
        </Col>
        <Col md="auto" className="mt-2">
          <TextField
            disabled={view}
            type="number"
            error={!rente.entgeltpunkte ? true : false}
            id="outlined-error-helper-text"
            label="Entgeltpunkte/Pflichtbeiträge"
            name="entgeltpunkte"
            value={rente.entgeltpunkte}
            onChange={handleRenteChange}
            variant="outlined"
          />
        </Col>
        <Col md="auto" className="mt-2">
          <TextField
            disabled={view}
            type="number"
            error={!rente.entgeltpunkteFrei ? true : false}
            id="outlined-error-helper-text"
            label="Entgeltpunkte/freiw. Beiträge"
            name="entgeltpunkteFrei"
            value={rente.entgeltpunkteFrei}
            onChange={handleRenteChange}
            variant="outlined"
          />
        </Col>
        <Col md="auto" className="mt-2">
          <TextField
            disabled={view}
            type="number"
            error={!rente.va ? true : false}
            id="outlined-error-helper-text"
            label="Entgeltpunkte (VA)"
            name="va"
            value={rente.va}
            onChange={handleRenteChange}
            variant="outlined"
          />
        </Col>
        <Col md="auto" className="mt-2">
          <TextField
            disabled={view}
            type="number"
            error={!rente.zugangsfaktor ? true : false}
            id="outlined-error-helper-text"
            label="Zugangsfaktor"
            name="zugangsfaktor"
            value={rente.zugangsfaktor}
            onChange={handleRenteChange}
            variant="outlined"
          />
        </Col>
        <Col md="auto" className="mt-2">
          <TextField
            disabled={view}
            type="number"
            error={!rente.persPunkte ? true : false}
            id="outlined-error-helper-text"
            label="bisherige Punkte"
            name="persPunkte"
            value={rente.persPunkte}
            onChange={handleRenteChange}
            variant="outlined"
          />
        </Col>
        <Col md="auto" className="mt-2">
          <TextField
            disabled={view}
            type="number"
            error={!rente.zuschlagHinterbl ? true : false}
            id="outlined-error-helper-text"
            label="Zuschlag Hinterbliebenenrente"
            name="zuschlagHinterbl"
            value={rente.zuschlagHinterbl}
            onChange={handleRenteChange}
            variant="outlined"
          />
        </Col>
        <Col md="auto" className="mt-2">
          <TextField
            disabled={view}
            type="number"
            error={!rente.faktorZuschlagHinterbl ? true : false}
            id="outlined-error-helper-text"
            label="Faktor Hinterbliebenenzuschlag"
            name="faktorZuschlagHinterbl"
            value={rente.faktorZuschlagHinterbl}
            onChange={handleRenteChange}
            variant="outlined"
          />
        </Col>
        <Col md="auto" className="mt-2">
          <TextField
            disabled={view}
            type="number"
            error={!rente.einkommen ? true : false}
            id="outlined-error-helper-text"
            label="anzurechnendes Einkommen"
            name="einkommen"
            value={rente.einkommen}
            onChange={handleRenteChange}
            variant="outlined"
          />
        </Col>
        <Col md="auto" className="mt-2">
          <TextField
            disabled={view}
            type="number"
            error={!rente.höherVers ? true : false}
            id="outlined-error-helper-text"
            label="Steigerungsbeträge aus Höherversicherung"
            name="höherVers"
            value={rente.höherVers}
            onChange={handleRenteChange}
            variant="outlined"
          />
        </Col>
      </Row>
      <div>Aufteilung in VNG-Rente, sowie Rente nach Art. 85 BayBeamtVG</div>
      <div>Entgeltpunkte aus Pflichtbeiträgen</div>
      <Row>
        <Col md="auto" className="mt-2">
          <TextField
            disabled={view}
            type="number"
            error={!rente.punkteVNG ? true : false}
            id="outlined-error-helper-text"
            label="anrechenbar nach VNG"
            name="punkteVNG"
            value={rente.punkteVNG}
            onChange={handleRenteChange}
            variant="outlined"
          />
        </Col>
        <Col md="auto" className="mt-2">
          <TextField
            disabled={view}
            type="number"
            error={!rente.punkte85 ? true : false}
            id="outlined-error-helper-text"
            label="anrechenbar nach BayBeamtVG"
            name="punkte85"
            value={rente.punkte85}
            onChange={handleRenteChange}
            variant="outlined"
          />
        </Col>
      </Row>
      <div>Entgeltpunkte aus freiwilligen Beiträgen</div>
      <Row>
        <Col md="auto" className="mt-2">
          <TextField
            disabled={view}
            type="number"
            error={!rente.freiwPunkteVNG ? true : false}
            id="outlined-error-helper-text"
            label="anrechenbar nach VNG"
            name="freiwPunkteVNG"
            value={rente.freiwPunkteVNG}
            onChange={handleRenteChange}
            variant="outlined"
          />
        </Col>
        <Col md="auto" className="mt-2">
          <TextField
            disabled={view}
            type="number"
            error={!rente.freiwPunkte85 ? true : false}
            id="outlined-error-helper-text"
            label="anrechenbar nach BayBeamtVG"
            name="freiwPunkte85"
            value={rente.freiwPunkte85}
            onChange={handleRenteChange}
            variant="outlined"
          />
        </Col>
        <Col md="auto" className="mt-2">
          <TextField
            disabled={view}
            type="number"
            error={!rente.freiwPunkteNicht ? true : false}
            id="outlined-error-helper-text"
            label="nicht anrechenbar"
            name="freiwPunkteNicht"
            value={rente.freiwPunkteNicht}
            onChange={handleRenteChange}
            variant="outlined"
          />
        </Col>
      </Row>
      <div>Steigerungsbeträge aus Höherversicherung</div>
      <Row>
        <Col md="auto" className="mt-2">
          <TextField
            disabled={view}
            type="number"
            error={!rente.höherVersVNG ? true : false}
            id="outlined-error-helper-text"
            label="anrechenbar nach VNG"
            name="höherVersVNG"
            value={rente.höherVersVNG}
            onChange={handleRenteChange}
            variant="outlined"
          />
        </Col>
        <Col md="auto" className="mt-2">
          <TextField
            disabled={view}
            type="number"
            error={!rente.höherVers85 ? true : false}
            id="outlined-error-helper-text"
            label="anrechenbar nach BayBeamtVG"
            name="höherVers85"
            value={rente.höherVers85}
            onChange={handleRenteChange}
            variant="outlined"
          />
        </Col>
        <Col md="auto" className="mt-2">
          <TextField
            disabled={view}
            type="number"
            error={!rente.höherVersNicht ? true : false}
            id="outlined-error-helper-text"
            label="nicht anrechenbar"
            name="höherVersNicht"
            value={rente.höherVersNicht}
            onChange={handleRenteChange}
            variant="outlined"
          />
        </Col>
      </Row>
      <div>Zuschlag bei Hinterbliebenenrente</div>
      <Row>
        <Col md="auto" className="mt-2">
          <TextField
            disabled={view}
            type="number"
            error={!rente.zuschlagHinterblVNG ? true : false}
            id="outlined-error-helper-text"
            label="anrechenbar nach VNG"
            name="zuschlagHinterblVNG"
            value={rente.zuschlagHinterblVNG}
            onChange={handleRenteChange}
            variant="outlined"
          />
        </Col>
        <Col md="auto" className="mt-2">
          <TextField
            disabled={view}
            type="number"
            error={!rente.zuschlagHinterbl85 ? true : false}
            id="outlined-error-helper-text"
            label="anrechenbar nach BayBeamtVG"
            name="zuschlagHinterbl85"
            value={rente.zuschlagHinterbl85}
            onChange={handleRenteChange}
            variant="outlined"
          />
        </Col>
        <Col md="auto" className="mt-2">
          <TextField
            disabled={view}
            type="number"
            error={!rente.zuschlagHinterblNicht ? true : false}
            id="outlined-error-helper-text"
            label="nicht anrechenbar"
            name="zuschlagHinterblNicht"
            value={rente.zuschlagHinterblNicht}
            onChange={handleRenteChange}
            variant="outlined"
          />
        </Col>
      </Row>
      <div>Einkommensanrechnung auf Rente</div>
      <Row>
        <Col md="auto" className="mt-2">
          <TextField
            disabled={view}
            type="number"
            error={!rente.einkommenVNG ? true : false}
            id="outlined-error-helper-text"
            label="anrechenbar nach VNG"
            name="einkommenVNG"
            value={rente.einkommenVNG}
            onChange={handleRenteChange}
            variant="outlined"
          />
        </Col>
        <Col md="auto" className="mt-2">
          <TextField
            disabled={view}
            type="number"
            error={!rente.einkommen85 ? true : false}
            id="outlined-error-helper-text"
            label="anrechenbar nach BayBeamtVG"
            name="einkommen85"
            value={rente.einkommen85}
            onChange={handleRenteChange}
            variant="outlined"
          />
        </Col>
        <Col md="auto" className="mt-2">
          <TextField
            disabled={view}
            type="number"
            error={!rente.einkommenNicht ? true : false}
            id="outlined-error-helper-text"
            label="nicht anrechenbar"
            name="einkommenNicht"
            value={rente.einkommenNicht}
            onChange={handleRenteChange}
            variant="outlined"
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col md="auto">Rentenberechnung</Col>

        <Col md="auto">
          <Button
            variant={rente.control === true ? "success" : "danger"}
            size="sm"
            disabled={view}
            onClick={handleControl}
          >
            Vergleichs- und Kontrollberechnung
          </Button>
        </Col>
      </Row>
      <div>Allgemein</div>
      <Row>
        <Col>Rentenwert</Col>
        <Col>
          <NumberFormat
            decimalScale="2"
            fixedDecimalScale={true}
            value={rente.wert}
            displayType={"text"}
            thousandSeparator="."
            decimalSeparator=","
            suffix={" €"}
          />
        </Col>
      </Row>
      <Row>
        <Col>Rentenfaktor</Col>
        <Col>
          <NumberFormat
            decimalScale="2"
            fixedDecimalScale={true}
            value={rente.rentenfaktor}
            displayType={"text"}
          />
        </Col>
      </Row>
      <Row>
        <Col>Entgeltpunkte aus Pflichtbeiträgen</Col>
        <Col>
          <NumberFormat
            decimalScale="4"
            fixedDecimalScale={true}
            value={rente.entgeltpunkte}
            displayType={"text"}
            thousandSeparator="."
            decimalSeparator=","
          />
        </Col>
      </Row>
      {rente.entgeltpunkteFrei > 0 && (
        <Row>
          <Col>Entgeltpunkte freiwillige Beiträge</Col>
          <Col>
            <NumberFormat
              decimalScale="4"
              fixedDecimalScale={true}
              value={rente.entgeltpunkteFrei}
              displayType={"text"}
              thousandSeparator="."
              decimalSeparator=","
            />
          </Col>
        </Row>
      )}
      {rente.va !== "0" && (
        <Row>
          <Col>Versorgungsausgleich</Col>
          <Col>
            <NumberFormat
              decimalScale="4"
              fixedDecimalScale={true}
              value={rente.va}
              displayType={"text"}
              thousandSeparator="."
              decimalSeparator=","
            />
          </Col>
        </Row>
      )}
      <Row className="border-top">
        <Col>Summe der Entgeltpunkte</Col>
        <Col>
          <NumberFormat
            decimalScale="4"
            fixedDecimalScale={true}
            value={rente.entgeltpunkteGes}
            displayType={"text"}
            thousandSeparator="."
            decimalSeparator=","
          />
        </Col>
      </Row>
      <Row>
        <Col>Zugangsfaktor</Col>
        <Col>
          <NumberFormat
            decimalScale="3"
            fixedDecimalScale={true}
            value={rente.zugangsfaktor}
            displayType={"text"}
            thousandSeparator="."
            decimalSeparator=","
          />
        </Col>
      </Row>
      <Row className="border-top">
        <Col>Summe pers. Entgeltpunkte</Col>
        <Col>
          <NumberFormat
            decimalScale="4"
            fixedDecimalScale={true}
            value={rente.punkteNachZugangsfaktor}
            displayType={"text"}
            thousandSeparator="."
            decimalSeparator=","
          />
        </Col>
      </Row>
      {(rente.einkommen > 0 || rente.höherVers > 0) && (
        <Row>
          <Col>Rente</Col>
          <Col>
            <NumberFormat
              decimalScale="2"
              fixedDecimalScale={true}
              value={rente.renteVorEinkommen}
              displayType={"text"}
              thousandSeparator="."
              decimalSeparator=","
              suffix={" €"}
            />
          </Col>
        </Row>
      )}
      {rente.einkommen > 0 && (
        <div>
          <Row>
            <Col>Minderung um anrechenbares Einkommen</Col>
            <Col>
              <NumberFormat
                decimalScale="2"
                fixedDecimalScale={true}
                value={+rente.einkommen}
                displayType={"text"}
                thousandSeparator="."
                decimalSeparator=","
                suffix={" €"}
              />
            </Col>
          </Row>
          <Row>
            <Col>Rente nach Einkommen</Col>
            <Col>
              <NumberFormat
                decimalScale="2"
                fixedDecimalScale={true}
                value={rente.renteNachEinkommen}
                displayType={"text"}
                thousandSeparator="."
                decimalSeparator=","
                suffix={" €"}
              />
            </Col>
          </Row>
        </div>
      )}
      {rente.höherVers > 0 && (
        <Row>
          <Col>Steigerungsbeträge aus Höherversicherung</Col>
          <Col>
            <NumberFormat
              decimalScale="2"
              fixedDecimalScale={true}
              value={rente.höherVers}
              displayType={"text"}
              thousandSeparator="."
              decimalSeparator=","
              suffix={" €"}
            />
          </Col>
        </Row>
      )}
      <Row className="border-top">
        <Col>Rentenzahlbetrag (brutto)</Col>
        <Col>
          <NumberFormat
            decimalScale="2"
            fixedDecimalScale={true}
            value={rente.zahlbetrag}
            displayType={"text"}
            thousandSeparator="."
            decimalSeparator=","
            suffix={" €"}
          />
        </Col>
      </Row>
      <div className="mt-3 mb-1">anrechenbare Rente nach VNG</div>
      <Row>
        <Col>Entgeltpunkte aus Pflichtbeiträgen</Col>
        <Col>
          <NumberFormat
            decimalScale="4"
            fixedDecimalScale={true}
            value={rente.punkteVNG}
            displayType={"text"}
            thousandSeparator="."
            decimalSeparator=","
          />
        </Col>
      </Row>
      {rente.freiwPunkteVNG > 0 && (
        <Row>
          <Col>Entgeltpunkte aus freiw. Beiträgen</Col>
          <Col>
            <NumberFormat
              decimalScale="4"
              fixedDecimalScale={true}
              value={rente.freiwPunkteVNG}
              displayType={"text"}
              thousandSeparator="."
              decimalSeparator=","
            />
          </Col>
        </Row>
      )}
      {(rente.einkommenVNG > 0 || rente.höherVersVNG > 0) && (
        <Row>
          <Col>Rentenbetrag nach VNG</Col>
          <Col>
            <NumberFormat
              decimalScale="2"
              fixedDecimalScale={true}
              value={rente.renteVNG}
              displayType={"text"}
              thousandSeparator="."
              decimalSeparator=","
              suffix={" €"}
            />
          </Col>
        </Row>
      )}
      {rente.einkommenVNG > 0 && (
        <div>
          <Row>
            <Col>Minderung um anzurechnendes Einkommen</Col>
            <Col>
              <NumberFormat
                decimalScale="2"
                fixedDecimalScale={true}
                value={+rente.einkommenVNG}
                displayType={"text"}
                thousandSeparator="."
                decimalSeparator=","
                suffix={" €"}
              />
            </Col>
          </Row>
          <Row>
            <Col>Rentenbetrag VNG nach Einkommensanrechnung</Col>
            <Col>
              <NumberFormat
                decimalScale="2"
                fixedDecimalScale={true}
                value={rente.renteVNGnachEinkommen}
                displayType={"text"}
                thousandSeparator="."
                decimalSeparator=","
                suffix={" €"}
              />
            </Col>
          </Row>
        </div>
      )}
      {rente.höherVersVNG > 0 && (
        <Row>
          <Col>Steigerungsbeträge aus Höherversicherung</Col>
          <Col>
            <NumberFormat
              decimalScale="2"
              fixedDecimalScale={true}
              value={rente.höherVersVNG}
              displayType={"text"}
              thousandSeparator="."
              decimalSeparator=","
              suffix={" €"}
            />
          </Col>
        </Row>
      )}
      <Row className="border-top">
        <Col>nach VNG anrechenbarer Betrag</Col>
        <Col>
          <NumberFormat
            decimalScale="2"
            fixedDecimalScale={true}
            value={rente.renteVNGnachHöher}
            displayType={"text"}
            thousandSeparator="."
            decimalSeparator=","
            suffix={" €"}
          />
        </Col>
      </Row>
      <div className="mt-3 mb-1">
        anrechenbare Rente nach Art. 85 BayBeamtVG
      </div>
      <Row>
        <Col>Entgeltpunkte aus Pflichtbeiträgen</Col>
        <Col>
          <NumberFormat
            decimalScale="4"
            fixedDecimalScale={true}
            value={rente.punkte85}
            displayType={"text"}
            thousandSeparator="."
            decimalSeparator=","
          />
        </Col>
      </Row>
      {rente.freiwPunkte85 > 0 && (
        <Row>
          <Col>Entgeltpunkte aus freiw. Beiträgen</Col>
          <Col>
            <NumberFormat
              decimalScale="4"
              fixedDecimalScale={true}
              value={rente.freiwPunkte85}
              displayType={"text"}
              thousandSeparator="."
              decimalSeparator=","
            />
          </Col>
        </Row>
      )}
      {(rente.einkommen85 > 0 || rente.höherVers85 > 0) && (
        <Row>
          <Col>Rentenbetrag nach Art. 85 BayBeamtVG</Col>
          <Col>
            <NumberFormat
              decimalScale="2"
              fixedDecimalScale={true}
              value={rente.rente85}
              displayType={"text"}
              thousandSeparator="."
              decimalSeparator=","
              suffix={" €"}
            />
          </Col>
        </Row>
      )}
      {rente.einkommen85 > 0 && (
        <div>
          <Row>
            <Col>Minderung um anzurechnendes Einkommen</Col>
            <Col>
              <NumberFormat
                decimalScale="2"
                fixedDecimalScale={true}
                value={+rente.einkommen85}
                displayType={"text"}
                thousandSeparator="."
                decimalSeparator=","
                suffix={" €"}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              Rentenbetrag (Art. 85 BayBeamtVG) nach Einkommensanrechnung
            </Col>
            <Col>
              <NumberFormat
                decimalScale="2"
                fixedDecimalScale={true}
                value={rente.rente85nachEinkommen}
                displayType={"text"}
                thousandSeparator="."
                decimalSeparator=","
                suffix={" €"}
              />
            </Col>
          </Row>
        </div>
      )}
      {rente.höherVers85 > 0 && (
        <Row>
          <Col>Steigerungsbeträge aus Höherversicherung</Col>
          <Col>
            <NumberFormat
              decimalScale="2"
              fixedDecimalScale={true}
              value={rente.höherVers85}
              displayType={"text"}
              thousandSeparator="."
              decimalSeparator=","
              suffix={" €"}
            />
          </Col>
        </Row>
      )}
      <Row className="border-top">
        <Col>nach Art. 85 BayBeamtVG anrechenbarer Betrag</Col>
        <Col>
          <NumberFormat
            decimalScale="2"
            fixedDecimalScale={true}
            value={rente.rente85nachHöher}
            displayType={"text"}
            thousandSeparator="."
            decimalSeparator=","
            suffix={" €"}
          />
        </Col>
      </Row>
      <Modal size="lg" show={showControl} onHide={() => setShowControl(false)}>
        <ModalHeader>
          <ModalTitle>Vergleichs- und Kontrollberechnung</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <div>Rentenzahlbetrag (brutto) = {rente.zahlbetrag}</div>
          <div>Versorgungsausgleich = {rente.vaWert}</div>
          <div>
            anrechnungsfreie Rente aus Zuschlägen für Hinterbliebenenrente =
          </div>
          <div>
            anrechnungsfreie Rente aus freiw. Beiträgen ={" "}
            {rente.renteFreiwNicht}
          </div>
          <div>
            anrechnungsfreie Steigerungsbeträge aus Höherversicherung ={" "}
            {rente.renteHöherNicht}
          </div>
          <div>
            anzurechnendes Einkommen, das auf anrechnungsfreie Entgeltpunkte
            entfällt = {rente.einkommenNicht}
          </div>
          <div>
            Höchstbetrag der anrechenbaren Renten = {rente.höchstbetragRente}
          </div>
          <div>errechnete Beträge = {rente.anrechnungsGes}</div>
          <div>Differenz =</div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default Rente;
