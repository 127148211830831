import { SET_HGR85 } from "../constants/actionTypes";

const initialState = {
  data: {
    ruhe85: 0,
    ezvk: 0,
    unfall: 0,
    mde: 0,
    ausland: 0,
    berufs: 0,
    art26abs6: 0,
  },
};

const HGR85 = (state = initialState, action) => {
  switch (action.type) {
    case SET_HGR85:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default HGR85;
