import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import NumberFormat from "react-number-format";

import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import { Col, Row } from "react-bootstrap";
import { get_dates_with_changed_values } from "./helper/functions";

const Bezüge = (props) => {
  const bezug = useSelector((state) => state.bezug.data);
  const versB = useSelector((state) => state.versB.data);
  const hgr83 = useSelector((state) => state.HGR83);
  const hgr85 = useSelector((state) => state.HGR85);
  const view = useSelector((state) => state.Vorgang.view);
  const detail = useSelector((state) => state.detail.data);
  const diffBes = useSelector((state) => state.diffBez);
  const dates = get_dates_with_changed_values(versB.zeitpunkt, hgr83, hgr85);

  const dispatch = useDispatch();
  const handleBezugChange = (e) => {
    const { name, value } = e.target;

    dispatch({
      type: "SET_BEZUG",
      payload: {
        ...bezug,
        zulagen: { ...bezug.zulagen, [name]: value },
      },
    });
  };

  const [iteration, setIteration] = useState(0);
  const inputElements = [];

  const handleClickAdd = () => {
    setIteration(iteration + 1);
    dispatch({
      type: "SET_BEZUG",
      payload: {
        ...bezug,
        anzZul: bezug.anzZul + 1,
      },
    });
  };

  const sumZulage = useRef(0);

  //Wenn sich etwas bei den Bezügen geändert hat neue rgf. Bezüge (Summe) berechnen
  useEffect(() => {
    //summe aller zulagen bilden
    for (let index = 0; index < bezug.anzZul; index++) {
      //string in zahl umwandeln
      const string = bezug.zulagen[`betrag${index + 1}`];
      const numbString = string && string.replace(",", ".");
      sumZulage.current += +numbString;
    }
    dispatch({
      type: "SET_BEZUG",
      payload: {
        ...bezug,
        rgfB:
          bezug.grundg +
          bezug.fz +
          bezug.struk +
          (!sumZulage.current ? 0 : sumZulage.current),
        sumZulagen: !sumZulage.current ? 0 : sumZulage.current,
      },
    });
    sumZulage.current = 0;
  }, [bezug.grundg, bezug.fz, bezug.struk, bezug.zulagen]);

  for (let index = 0; index < iteration; index++) {
    inputElements.push(
      <Row key={index}>
        <Col>
          <TextField
            disabled={view}
            size="small"
            id={`${index + 2}`}
            label="weitere Zulage"
            name={`name${index + 2}`}
            value={
              bezug.zulagen[`name${index + 2}`]
                ? bezug.zulagen[`name${index + 2}`]
                : ""
            }
            onChange={handleBezugChange}
            variant="outlined"
          />
        </Col>
        <Col>
          <TextField
            disabled={view}
            label="Betrag"
            id={`${index + 2}`}
            value={
              bezug.zulagen[`betrag${index + 2}`]
                ? bezug.zulagen[`betrag${index + 2}`]
                : ""
            }
            onChange={handleBezugChange}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
            variant="outlined"
            size="small"
            name={`betrag${index + 2}`}
          />
        </Col>
      </Row>
    );
  }
  return (
    <div>
      <Row>
        <Col></Col>
        <Col>{detail.beg}</Col>
        {dates.map((date) => (
          <Col>{date}</Col>
        ))}
      </Row>
      <Row>
        <Col>Grundgehalt:</Col>
        <Col>
          <NumberFormat
            decimalScale="2"
            fixedDecimalScale={true}
            value={bezug.grundg}
            displayType={"text"}
            thousandSeparator="."
            decimalSeparator=","
            suffix={" €"}
          />
        </Col>
        {diffBes[0].map((step) => (
          <Col>
            <NumberFormat
              decimalScale="2"
              fixedDecimalScale={true}
              value={step[0]}
              displayType={"text"}
              thousandSeparator="."
              decimalSeparator=","
              suffix={" €"}
            />
          </Col>
        ))}
      </Row>
      {bezug.fz > 0 && (
        <Row>
          <Col>Familienzuschlag:</Col>
          <Col>
            <NumberFormat
              decimalScale="2"
              fixedDecimalScale={true}
              value={bezug.fz}
              displayType={"text"}
              thousandSeparator="."
              decimalSeparator=","
              suffix={" €"}
            />
          </Col>
          {diffBes[0].map((step) => (
            <Col>
              <NumberFormat
                decimalScale="2"
                fixedDecimalScale={true}
                value={step[1]}
                displayType={"text"}
                thousandSeparator="."
                decimalSeparator=","
                suffix={" €"}
              />
            </Col>
          ))}
        </Row>
      )}
      {bezug.struk > 0 && (
        <Row>
          <Col>Strukturzulage:</Col>
          <Col>
            <NumberFormat
              decimalScale="2"
              fixedDecimalScale={true}
              value={bezug.struk}
              displayType={"text"}
              thousandSeparator="."
              decimalSeparator=","
              suffix={" €"}
            />
          </Col>
          {diffBes[0].map((step) => (
            <Col>
              <NumberFormat
                decimalScale="2"
                fixedDecimalScale={true}
                value={step[2]}
                displayType={"text"}
                thousandSeparator="."
                decimalSeparator=","
                suffix={" €"}
              />
            </Col>
          ))}
        </Row>
      )}
      {props.vorgang !== "anrechnung" && (
        <Row className="mt-1">
          <Col>
            <TextField
              disabled={view}
              error={
                bezug.zulagen.betrag1 && !bezug.zulagen.name1 ? true : false
              }
              size="small"
              id="1"
              label="weitere Zulage"
              name="name1"
              value={bezug.zulagen.name1 ? bezug.zulagen.name1 : ""}
              onChange={handleBezugChange}
              variant="outlined"
            />
          </Col>
          <Col>
            <TextField
              disabled={view}
              label="Betrag"
              id="1"
              value={bezug.zulagen.betrag1 ? bezug.zulagen.betrag1 : ""}
              onChange={handleBezugChange}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
              variant="outlined"
              size="small"
              name="betrag1"
            />
          </Col>
        </Row>
      )}
      {inputElements}
      {props.vorgang !== "anrechnung" && (
        <Row className="align-items-center">
          <Col>
            <AddCircleOutlineOutlinedIcon
              style={{ cursor: "pointer" }}
              color={view !== true ? "inherit" : "disabled"}
              onClick={view !== true ? handleClickAdd : undefined}
            />
          </Col>
        </Row>
      )}
      <Row className="border-top">
        <Col>rgf. Bezüge:</Col>
        <Col>
          <NumberFormat
            decimalScale="2"
            fixedDecimalScale={true}
            value={bezug.rgfB}
            displayType={"text"}
            thousandSeparator="."
            decimalSeparator=","
            suffix={" €"}
          />
        </Col>
        {diffBes[0].map((step) => (
          <Col>
            <NumberFormat
              decimalScale="2"
              fixedDecimalScale={true}
              value={step[0] + step[1] + step[2]}
              displayType={"text"}
              thousandSeparator="."
              decimalSeparator=","
              suffix={" €"}
            />
          </Col>
        ))}
      </Row>
      <br />
    </div>
  );
};

export default Bezüge;
