import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import BasicDocument from "./bescheide/festsetzung2";
import moment from "moment";
import { useSelector } from "react-redux";

const Bescheide = () => {
  const created = useSelector((state) => state.Vorgang.data.created);
  const [bescheid, setBescheid] = useState("");
  const [datum, setDatum] = useState(moment(created, "X").format("YYYY-MM-DD"));
  const [status, setStatus] = useState("Entwurf");

  const handleChangeDatum = (e) => {
    setDatum(e.target.value);
  };

  const handleChangeStatus = () => {
    if (status === "Entwurf") {
      setStatus("Original");
    } else {
      setStatus("Entwurf");
    }
  };

  useEffect(() => {
    if (created === undefined) {
      setDatum(moment().format("YYYY-MM-DD"));
    }
  }, []);

  return (
    <div>
      {/*instance.loading && <div>Loading ...</div>*/}
      <div className="d-flex mb-2">
        <Button
          onClick={() => setBescheid("anschreiben")}
          variant="outline-secondary"
          className="mr-2"
        >
          Anschreiben
        </Button>
        <TextField
          size="small"
          error={
            datum === "0000-00-00" || datum === 0 || datum === "" ? true : false
          }
          required
          variant="outlined"
          id="date"
          label="Datum des Bescheids"
          type="date"
          name="datum"
          value={datum}
          onChange={handleChangeDatum}
        />
        <Button
          onClick={handleChangeStatus}
          disabled={bescheid === ""}
          className="ml-2"
          variant="outline-secondary"
        >
          {status}
        </Button>
      </div>
      {bescheid === "anschreiben" && (
        <BasicDocument status={status} datum={datum} />
      )}
    </div>
  );
};

export default Bescheide;
