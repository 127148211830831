import { FormControl, InputLabel, Select } from "@material-ui/core";
import { TextField } from "@mui/material";
import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {
  besA,
  besB,
  besC,
  besP,
  besW,
  stufenA,
  stufenB,
  stufenC,
  stufenW,
  stufenP,
} from "../helper/besoldung";
import {
  calc_rgfB,
  calc_versB,
  get_dates_with_changed_values,
  grundg,
  zulage,
} from "../helper/functions";

const AnrechnungAllgemein = () => {
  const data = useSelector((state) => state.person.data);
  const geschl = useSelector((state) => state.person.data.geschl);
  const detail = useSelector((state) => state.detail.data);
  const bezug = useSelector((state) => state.bezug.data);
  const view = useSelector((state) => state.Vorgang.view);
  const anlagen = useSelector((state) => state.Anschreiben.anlagen);
  const hgr85 = useSelector((state) => state.HGR85.data);
  const hgr83 = useSelector((state) => state.HGR83);
  const versB = useSelector((state) => state.versB.data);
  const gesamtzeit = useSelector((state) => state.Zeiten.gesamtzeit);
  const reduxZeiten = useSelector((state) => state.Zeiten);
  const va = useSelector((state) => state.va.va1);
  const rente = useSelector((state) => state.rente.data);
  const dispatch = useDispatch();

  const handleDataChange = (e) => {
    if (e.target.name === "fzK") {
      dispatch({ type: "SET_DATA", payload: { ...data, fzK: e.target.value } });
    } else {
      dispatch({
        type: "SET_DATA",
        payload: { ...data, [e.target.name]: e.target.value },
      });
    }
  };

  const handleDetailChange = (e) => {
    if (e.target.name === "va") {
      dispatch({ type: "SET_DETAIL", payload: { ...detail, va: !detail.va } });
      dispatch({
        type: "SET_ANLAGEN",
        payload: { ...anlagen, anlageVA: !detail.va },
      });
    } else if (e.target.name === "besch") {
      dispatch({
        type: "SET_DETAIL",
        payload: { ...detail, besch: !detail.besch },
      });
    } else if (e.target.name === "wvers") {
      dispatch({
        type: "SET_DETAIL",
        payload: { ...detail, wvers: !detail.wvers },
      });
    } else if (e.target.name === "rente") {
      dispatch({
        type: "SET_DETAIL",
        payload: { ...detail, rente: !detail.rente },
      });
    } else {
      dispatch({
        type: "SET_DETAIL",
        payload: { ...detail, [e.target.name]: e.target.value },
      });
    }
  };

  const handle85Change = (e) => {
    dispatch({
      type: "SET_HGR85",
      payload: {
        ...hgr85,
        [e.target.name]: e.target.value,
      },
    });
  };

  const handleVersBChange = (e) => {
    dispatch({
      type: "SET_VERSB",
      payload: { ...versB, [e.target.name]: e.target.value },
    });
  };

  const getStufen = () => {
    let Stufen = "";
    if (detail.besO === "A") {
      Stufen = stufenA.filter((value, index) => {
        return value.gruppe === detail.besG;
      });
    } else if (detail.besO === "B") {
      Stufen = stufenB.filter((value, index) => {
        return value.gruppe === detail.besG;
      });
    } else if (detail.besO === "C") {
      Stufen = stufenC.filter((value, index) => {
        return value.gruppe === detail.besG;
      });
    } else if (detail.besO === "W") {
      Stufen = stufenW.filter((value, index) => {
        return value.gruppe === detail.besG;
      });
    } else if (detail.besO === "P") {
      Stufen = stufenP.filter((value, index) => {
        return value.gruppe === detail.besG;
      });
    }

    return Stufen.length > 0 ? Stufen[0].stufe : 0;
  };
  //array mit den stufen
  const stufen = getStufen();

  //Bezüge neu berechnen wenn sich detail oder data was geändert hat
  useEffect(() => {
    //wenn zeitpunkt gewählt, besoldung setzten und rechnen
    if (versB.zeitpunkt !== "" && versB.zeitpunkt !== "0000-00-00") {
      const grundgehalt = grundg(
        detail.besO + detail.besG,
        detail.stufe,
        versB.zeitpunkt
      );
      const fam = zulage("fz1", versB.zeitpunkt, detail.besO + detail.besG);
      const strZulage = zulage(
        "struk",
        versB.zeitpunkt,
        detail.besO + detail.besG
      );
      const newFzPer = data.fzPer.replace(",", ".");

      dispatch({
        type: "SET_BEZUG",
        payload: {
          ...bezug,
          grundg: grundgehalt ? +grundgehalt["betrag"] : 0,
          fz:
            fam &&
            (data.fam === "1" ||
              data.fam === "2" ||
              data.fam === "5" ||
              data.fam === "9")
              ? Math.round(fam["betrag"] * newFzPer) / 100
              : 0,
          struk:
            strZulage &&
            detail.besO === "A" &&
            +detail.besG < 12 &&
            detail.status === "RelPäd"
              ? strZulage["betrag"]
              : strZulage &&
                detail.besO === "A" &&
                +detail.besG < 14 &&
                detail.status !== "RelPäd"
              ? strZulage["betrag"]
              : 0,
        },
      });
    }
  }, [detail, data, versB.zeitpunkt]);

  useEffect(() => {
    const dates = get_dates_with_changed_values(versB.zeitpunkt, hgr83, hgr85);
    let diff_bez = [];
    let diff_vers = [];
    dates.forEach((element) => {
      diff_bez.push(calc_rgfB(detail, element));
      diff_vers.push(
        calc_versB(
          gesamtzeit,
          detail,
          element,
          data,
          reduxZeiten,
          va,
          rente,
          hgr85
        )
      );
    });
    dispatch({ type: "SET_DIFF_BEZ", payload: [diff_bez, diff_vers] });
  }, []);

  return (
    <div>
      Allg. Daten
      <Row className="mt-2">
        <Col>
          <TextField
            disabled={true}
            id="outlined-error-helper-text"
            label="Personalnummer"
            name="id"
            value={data.id}
            variant="outlined"
          />
        </Col>
      </Row>
      <Row>
        <Col className="mt-2">
          <FormControl variant="outlined">
            <InputLabel>Titel</InputLabel>
            <Select
              disabled
              native
              label="Titel"
              name="titel"
              value={data.titel}
            >
              <option aria-label="None" value="" />
              <option value={1}>Dr.</option>
              <option value={2}>Prof.</option>
              <option value={3}>Prof. Dr.</option>
            </Select>
          </FormControl>
        </Col>

        <Col className="mt-2">
          <TextField
            disabled
            id="outlined-basic"
            label="Vorname"
            variant="outlined"
            required
            name="vorname"
            value={data.vorname}
          />
        </Col>

        <Col className="mt-2">
          <TextField
            disabled
            id="outlined-basic"
            label="Nachname"
            variant="outlined"
            required
            name="nachname"
            value={data.nachname}
          />
        </Col>
        <Col className="mt-2">
          <TextField
            disabled
            variant="outlined"
            required
            id="date"
            label="Geburtsdatum"
            type="date"
            name="birthDate"
            defaultValue={data.birthDate}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Col>
        <Col className="mt-2">
          <FormControl
            disabled
            required
            style={{ minWidth: "140px" }}
            variant="outlined"
          >
            <InputLabel>Geschlecht</InputLabel>
            <Select
              disabled
              native
              label="Geschlecht"
              name="geschl"
              value={data.geschl}
            >
              <option aria-label="None" value="" />
              <option value={1}>M</option>
              <option value={2}>W</option>
            </Select>
          </FormControl>
        </Col>
      </Row>
      <Row>
        <Col className="mt-2">
          <FormControl
            disabled={view}
            required
            style={{ minWidth: "160px" }}
            variant="outlined"
          >
            <InputLabel>Familienstand</InputLabel>
            <Select
              disabled={view}
              error={data.fam === "" ? true : false}
              native
              label="familienstand"
              name="fam"
              value={data.fam}
              onChange={handleDataChange}
            >
              <option aria-label="None" value="" />
              <option value={0}>ledig</option>
              <option value={1}>verh.</option>
              <option value={2}>verwitwet</option>
              <option value={3}>geschieden</option>
              <option value={5}>getr. lebend</option>
              <option value={9}>reg. Partnerschaft</option>
            </Select>
          </FormControl>
        </Col>

        <Col className="mt-2">
          <FormControl
            disabled={view}
            required
            style={{ minWidth: "160px" }}
            variant="outlined"
          >
            <InputLabel>Steuerklasse</InputLabel>
            <Select
              disabled={view}
              error={!data.stkl ? true : false}
              native
              label="Steuerklasse"
              name="stkl"
              value={data.stkl}
              onChange={handleDataChange}
            >
              <option aria-label="None" value="" />
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
            </Select>
          </FormControl>
        </Col>

        <Col className="mt-2">
          <TextField
            disabled={view}
            id="outlined-error-helper-text"
            label="Familienzuschlag Stufe 1 (%)"
            name="fzPer"
            value={data.fzPer}
            onChange={handleDataChange}
            variant="outlined"
            error={data.fzPer > 100}
          />
        </Col>
        <Col className="mt-2">
          <TextField
            disabled={view}
            id="outlined-error-helper-text"
            label="Familienzuschlag (Kind)"
            name="fzK"
            value={data.fzK}
            onChange={handleDataChange}
            variant="outlined"
          />
        </Col>
        <Col>
          <FormControlLabel
            control={
              <Checkbox
                disabled
                color="primary"
                checked={data.GdB}
                name="GdB"
              />
            }
            label="Schwerbehinderung?"
          />
        </Col>
      </Row>
      <Row>
        <Col className="mt-2">
          <FormControl
            required
            style={{ minWidth: "200px" }}
            variant="outlined"
          >
            <InputLabel>Rechtsstatus</InputLabel>
            <Select
              disabled
              native
              label="Rechtsstatus"
              name="status"
              value={detail.status}
            >
              <option aria-label="None" value="" />
              <option value={"Pfarrer"}>
                {geschl === 1
                  ? "Pfarrer"
                  : geschl === 2
                  ? "Pfarrerin"
                  : "Pfarrer"}
              </option>
              <option value={"Beamter"}>
                {geschl === 1
                  ? "Kirchenbeamter"
                  : geschl === 2
                  ? "Kirchenbeamtin"
                  : "Kirchenbeamter"}
              </option>
              <option value={"Diakon"}>
                {geschl === 1 ? "Diakon" : geschl === 2 ? "Diakonin" : "Diakon"}
              </option>
              <option value={"RelPäd"}>
                {geschl === 1
                  ? "Religionspädagoge"
                  : geschl === 2
                  ? "Religionspädagogin"
                  : "Religionspädagoge"}
              </option>
            </Select>
          </FormControl>
        </Col>
        <Col className="mt-2">
          <TextField
            disabled
            required
            variant="outlined"
            id="date"
            label="Beginn Beamtv."
            type="date"
            name="begBeamt"
            value={detail.begBeamt}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Col>
        <Col className="mt-2">
          <FormControl required variant="outlined">
            <InputLabel>Grund</InputLabel>
            <Select
              disabled
              error={!detail.grund ? true : false}
              native
              label="Grund"
              name="grund"
              value={detail.grund}
            >
              <option aria-label="None" value="" />
              <option value={1}>Regelaltersgrenze</option>
              <option value={2}>auf Antrag</option>
              <option value={3}>auf Antrag (GdB &gt; 50)</option>
              <option value={4}>Dienstunfähigkeit</option>
              <option value={5}>Dienstunfähigkeit (Dienstunfall)</option>
              <option value={6}>aus dem Wartestand</option>
              <option value={7}>Urteil d. Disziplinargerichts</option>
            </Select>
          </FormControl>
          {detail.grund === "5" && (
            <FormControl
              className="mt-2"
              required
              variant="outlined"
              size="small"
            >
              <InputLabel>MdE</InputLabel>
              <Select
                disabled={view}
                native
                label="MdE"
                name="mde"
                value={hgr85.mde}
                onChange={handle85Change}
              >
                <option aria-label="None" value="" />
                <option value={10}>10 %</option>
                <option value={20}>20 %</option>
                <option value={30}>30 %</option>
                <option value={40}>40 %</option>
                <option value={50}>50 %</option>
                <option value={60}>60 %</option>
                <option value={70}>70 %</option>
                <option value={80}>80 %</option>
                <option value={90}>90 %</option>
                <option value={100}>100 %</option>
              </Select>
            </FormControl>
          )}
        </Col>
        <Col className="mt-2">
          <TextField
            disabled
            required
            variant="outlined"
            id="date"
            label="Beginn"
            type="date"
            name="beg"
            value={detail.beg}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Col>
        <Col className="mt-2">
          <TextField
            disabled={view}
            error={
              !versB.zeitpunkt ||
              versB.zeitpunkt === "" ||
              versB.zeitpunkt === "Invalid date" ||
              versB.zeitpunkt === "0000-00-00" ||
              versB.zeitpunkt < detail.beg
                ? true
                : false
            }
            required
            variant="outlined"
            id="date"
            label="Zeitpunkt"
            type="date"
            name="zeitpunkt"
            value={versB.zeitpunkt}
            onChange={handleVersBChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col className="mt-2">
          <TextField
            disabled
            variant="outlined"
            id="date"
            label="abw. Altersgrenze"
            type="date"
            name="schule"
            value={detail.schule}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Col>
        <Col className="mt-2">
          <FormControl
            required
            style={{ minWidth: "200px" }}
            variant="outlined"
          >
            <InputLabel>Besoldungsordnung</InputLabel>
            <Select
              disabled
              native
              label="Besoldungsordnung"
              name="besO"
              value={detail.besO}
            >
              <option aria-label="None" value="" />
              <option value={"A"}>A</option>
              <option value={"B"}>B</option>
              <option value={"C"}>C</option>
              <option value={"P"}>P</option>
              <option value={"W"}>W</option>
            </Select>
          </FormControl>
        </Col>
        <Col className="mt-2">
          <FormControl
            style={{ minWidth: "200px" }}
            required
            variant="outlined"
          >
            <InputLabel>Besoldungsgruppe</InputLabel>
            <Select
              disabled
              native
              label="Besoldungsgruppe"
              name="besG"
              value={detail.besG}
            >
              <option aria-label="None" value="" />
              {detail.besO === "A" ? (
                besA.map((x, y) => (
                  <option value={x < 10 ? "0" + x : x} key={y}>
                    {x}
                  </option>
                ))
              ) : detail.besO === "B" ? (
                besB.map((x, y) => (
                  <option value={x < 10 ? "0" + x : x} key={y}>
                    {x}
                  </option>
                ))
              ) : detail.besO === "C" ? (
                besC.map((x, y) => (
                  <option value={x < 10 ? "0" + x : x} key={y}>
                    {x}
                  </option>
                ))
              ) : detail.besO === "P" ? (
                besP.map((x, y) => (
                  <option value={x < 10 ? "0" + x : x} key={y}>
                    {x}
                  </option>
                ))
              ) : detail.besO === "W" ? (
                besW.map((x, y) => (
                  <option value={x < 10 ? "0" + x : x} key={y}>
                    {x}
                  </option>
                ))
              ) : (
                <option aria-label="None" value="" />
              )}
            </Select>
          </FormControl>
        </Col>
        <Col className="mt-2">
          <FormControl required variant="outlined">
            <InputLabel>Stufe</InputLabel>
            <Select
              native
              label="Stufe"
              name="stufe"
              value={detail.stufe}
              disabled
            >
              <option aria-label="None" value="" />
              {stufen !== 0 &&
                stufen.map((x, y) => <option key={y}>{x}</option>)}
            </Select>
          </FormControl>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormControlLabel
            disabled
            control={
              <Checkbox
                color="primary"
                checked={detail.schuldienst}
                name="schuldienst"
              />
            }
            label="Abweichender Zeitpunkt wg. Schuldienst?"
          />
        </Col>
        <Col>
          <FormControlLabel
            disabled={view}
            control={
              <Checkbox
                color="primary"
                checked={detail.va}
                onChange={handleDetailChange}
                name="va"
              />
            }
            label="Versorgungsausgleich?"
          />
        </Col>
        <Col>
          <FormControlLabel
            disabled={view}
            control={
              <Checkbox
                color="primary"
                checked={detail.besch}
                onChange={handleDetailChange}
                name="besch"
              />
            }
            label="weiteres Beschäftigungsverhältnis?"
          />
        </Col>
        <Col>
          <FormControlLabel
            disabled={view}
            control={
              <Checkbox
                color="primary"
                checked={detail.wvers}
                onChange={handleDetailChange}
                name="wvers"
              />
            }
            label="weiterer Versorgungsbezug?"
          />
        </Col>
        <Col>
          <FormControlLabel
            disabled={view}
            control={
              <Checkbox
                color="primary"
                checked={detail.rente}
                onChange={handleDetailChange}
                name="rente"
              />
            }
            label="Rentenanrechnung?"
          />
        </Col>
      </Row>
    </div>
  );
};

export default AnrechnungAllgemein;
