import React from "react";
import moment from "moment";
import { Col, Row, Table } from "react-bootstrap";
import NumberFormat from "react-number-format";

const MischRecht = (props) => {
  const zeiten = props.zeiten;
  const anzZei = props.anzZei;
  const gesamtzeit = props.gesamtzeit;
  const iteration = anzZei;
  const inputElements = [];

  for (let index = 0; index < iteration; index++) {
    //underline the last element (iteration-index) === 1
    const style_underline = "align-self-end border-bottom border-dark";

    inputElements.push(
      <tr key={index}>
        <td>
          {zeiten[`z${index + 1}`] && zeiten[`z${index + 1}`].ze
            ? zeiten[`z${index + 1}`].ze
            : 0}
        </td>
        <td>
          {zeiten[`z${index + 1}`]
            ? moment(zeiten[`z${index + 1}`].begDa).format("DD.MM.YYYY")
            : ""}
        </td>
        <td>
          {zeiten[`z${index + 1}`]
            ? moment(zeiten[`z${index + 1}`].endDa).format("DD.MM.YYYY")
            : ""}
        </td>
        <td>{zeiten[`z${index + 1}`] ? zeiten[`z${index + 1}`].za : ""}</td>
        <td>{zeiten[`z${index + 1}`] ? zeiten[`z${index + 1}`].n : ""}</td>
        {gesamtzeit[13][`z${index + 1}`] && (
          <>
            <td className={style_underline}>
              {gesamtzeit[13][`z${index + 1}`][0] + " Jahre "}
            </td>
            <td className={style_underline}>
              {gesamtzeit[13][`z${index + 1}`][1] + " Tage"}
            </td>
          </>
        )}
      </tr>
    );
  }

  return (
    <div>
      {(gesamtzeit[0] > 0 || gesamtzeit[1] > 0) && (
        <div>
          <Table borderless striped>
            <thead>
              <tr className="border-bottom border-dark">
                <td>Typ</td>
                <td>Beginn</td>
                <td>Ende</td>
                <td>Zähler</td>
                <td>Nenner</td>
                <td></td>
                <td></td>
              </tr>
            </thead>
            <tbody>{inputElements}</tbody>
          </Table>
          <div className="mt-1 mb-1 pt-1 pb-1">
            Mischrecht{" "}
            {gesamtzeit[11] && (
              <span className="text-danger">
                (rgf. Dienstzeit erst ab dem 27. Lebensjahr (
                {moment(gesamtzeit[12]).format("DD.MM.YYYY")})
              </span>
            )}
          </div>
          <Row>
            <Col>Zeiten nach Mischrecht</Col>
            <Col>{gesamtzeit[0]} Jahre</Col>
            <Col>{gesamtzeit[1]} Tage</Col>
          </Row>
          <Row>
            <Col>Dezimaljahre</Col>
            <Col></Col>
            <Col>
              <NumberFormat
                decimalScale="2"
                fixedDecimalScale={true}
                value={gesamtzeit[2]}
                displayType={"text"}
                thousandSeparator="."
                decimalSeparator=","
              />
            </Col>
          </Row>
          <Row>
            <Col>Zeiten nach altem Recht x Faktor</Col>
            <Col></Col>
            <Col>
              <NumberFormat
                decimalScale="2"
                fixedDecimalScale={true}
                value={gesamtzeit[6]}
                displayType={"text"}
                thousandSeparator="."
                decimalSeparator=","
                suffix={" %"}
              />
            </Col>
          </Row>
          <Row>
            <Col>Zeiten nach neuem Recht</Col>
            <Col>{gesamtzeit[3]} Jahre</Col>
            <Col>{gesamtzeit[4]} Tage</Col>
          </Row>
          <Row>
            <Col>Beachtung 10-Jahres-Frist</Col>
            <Col></Col>
            <Col>- {gesamtzeit[5]} Jahre</Col>
          </Row>
          <Row>
            <Col>Zeit nach neuem Recht x Faktor</Col>
            <Col></Col>
            <Col>
              <NumberFormat
                decimalScale="2"
                fixedDecimalScale={true}
                value={gesamtzeit[7]}
                displayType={"text"}
                thousandSeparator="."
                decimalSeparator=","
                suffix={" %"}
              />
            </Col>
          </Row>
          <Row>
            <Col>Sockel</Col>
            <Col></Col>
            <Col>35,00 %</Col>
          </Row>
          <Row>
            <Col>Ruhegehaltsatz</Col>
            <Col></Col>
            <Col>
              <NumberFormat
                decimalScale="2"
                fixedDecimalScale={true}
                value={gesamtzeit[8]}
                displayType={"text"}
                thousandSeparator="."
                decimalSeparator=","
                suffix={" %"}
              />
            </Col>
          </Row>
          <Row>
            <Col>Multipliziert x 0,95667</Col>
            <Col></Col>
            <Col>
              <NumberFormat
                decimalScale="2"
                fixedDecimalScale={true}
                value={gesamtzeit[9]}
                displayType={"text"}
                thousandSeparator="."
                decimalSeparator=","
                suffix={" %"}
              />
            </Col>
          </Row>
          {gesamtzeit[9] > 71.75 && (
            <Row>
              <Col>Aber maximal</Col>
              <Col></Col>
              <Col>
                <NumberFormat
                  decimalScale="2"
                  fixedDecimalScale={true}
                  value={gesamtzeit[10]}
                  displayType={"text"}
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix={" %"}
                />
              </Col>
            </Row>
          )}
        </div>
      )}
    </div>
  );
};

export default MischRecht;
