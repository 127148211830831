import React from "react";
import { Text, View } from "@react-pdf/renderer";

const Foot = () => {
  return (
    <View
      style={{
        fontSize: "6pt",
        flexDirection: "row",
        position: "absolute",
        paddingLeft: "19mm",
        paddingBottom: "15mm",
        bottom: 0,
        width: "100%",
      }}
    >
      <View style={{ marginRight: "30px", flexGrow: 2 }}>
        <Text>Hausanschrift:</Text>
        <Text>Katharina-von-Bora-Str. 5-13</Text>
        <Text>80333 München</Text>
      </View>
      <View style={{ marginRight: "30px", flexGrow: 3 }}>
        <Text>Zentrale:</Text>
        <Text>Telefon 089 5595 - 0</Text>
        <Text>Fax 089 5595 - 444</Text>
      </View>
      <View style={{ marginRight: "30px", flexGrow: 4 }}>
        <Text>Konten der Landeskirchenkasse:</Text>
        <Text>Evangelische Bank eG</Text>
        <Text>Konto 10 10 107, BLZ 520 604 10</Text>
        <Text>IBAN: DE57 5206 0410 0001 0101 07</Text>
        <Text>BIC: GENODEF1EK1</Text>
      </View>
      <View
        style={{
          borderRight: "1px solid black",
          marginRight: "19.33mm",
          flexGrow: 5,
        }}
      >
        <Text>Bayer. Landesbank München</Text>
        <Text>Konto 24 144, BLZ 700 500 00</Text>
        <Text>IBAN: DE07 7005 0000 0000 0241 44</Text>
        <Text>BIC: BYLADEMMXXX</Text>
      </View>
    </View>
  );
};

export default Foot;
