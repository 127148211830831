import { SET_VORGANG, SET_VIEW } from "../constants/actionTypes";

const initialState = {
  data: {
    id: 0,
  },
  view: false
};

const Vorgang = (state = initialState, action) => {
  switch (action.type) {
    case SET_VORGANG:
      return {
        ...state,
        data: action.payload,
      };
    case SET_VIEW:
      return {
        ...state,
        view: !state.view
      }
    default:
      return state;
  }
};

export default Vorgang;
