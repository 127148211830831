import { SET_RENTE, SET_RENTE_CHANGE } from "../constants/actionTypes";

const initialState = {
  data: {
    anrechnungsGes: 0,
    beg: "",
    control: true,
    dateBescheid: "",
    einkommen: 0,
    einkommen85: 0,
    einkommenNicht: 0,
    einkommenVNG: 0,
    entgeltpunkte: 0,
    entgeltpunkteFrei: 0,
    entgeltpunkteGes: 0,
    faktorZuschlagHinterbl: 1,
    freiwPunkte85: 0,
    freiwPunkteNicht: 0,
    freiwPunkteVNG: 0,
    höchstbetragRente: 0,
    höherVers: 0,
    höherVers85: 0,
    höherVersNicht: 0,
    höherVersVNG: 0,
    pan: "",
    persPunkte: 0,
    punkte85: 0,
    punkteNachZugangsfaktor: 0,
    punkteVNG: 0,
    rente: 0,
    rente85: 0,
    rente85nachEinkommen: 0,
    rente85nachHöher: 0,
    renteFreiwNicht: -0,
    renteHöherNicht: -0,
    renteNachEinkommen: 0,
    renteVNG: 0,
    renteVNGnachEinkommen: 0,
    renteVNGnachHöher: 0,
    renteVorEinkommen: 0,
    renteZuschlagHinterblNicht: -0,
    rentenart: "",
    rentenfaktor: 1.00,
    rentennummer: "",
    summePersPunkte: 0,
    va: 0,
    vaWert: -0,
    wert: 0,
    zahlbetrag: 0,
    zugangsfaktor: 1,
    zuschlagHinterbl: 0,
    zuschlagHinterbl85: 0,
    zuschlagHinterblNicht: 0,
    zuschlagHinterblVNG: 0
  },
  change: false
}

const rente = (state=initialState, action) => {
    switch (action.type) {
        case SET_RENTE:
            return{
              ...state,
              data: action.payload
            }
        case SET_RENTE_CHANGE:
          return {
            ...state,
            change: !state.change
          }
        default:
        return state;   
    }
}

export default rente;