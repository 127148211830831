import React from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import Logo from "./assets/elkb.jpg";
import Start from "./components/start";
import Festsetzung from "./components/festsetzung/festsetzung";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Anrechnung from "./components/anrechnung/anrechnung";
import Stabgz from "./components/stabgz/stabgz";
import OverViewStabgz from "./components/stabgz/overview";
import MindMap from "./components/tree";
import Umlage from "./components/umlage/umlage";
import Sonderzahlung from "./components/sonderzahlung/sz";
import FZ from "./components/fz/fz";

function App() {
  return (
    <div className="container-fluid">
      <Router>
        <Row className="border-bottom pb-1 justify-content-between">
          <Col className="align-self-end">
            <h2>Versorgung</h2>
          </Col>
          <Col className="text-end">
            <img style={{ height: "9vh" }} src={Logo} alt="Logo" />
          </Col>
        </Row>
        <Switch>
          {/* The default route */}
          <Route exact path="/">
            <Start />
          </Route>
          <Route path="/festsetzung">
            <Festsetzung />
          </Route>
          <Route path="/anrechnung">
            <Anrechnung />
          </Route>
          <Route exact path="/stabgz">
            <OverViewStabgz />
          </Route>
          <Route path="/stabgz/detail">
            <Stabgz />
          </Route>
          <Route path="/mindMap">
            <MindMap />
          </Route>
          <Route path="/umlage">
            <Umlage />
          </Route>
          <Route path="/sz">
            <Sonderzahlung />
          </Route>
          <Route path="/fz">
            <FZ />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
