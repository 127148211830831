import { Button, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Row } from "react-bootstrap";
import SearchIcon from "@material-ui/icons/Search";
import QueueIcon from "@material-ui/icons/Queue";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const Search = React.memo(() => {
  const dispatch = useDispatch();

  const [id, setId] = useState("");
  const [vorname, setVorname] = useState("");
  const [nachname, setNachname] = useState("");

  const [response, setResponse] = useState({});
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));
  const classes = useStyles();

  const loadData = async () => {
    setLoading(true);
    const response = await fetch(
      //`http://localhost/versorgung/rest-api/abfragen.php`,
      `https://versorgung.hosting142616.a2e76.netcup.net/rest-api/abfragen.php`,
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify({ id: +id, vorname: vorname, nachname: nachname }),
      }
    );
    const data = await response.json();
    if (data) {
      setResponse(data);
      setLoading(false);
    }
  };

  const setPerson = async (id) => {
    setLoading(true);
    //GdB von 1 aus der DB "umrechnen" in true und false
    response[id].GdB = response[id].GdB === 1 ? true : false;

    dispatch({ type: "SET_DATA", payload: response[id] });

    const detail = await fetch(
      //`http://localhost/versorgung/rest-api/other.php?typ=set`,
      `https://versorgung.hosting142616.a2e76.netcup.net/rest-api/other.php?typ=set`,
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify({ id: response[id].id }),
      }
    );
    const data = await detail.json();
    if (data) {
      //den Rest von 1 aus der DB "umrechnen" in true und false
      data[0][0].schuldienst = data[0][0].schuldienst === 1 ? true : false;
      data[0][0].va = data[0][0].va === 1 ? true : false;
      data[0][0].besch = data[0][0].besch === 1 ? true : false;
      data[0][0].wvers = data[0][0].wvers === 1 ? true : false;
      data[0][0].rente = data[0][0].rente === 1 ? true : false;

      dispatch({ type: "SET_VORGAENGE", payload: data[2] });
      dispatch({ type: "SET_DETAIL", payload: data[0][0] });
      dispatch({ type: "SET_ANSCHREIBEN", payload: data[1][0] });
      setLoading(false);
    }

    setId("");
    setVorname("");
    setNachname("");
    setResponse({});
  };

  const handleDataChange = (e) => {
    if (e.target.name === "id") {
      setId(e.target.value);
    } else if (e.target.name === "vorname") {
      setVorname(e.target.value);
    } else if (e.target.name === "nachname") {
      setNachname(e.target.value);
    }
  };

  const handleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <div>
      {loading && (
        <Backdrop open={loading} className={classes.backdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Row className="mt-2">
        <Col md="auto pr-0">
          <TextField
            id="outlined-error-helper-text"
            label="Personalnummer"
            name="id"
            value={id}
            onChange={handleDataChange}
            variant="outlined"
            size="small"
          />
        </Col>
        <Col className="d-flex align-items-center pl-0">
          <QueueIcon
            className="ml-1 mr-1"
            onClick={handleModal}
            style={{ cursor: "pointer" }}
          />{" "}
          <SearchIcon onClick={loadData} style={{ cursor: "pointer" }} />
        </Col>
      </Row>
      <Row>
        {response.length >= 1 &&
          response.map((person, id) => {
            return (
              <Col key={person.id}>
                <Button
                  onClick={() => setPerson(id)}
                  variant="outlined"
                  color="primary"
                >
                  {person.id} ({person.vorname[0] + ". " + person.nachname})
                </Button>
              </Col>
            );
          })}
      </Row>

      <Modal open={modalOpen} onClose={handleModal}>
        <div
          style={{
            display: "grid",
            backgroundColor: "LightGrey",
            border: "2px solid #000",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: `translate(-${50}%, -${50}%)`,
            width: "400px",
            height: "200px",
          }}
        >
          <Row className="mr-0 ml-0 mt-4 justify-content-center">
            <Col md="auto">
              <TextField
                //error={!props.data.vorname ? true : false}
                id="outlined-basic"
                label="Vorname"
                variant="outlined"
                required
                name="vorname"
                value={vorname}
                onChange={handleDataChange}
              />
            </Col>
          </Row>
          <Row className="mr-0 ml-0 justify-content-center">
            <Col md="auto">
              <TextField
                //error={!props.data.nachname ? true : false}
                id="outlined-basic"
                label="Nachname"
                variant="outlined"
                required
                name="nachname"
                value={nachname}
                onChange={handleDataChange}
              />
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
});

export default Search;
