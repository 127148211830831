import {
  FormControl,
  InputAdornment,
  InputLabel,
  Select,
  Switch,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { getHGR83 } from "./helper/functions";
import Button from "@material-ui/core/Button";

const Hgr83 = () => {
  const dispatch = useDispatch();
  const [selected83, setSelected83] = useState("hgr83_1");
  const hgr83 = useSelector((state) => state.HGR83[selected83]);
  const count = useSelector((state) => state.HGR83.count);
  const hgr85 = useSelector((state) => state.HGR85.data);
  const allHGR = useSelector((state) => state.HGR83);
  const detail = useSelector((state) => state.detail.data);
  const bezug = useSelector((state) => state.bezug.data);
  const versB = useSelector((state) => state.versB.data);
  const data = useSelector((state) => state.person.data);
  const view = useSelector((state) => state.Vorgang.view);
  const gesamtzeit = useSelector((state) => state.Zeiten.gesamtzeit);
  const reduxZeiten = useSelector((state) => state.Zeiten);
  const rente = useSelector((state) => state.rente.data);
  const va = useSelector((state) => state.va.va1);
  const [hgrF, setHgrF] = useState([
    0, 0, 0, 0, 100, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);

  const handle83Change = (e) => {
    if (e.target.name === "bezugsArt") {
      if (e.target.checked === true) {
        e.target.value = "jährlich";
      } else {
        e.target.value = "monatlich";
      }
    }
    if (e.target.name !== "mindest") {
      dispatch({
        type: "SET_HGR83",
        hgr83: selected83,
        payload: { ...hgr83, [e.target.name]: e.target.value },
      });
    } else {
      dispatch({
        type: "SET_HGR83",
        hgr83: selected83,
        payload: { ...hgr83, [e.target.name]: e.target.checked },
      });
    }
  };

  const handleAdd83 = () => {
    dispatch({ type: "ADD_HGR83" });
  };

  useEffect(() => {
    if (hgr83.beg !== "0000-00-00" && hgr83.beg !== "") {
      const hgr = getHGR83(
        hgr83,
        detail,
        bezug,
        data,
        gesamtzeit,
        reduxZeiten,
        allHGR,
        va,
        rente,
        hgr85
      );
      setHgrF(hgr);
      dispatch({
        type: "SET_HGR83",
        hgr83: selected83,
        payload: {
          ...hgr83,
          grundgehalt: hgr[0],
          stufe: hgr[1],
          fam: hgr[2],
          strZulage: hgr[3],
          rgs: hgr[4],
          erh: hgr[5],
          hgr: hgr[6],
          ruhegehalt: hgr[7],
          gesamtV: hgr[8],
          ruhe83: hgr[9],
          versBezugAfter83: hgr[10],
          versBezugAfterMin: hgr[11],
          ruhe83AfterMin: hgr[12],
          mind: hgr[13],
          kirchV: hgr[14],
          fzK: hgr[15],
          GesEinkommen: hgr[16],
        },
      });
    } else {
      setHgrF([0, 0, 0, 0, 100, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    }
  }, [
    hgr83.beg,
    hgr83.end,
    hgr83.einkommen,
    hgr83.mindest,
    hgr83.bezugsArt,
    detail,
    bezug,
    versB,
    data,
    gesamtzeit,
    reduxZeiten,
  ]);

  //in der drüberen useEffect wird immer nur die aktulle hgr berechnet
  //diese useEffect dient dazu auch die weitern hgr zu berechnen wenn die seite das erste
  //mal aufgerufen wird.
  //in der Regel sind das hier die hgr83_2 usw...
  useEffect(() => {
    for (let index = 0; index < count - 1; index++) {
      if (
        allHGR[`hgr83_${index + 2}`].beg !== "0000-00-00" &&
        allHGR[`hgr83_${index + 2}`].beg !== ""
      ) {
        const hgr = getHGR83(
          allHGR[`hgr83_${index + 2}`],
          detail,
          bezug,
          data,
          gesamtzeit,
          reduxZeiten,
          allHGR,
          va,
          rente,
          hgr85
        );
        dispatch({
          type: "SET_HGR83",
          hgr83: `hgr83_${index + 2}`,
          payload: {
            ...allHGR[`hgr83_${index + 2}`],
            grundgehalt: hgr[0],
            stufe: hgr[1],
            fam: hgr[2],
            strZulage: hgr[3],
            rgs: hgr[4],
            erh: hgr[5],
            hgr: hgr[6],
            ruhegehalt: hgr[7],
            gesamtV: hgr[8],
            ruhe83: hgr[9],
            versBezugAfter83: hgr[10],
            versBezugAfterMin: hgr[11],
            ruhe83AfterMin: hgr[12],
            mind: hgr[13],
            kirchV: hgr[14],
            fzK: hgr[15],
            GesEinkommen: hgr[16],
          },
        });
      }
    }
  }, [
    hgr83.beg,
    hgr83.end,
    hgr83.einkommen,
    hgr83.mindest,
    hgr83.bezugsArt,
    detail,
    bezug,
    versB,
    data,
    gesamtzeit,
    reduxZeiten,
  ]);

  //Buttons bei mehreren VAs
  const Buttons = [];
  for (let index = 0; index < count; index++) {
    Buttons.push(
      <Button
        key={index}
        onClick={() => setSelected83(`hgr83_${index + 1}`)}
        variant="outlined"
      >{`Datensatz${index + 1}`}</Button>
    );
  }
  return (
    <div>
      <h3>aktuell {selected83}</h3>
      {count > 1 && Buttons}
      <Button variant="outlined" onClick={handleAdd83}>
        weiteren Satz anlegen
      </Button>
      <div>Art. 83 BayBeamtVG</div>
      <div>
        <Row>
          <Col>Zeitraum</Col>
          <Col>
            <TextField
              size="small"
              disabled={view}
              error={
                hgr83.beg === "0000-00-00" ||
                hgr83.beg === 0 ||
                hgr83.beg === ""
                  ? true
                  : false
              }
              required
              variant="outlined"
              id="date"
              label="Beginn"
              type="date"
              name="beg"
              value={hgr83.beg}
              onChange={handle83Change}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Col>
          <Col>
            <TextField
              size="small"
              disabled={view}
              error={
                hgr83.end === "0000-00-00" ||
                hgr83.end === 0 ||
                hgr83.end === ""
                  ? true
                  : false
              }
              required
              variant="outlined"
              id="date"
              label="Ende"
              type="date"
              name="end"
              value={hgr83.end}
              onChange={handle83Change}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>Einkommensart</Col>
          <Col>
            <FormControl size="small" required variant="outlined">
              <InputLabel>Art des Einkommens</InputLabel>
              <Select
                disabled={view}
                error={!hgr83.art ? true : false}
                native
                label="Art des Einkommens"
                name="art"
                value={hgr83.art}
                onChange={handle83Change}
              >
                <option aria-label="None" value="" />
                <option value={1}>Erwerbs- oder Erwerbsersatzeinkommen</option>
                <option value={2}>Verwendungseinkommen</option>
              </Select>
            </FormControl>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>Arbeitgeber</Col>
          <Col>
            <TextField
              name="arbeitg"
              size="small"
              variant="outlined"
              label="Arbeitgeber"
              value={hgr83.arbeitg}
              onChange={handle83Change}
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>Bezugsart</Col>
          <Col>
            monatlich
            <Switch
              name="bezugsArt"
              value={hgr83.bezugsArt}
              checked={hgr83.bezugsArt === "jährlich" ? true : false}
              onChange={handle83Change}
            />
            jährlich
          </Col>
        </Row>
        <Row>
          <Col>Einkommen</Col>
          <Col>
            <TextField
              disabled={view}
              size="small"
              label="Betrag"
              value={hgr83.einkommen}
              onChange={handle83Change}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
              variant="outlined"
              name="einkommen"
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>Mindestbelassung?</Col>
          <Col>
            nein
            <Switch
              name="mindest"
              value={hgr83.mindest}
              checked={hgr83.mindest}
              onChange={handle83Change}
            />
            ja
          </Col>
        </Row>
      </div>
      <div>
        Höchstgrenzenberechnung
        <Row>
          <Col></Col>
          {Object.values(allHGR).map((hgr) => {
            return hgr.beg !== undefined && <Col>{hgr.beg}</Col>;
          })}
        </Row>
        <Row>
          <Col>Grundgehalt</Col>
          {Object.values(allHGR).map((hgr) => {
            return (
              hgr.beg !== undefined && (
                <Col>
                  <NumberFormat
                    decimalScale="2"
                    fixedDecimalScale={true}
                    value={hgr.grundgehalt}
                    displayType={"text"}
                    thousandSeparator="."
                    decimalSeparator=","
                    suffix={" €"}
                  />
                </Col>
              )
            );
          })}
        </Row>
        {bezug.fz > 0 && (
          <Row>
            <Col>Familienzuschlag</Col>
            {Object.values(allHGR).map((hgr) => {
              return (
                hgr.beg !== undefined && (
                  <Col>
                    <NumberFormat
                      decimalScale="2"
                      fixedDecimalScale={true}
                      value={hgr.fam}
                      displayType={"text"}
                      thousandSeparator="."
                      decimalSeparator=","
                      suffix={" €"}
                    />
                  </Col>
                )
              );
            })}
          </Row>
        )}
        {bezug.struk > 0 && (
          <Row>
            <Col>Strukturzulage</Col>
            {Object.values(allHGR).map((hgr) => {
              return (
                hgr.beg !== undefined && (
                  <Col>
                    <NumberFormat
                      decimalScale="2"
                      fixedDecimalScale={true}
                      value={hgr.strZulage}
                      displayType={"text"}
                      thousandSeparator="."
                      decimalSeparator=","
                      suffix={" €"}
                    />
                  </Col>
                )
              );
            })}
          </Row>
        )}
        {bezug.sumZulagen > 0 && (
          <Row>
            <Col>Summe sonstiger Zulagen</Col>
            <Col>
              <NumberFormat
                decimalScale="2"
                fixedDecimalScale={true}
                value={bezug.sumZulagen}
                displayType={"text"}
                thousandSeparator="."
                decimalSeparator=","
                suffix={" €"}
              />
            </Col>
          </Row>
        )}
        <Row className="border-top">
          <Col>
            <b>ruhegehaltfähige Bezüge</b>
          </Col>
          {Object.values(allHGR).map((hgr) => {
            return (
              hgr.beg !== undefined && (
                <Col>
                  <NumberFormat
                    decimalScale="2"
                    fixedDecimalScale={true}
                    value={hgr.grundgehalt + hgr.fam + hgr.strZulage}
                    displayType={"text"}
                    thousandSeparator="."
                    decimalSeparator=","
                    suffix={" €"}
                  />
                </Col>
              )
            );
          })}
        </Row>
        {detail.grund === "5" && (
          <Row>
            <Col>mind. der Höchstgrenze auf 71,75 %</Col>
            {Object.values(allHGR).map((hgr) => {
              return (
                hgr.beg !== undefined && (
                  <Col>
                    <NumberFormat
                      decimalScale="2"
                      fixedDecimalScale={true}
                      value={hgr.ruhegehalt}
                      displayType={"text"}
                      thousandSeparator="."
                      decimalSeparator=","
                      suffix={" €"}
                    />
                  </Col>
                )
              );
            })}
          </Row>
        )}
        {versB.fzK > 0 && (
          <Row>
            <Col>Familienzuschlag (Kind)</Col>
            {Object.values(allHGR).map((hgr) => {
              return (
                hgr.beg !== undefined && (
                  <Col>
                    <NumberFormat
                      decimalScale="2"
                      fixedDecimalScale={true}
                      value={hgr.fzK}
                      displayType={"text"}
                      thousandSeparator="."
                      decimalSeparator=","
                      suffix={" €"}
                    />
                  </Col>
                )
              );
            })}
          </Row>
        )}
        {detail.grund === "5" && (
          <Row>
            <Col>Hinzurechnungsbetrag</Col>
            {Object.values(allHGR).map((hgr) => {
              return (
                hgr.beg !== undefined && (
                  <Col>
                    <NumberFormat
                      decimalScale="2"
                      fixedDecimalScale={true}
                      value={hgr.erh}
                      displayType={"text"}
                      thousandSeparator="."
                      decimalSeparator=","
                      suffix={" €"}
                    />
                  </Col>
                )
              );
            })}
          </Row>
        )}
        <Row className="border-top">
          <Col>
            <b>Höchstgrenze</b>
          </Col>
          {Object.values(allHGR).map((hgr) => {
            return (
              hgr.beg !== undefined && (
                <Col>
                  <NumberFormat
                    decimalScale="2"
                    fixedDecimalScale={true}
                    value={hgr.hgr}
                    displayType={"text"}
                    thousandSeparator="."
                    decimalSeparator=","
                    suffix={" €"}
                  />
                </Col>
              )
            );
          })}
        </Row>
      </div>
      <div className="mt-2">
        Gesamteinkünfte
        <Row>
          <Col>kirchlicher Versorgungsbezug</Col>
          {Object.values(allHGR).map((hgr) => {
            return (
              hgr.beg !== undefined && (
                <Col>
                  <NumberFormat
                    decimalScale="2"
                    fixedDecimalScale={true}
                    value={hgr.kirchV}
                    displayType={"text"}
                    thousandSeparator="."
                    decimalSeparator=","
                    suffix={" €"}
                  />
                </Col>
              )
            );
          })}
        </Row>
        <Row>
          <Col>Erwerbseinkommen</Col>
          {Object.values(allHGR).map((hgr) => {
            return (
              hgr.beg !== undefined && (
                <Col>
                  <NumberFormat
                    decimalScale="2"
                    fixedDecimalScale={true}
                    value={hgr.GesEinkommen}
                    displayType={"text"}
                    thousandSeparator="."
                    decimalSeparator=","
                    suffix={" €"}
                  />
                </Col>
              )
            );
          })}
        </Row>
        <Row>
          <Col>Arbeitnehmerpauschale (Werbungskosten)</Col>
          {Object.values(allHGR).map((hgr) => {
            return (
              hgr.beg !== undefined && (
                <Col>
                  <NumberFormat
                    decimalScale="2"
                    fixedDecimalScale={true}
                    value={83.33}
                    displayType={"text"}
                    thousandSeparator="."
                    decimalSeparator=","
                    suffix={" €"}
                  />
                </Col>
              )
            );
          })}
        </Row>
        <Row className="border-top">
          <Col>
            <b>Gesamteinkünfte</b>
          </Col>
          {Object.values(allHGR).map((hgr) => {
            return (
              hgr.beg !== undefined && (
                <Col>
                  <NumberFormat
                    decimalScale="2"
                    fixedDecimalScale={true}
                    value={hgr.gesamtV}
                    displayType={"text"}
                    thousandSeparator="."
                    decimalSeparator=","
                    suffix={" €"}
                  />
                </Col>
              )
            );
          })}
        </Row>
        <Row className="font-weight-bold">
          <Col>übersteigt die Höchstgrenze um</Col>
          {Object.values(allHGR).map((hgr) => {
            return (
              hgr.beg !== undefined && (
                <Col>
                  <NumberFormat
                    decimalScale="2"
                    fixedDecimalScale={true}
                    value={hgr.ruhe83}
                    displayType={"text"}
                    thousandSeparator="."
                    decimalSeparator=","
                    suffix={" €"}
                  />
                </Col>
              )
            );
          })}
        </Row>
      </div>
      {hgr83.mindest === true && (
        <div className="mt-2">
          Vergleichsberechnung - Mindestbelassung
          <Row>
            <Col>Versorgungsbezug nach Anwendung des Ruhensbetrag</Col>
            {Object.values(allHGR).map((hgr) => {
              return (
                hgr.beg !== undefined && (
                  <Col>
                    <NumberFormat
                      decimalScale="2"
                      fixedDecimalScale={true}
                      value={hgr.versBezugAfter83}
                      displayType={"text"}
                      thousandSeparator="."
                      decimalSeparator=","
                      suffix={" €"}
                    />
                  </Col>
                )
              );
            })}
          </Row>
          <Row>
            <Col>Mindestbelassung</Col>
            {Object.values(allHGR).map((hgr) => {
              return (
                hgr.beg !== undefined && (
                  <Col>
                    <NumberFormat
                      decimalScale="2"
                      fixedDecimalScale={true}
                      value={hgr.mind}
                      displayType={"text"}
                      thousandSeparator="."
                      decimalSeparator=","
                      suffix={" €"}
                    />
                  </Col>
                )
              );
            })}
          </Row>
          <Row>
            <Col>
              Mindestbelassung übersteigt den geregelten Versorgungsbezug
            </Col>
            {Object.values(allHGR).map((hgr) => {
              return (
                hgr.beg !== undefined && (
                  <Col>
                    {hgr.versBezugAfter83 === hgr.versBezugAfterMin && "nein"}
                  </Col>
                )
              );
            })}
          </Row>
        </div>
      )}
      <Row>
        <Col>
          <b>Ruhensbetrag</b>
        </Col>
        {Object.values(allHGR).map((hgr) => {
          return (
            hgr.beg !== undefined && (
              <Col>
                <NumberFormat
                  decimalScale="2"
                  fixedDecimalScale={true}
                  value={hgr.ruhe83AfterMin}
                  displayType={"text"}
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix={" €"}
                />
              </Col>
            )
          );
        })}
      </Row>
    </div>
  );
};

export default Hgr83;
