import {
  Document,
  Page,
  Text,
  View,
  Font,
  StyleSheet,
  PDFViewer,
  Svg,
  Line,
} from "@react-pdf/renderer";
import { useSelector } from "react-redux";
import Head from "./head2";
import Foot from "./footer2";
import moment from "moment";
import NumberFormat from "react-number-format";
import Rechtsbehelf from "./rechtsbehelf";
import rentenwert from "../helper/rentenwert.json";

// Create styles
const styles = StyleSheet.create({
  page: {
    color: "black",
    fontSize: "11pt",
    textAlign: "justify",
    //paddingLeft: "19mm",
    paddingTop: "13mm",
    paddingBottom: "15mm",
    fontFamily: "rotis",
  },
  head: {},
  section: {
    margin: 10,
    padding: 10,
  },
  viewer: {
    width: "90%", //the pdf viewer will take up all of the width and height
    height: "90vh",
  },
});
Font.register({
  family: "rotis",
  src: "./Rotis_SemiSans_55.ttf",
});
// Create Document Component
function BasicDocument(props) {
  const person = useSelector((state) => state.person.data);
  const anschreiben = useSelector((state) => state.Anschreiben.data);
  const anlagen = useSelector((state) => state.Anschreiben.anlagen);
  const detail = useSelector((state) => state.detail.data);
  const zeiten = useSelector((state) => state.Zeiten);
  const versB = useSelector((state) => state.versB.data);
  const va = useSelector((state) => state.va);

  //Rentenwert
  const ArrayWert = rentenwert.filter(
    (value) => value.beg <= detail.beg && value.end > detail.beg
  );
  const filterWert = ArrayWert[0] ? ArrayWert[0].wert : 0;

  return (
    <PDFViewer style={styles.viewer}>
      {/* Start of the document*/}
      <Document>
        {/*render a single page*/}
        <Page size="A4" style={styles.page}>
          <Head
            status={props.status}
            datum={moment(props.datum).format("DD.MM.YYYY")}
            person={person}
            anschreiben={anschreiben}
            betreff1="Vollzug des Kirchlichen Versorgungsgesetz (KVersG)"
            betreff2={`Versorgungsbezüge ab ${moment(detail.beg).format(
              "DD.MM.YYYY"
            )}`}
          />
          {/*Linie*/}
          <Svg
            height="100.3%"
            width="100%"
            style={{
              position: "absolute",
              zIndex: "10",
            }}
          >
            <Line
              style={{ zIndex: "10" }}
              x1="540"
              y1="120"
              x2="540"
              y2="850"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          {/*Hauptteil*/}
          <View
            style={{
              paddingLeft: "19mm",
              marginRight: "22mm",
            }}
          >
            <Text>
              mit Wirkung vom {moment(detail.beg).format("DD.MM.YYYY")} werden
              Sie gemäß
              {detail.grund === "1"
                ? " § 87 Abs. 1 Pfarrdienstgesetz der EKD (PfDG.EKD) "
                : detail.grund === "2"
                ? " § 88 Abs. 1 Nr. 1 Pfarrdienstgesetz der EKD (PfDG.EKD) i. V. m. § 36 Abs. 1 Pfarrdienstausführungsgesetz (PfDAG – RS 500/2) "
                : detail.grund === "3"
                ? " § 88 Abs. 1 Nr. 2 Pfarrdienstgesetz der EKD (PfDG.EKD) i. V. m. § 36 Abs. 2 Pfarrdienstausführungsgesetz (PfDAG – RS 500/2) "
                : detail.grund === "4"
                ? " § 89 Abs. 1 Pfarrdienstgesetz der EKD (PfDG.EKD) "
                : detail.grund === "5" &&
                  " § 92 Pfarrdienstgesetz der EKD (PfDG.EKD) "}
              in den Ruhestand versetzt (siehe auch Schreiben vom 11.02.2020 –
              Az.: H 0651 p1).
            </Text>
            <Text style={{ marginTop: "5.63mm" }}>
              Sie haben ab diesem Zeitpunkt Anspruch auf Versorgungsbezüge.
              Diese errechnen sich auf der Grundlage Ihrer ruhegehaltfähigen
              Bezüge und Ihrer ruhegehaltfähigen Dienstzeit (§ 9 und § 12
              KVersG). Die zu zahlende Versorgung (brutto) entnehmen Sie bitte
              der <b>Anlage „Festsetzung VB“.</b>
            </Text>
            <Text style={{ marginTop: "5.63mm" }}>
              Die beiliegende Festsetzung Ihrer Versorgungsbezüge dürfen wir
              ergänzend wie folgt erläutern:
            </Text>
            <Text style={{ marginTop: "5.63mm" }}>
              <Text>1) Familienzuschlag</Text>
            </Text>
            <Text style={{ marginTop: "5.63mm", marginLeft: "13px" }}>
              Der <b>Familienzuschlag</b> wurde anhand der Aktenlage
              festgesetzt. Um den Familienzuschlag abschließend festsetzen zu
              können, benötigen wir weitere Informationen. Wir wären Ihnen
              dankbar, wenn Sie uns die hierzu beigefügten Formblätter möglichst
              bald ausgefüllt zukommen ließen.
            </Text>
            <Text
              style={{
                marginTop: "5.63mm",
                fontSize: "9pt",
                textDecoration: "underline",
                marginLeft: "13px",
              }}
            >
              Bitte beachten Sie ferner:
            </Text>
            <Text style={{ marginLeft: "13px", fontSize: "9pt" }}>
              Im Blick auf den Familienzuschlag sind die ruhegehaltfähigen
              Dienst-bzw. Versorgungsbezüge immer dann neu zu berechnen, wenn
              eine Änderung des Familienstandes eintritt, dem Ehegatten
              ebenfalls ein Familienzuschlag der Stufe 1 oder eine entsprechende
              Leistung zusteht, oder wenn Kindergeld beansprucht werden kann
              bzw. sich die Anspruchsvoraussetzungen für die Gewährung des
              Kindergeldes (z.B. Ende der Ausbildung, eigenes Einkommen) ändern.
              Aus diesem Grunde weisen wir vorsorglich nochmals auf Ihre
              Anzeigepflicht nach § 2 Abs. 1 KVersG i.V.m. Art. 10 BayBeamtVG
              besonders hin.
            </Text>
          </View>
          {/*Footer*/}
          <Foot />
        </Page>
        <Page size="A4" style={styles.page}>
          <View style={{ paddingLeft: "19mm", marginRight: "1.5cm" }}>
            {/*Kopfzeile*/}
            <Text
              style={{
                paddingBottom: "5mm",
                textAlign: "right",
                fontSize: "9pt",
              }}
              render={({ pageNumber, totalPages }) =>
                `Seite ${pageNumber} des Schreibens vom ${moment(
                  props.datum
                ).format("DD.MM.YYYY")}, Az. ${anschreiben.az}`
              }
              fixed
            />
            {/*Ruhegehaltfähige Dienstzeit / Bezüge*/}
            <Text style={{ marginTop: "5.63mm" }}>
              2) Ruhegehaltfähige Dienstzeit / Bezüge
            </Text>
            <Text style={{ marginTop: "5.63mm", marginLeft: "13px" }}>
              Ihre ruhegehaltfähigen Bezüge werden aufgrund Ihrer
              ruhegehaltfähigen Dienstzeit zum{" "}
              {moment(detail.beg).format("DD.MM.YYYY")} mit einem
              Ruhegehaltssatz von{" "}
              {
                <NumberFormat
                  decimalScale="2"
                  fixedDecimalScale={true}
                  value={zeiten.gesamtzeit.gesamtZ[4]}
                  displayType={"text"}
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix={" %"}
                />
              }{" "}
              gewährt (siehe Abschn. II.2 der Festsetzung).
              {/*Abschlag*/}
              {versB.abschlPro > 0 &&
                "Beginnt der Ruhestand vor Ablauf des Monats,in dem die Regelaltersgrenze erreicht wird, so wird das Ruhegehalt auf Dauer um einen Abschlag von 3,6 v.H. pro Jahr vermindert, höchstens jedoch um"}
              {detail.grund === "2"
                ? " 14,4 v. H. (§ 15 Abs. 2 Nr. 1 KVersG)."
                : detail.grund === "3"
                ? " 10,8 v. H. (§ 15 Abs. 2 Nr. 2 KVersG)."
                : detail.grund === "4"
                ? " 10,8 v. H. (§ 15 Abs. 2 Nr. 3 KVersG)."
                : detail.grund === "5"
                ? " 14,4 v. H. (§ 15 Abs. 2 Nr. 4 KVersG)."
                : detail.grund === "6" &&
                  " 14,4 v. H. (§ 15 Abs. 2 Nr. 5 KVersG)."}
            </Text>
            {/*Versorgungsausgleich*/}
            {va.va1.eheEnde !== "" && (
              <View>
                <Text style={{ marginTop: "5.63mm" }}>
                  3) Versorgungsausgleich
                </Text>
                <Text style={{ marginTop: "5.63mm", marginLeft: "13px" }}>
                  Die Versorgungsbezüge sind nach der Ehescheidung zwingend zu
                  kürzen, wenn Anwartschaften in einer gesetzlichen
                  Rentenversicherung durch Entscheidung des Familiengerichtes
                  begründet worden sind (Kürzung nach § 39 KVersG i. V. m. Art.
                  92 BayBeamtVG).
                </Text>
                <Text style={{ marginTop: "5.63mm", marginLeft: "13px" }}>
                  Zu Lasten Ihrer Versorgung als {detail.status} in der
                  Evang.-Luth. Kirche in Bayern wurden bezogen auf den{" "}
                  {moment(va.va1.eheEnde).format("DD.MM.YYYY")}, d. h. dem Ende
                  der Ehezeit, Rentenanwartschaften in Höhe von monatlich{" "}
                  <NumberFormat
                    decimalScale="2"
                    fixedDecimalScale={true}
                    value={va.va1.betrag}
                    displayType={"text"}
                    thousandSeparator="."
                    decimalSeparator=","
                    suffix={" " + va.va1.waehrung}
                  />{" "}
                  auf dem Versicherungskonto Ihres geschiedenen Ehepartners
                  begründet. Gemäß Art. 92 BayBeamtVG ist dafür Ihr Ruhegehalt
                  entsprechend zu kürzen. Ausgehend von dem festgelegten Betrag,
                  der um die seither erfolgten Besoldungsanpassungen zu erhöhen
                  ist, errechnet sich der Kürzungsbetrag von{" "}
                  <NumberFormat
                    allowNegative={false}
                    decimalScale="2"
                    fixedDecimalScale={true}
                    value={va.va1.kuerzung}
                    displayType={"text"}
                    thousandSeparator="."
                    decimalSeparator=","
                    suffix={" €"}
                  />
                  .
                </Text>
                <Text style={{ marginTop: "5.63mm", marginLeft: "13px" }}>
                  Die Anrechnungspflicht betrifft auch Ansprüche, die wegen
                  Versorgungsausgleich auf Ihren geschiedenen Ehepartner
                  übertragen wurden (Rentenanwartschaften von monatlich{" "}
                  <NumberFormat
                    allowNegative={false}
                    decimalScale="2"
                    fixedDecimalScale={true}
                    value={va.va1.drv}
                    displayType={"text"}
                    thousandSeparator="."
                    decimalSeparator=","
                    suffix={" " + va.va1.waehrung}
                  />{" "}
                  bezogen auf den {moment(va.va1.eheEnde).format("DD.MM.YYYY")}{" "}
                  => das entspricht{" "}
                  <NumberFormat
                    allowNegative={false}
                    decimalScale="4"
                    fixedDecimalScale={true}
                    value={va.va1.entgeltPunkte}
                    displayType={"text"}
                    decimalSeparator=","
                    thousandSeparator="."
                  />{" "}
                  Entgeltpunkten und einem aktuellen Rentenwert in Höhe von{" "}
                  <NumberFormat
                    allowNegative={false}
                    decimalScale="2"
                    fixedDecimalScale={true}
                    value={filterWert}
                    displayType={"text"}
                    thousandSeparator="."
                    decimalSeparator=","
                    suffix={" €"}
                  />
                  ). Für Ihre Rente bedeutet dies, dass die anrechenbare Rente
                  voraussichtlich um{" "}
                  <NumberFormat
                    allowNegative={false}
                    decimalScale="2"
                    fixedDecimalScale={true}
                    value={va.va1.entgeltPunkte * filterWert}
                    displayType={"text"}
                    thousandSeparator="."
                    decimalSeparator=","
                    suffix={" €"}
                  />{" "}
                  höher ist, als der Zahlbetrag, den Sie von der drv-bund
                  erhalten.
                </Text>
              </View>
            )}
            {/*Versorgungsausgleich-2*/}
            {va.va2 && va.va2.eheEnde !== "" && (
              <View>
                <Text style={{ marginTop: "5.63mm", marginLeft: "13px" }}>
                  Zu Lasten Ihrer Versorgung als {detail.status} in der
                  Evang.-Luth. Kirche in Bayern wurden bezogen auf den{" "}
                  {moment(va.va2.eheEnde).format("DD.MM.YYYY")}, d. h. dem Ende
                  der Ehezeit, Rentenanwartschaften in Höhe von monatlich{" "}
                  <NumberFormat
                    decimalScale="2"
                    fixedDecimalScale={true}
                    value={va.va2.betrag}
                    displayType={"text"}
                    thousandSeparator="."
                    decimalSeparator=","
                    suffix={" " + va.va2.waehrung}
                  />{" "}
                  auf dem Versicherungskonto Ihres geschiedenen Ehepartners
                  begründet. Gemäß Art. 92 BayBeamtVG ist dafür Ihr Ruhegehalt
                  entsprechend zu kürzen. Ausgehend von dem festgelegten Betrag,
                  der um die seither erfolgten Besoldungsanpassungen zu erhöhen
                  ist, errechnet sich der Kürzungsbetrag von{" "}
                  <NumberFormat
                    allowNegative={false}
                    decimalScale="2"
                    fixedDecimalScale={true}
                    value={va.va2.kuerzung}
                    displayType={"text"}
                    thousandSeparator="."
                    decimalSeparator=","
                    suffix={" €"}
                  />
                  .
                </Text>
                <Text style={{ marginTop: "5.63mm", marginLeft: "13px" }}>
                  Die Anrechnungspflicht betrifft auch Ansprüche, die wegen
                  Versorgungsausgleich auf Ihren geschiedenen Ehepartner
                  übertragen wurden (Rentenanwartschaften von monatlich{" "}
                  <NumberFormat
                    allowNegative={false}
                    decimalScale="2"
                    fixedDecimalScale={true}
                    value={va.va2.drv}
                    displayType={"text"}
                    thousandSeparator="."
                    decimalSeparator=","
                    suffix={" " + va.va2.waehrung}
                  />{" "}
                  bezogen auf den {moment(va.va2.eheEnde).format("DD.MM.YYYY")}{" "}
                  => das entspricht{" "}
                  <NumberFormat
                    allowNegative={false}
                    decimalScale="4"
                    fixedDecimalScale={true}
                    value={va.va2.entgeltPunkte}
                    displayType={"text"}
                    decimalSeparator=","
                    thousandSeparator="."
                  />{" "}
                  Entgeltpunkten und einem aktuellen Rentenwert in Höhe von{" "}
                  <NumberFormat
                    allowNegative={false}
                    decimalScale="2"
                    fixedDecimalScale={true}
                    value={filterWert}
                    displayType={"text"}
                    thousandSeparator="."
                    decimalSeparator=","
                    suffix={" €"}
                  />
                  ). Für Ihre Rente bedeutet dies, dass die anrechenbare Rente
                  voraussichtlich um{" "}
                  <NumberFormat
                    allowNegative={false}
                    decimalScale="2"
                    fixedDecimalScale={true}
                    value={va.va2.entgeltPunkte * filterWert}
                    displayType={"text"}
                    thousandSeparator="."
                    decimalSeparator=","
                    suffix={" €"}
                  />{" "}
                  höher ist, als der Zahlbetrag, den Sie von der drv-bund
                  erhalten.
                </Text>
              </View>
            )}
            {/*Versorgungsausgleich-3*/}
            {va.va3 && va.va3.eheEnde !== "" && (
              <View>
                <Text style={{ marginTop: "5.63mm", marginLeft: "13px" }}>
                  Zu Lasten Ihrer Versorgung als {detail.status} in der
                  Evang.-Luth. Kirche in Bayern wurden bezogen auf den{" "}
                  {moment(va.va3.eheEnde).format("DD.MM.YYYY")}, d. h. dem Ende
                  der Ehezeit, Rentenanwartschaften in Höhe von monatlich{" "}
                  <NumberFormat
                    decimalScale="2"
                    fixedDecimalScale={true}
                    value={va.va3.betrag}
                    displayType={"text"}
                    thousandSeparator="."
                    decimalSeparator=","
                    suffix={" " + va.va3.waehrung}
                  />{" "}
                  auf dem Versicherungskonto Ihres geschiedenen Ehepartners
                  begründet. Gemäß Art. 92 BayBeamtVG ist dafür Ihr Ruhegehalt
                  entsprechend zu kürzen. Ausgehend von dem festgelegten Betrag,
                  der um die seither erfolgten Besoldungsanpassungen zu erhöhen
                  ist, errechnet sich der Kürzungsbetrag von{" "}
                  <NumberFormat
                    allowNegative={false}
                    decimalScale="2"
                    fixedDecimalScale={true}
                    value={va.va3.kuerzung}
                    displayType={"text"}
                    thousandSeparator="."
                    decimalSeparator=","
                    suffix={" €"}
                  />
                  .
                </Text>
                <Text style={{ marginTop: "5.63mm", marginLeft: "13px" }}>
                  Die Anrechnungspflicht betrifft auch Ansprüche, die wegen
                  Versorgungsausgleich auf Ihren geschiedenen Ehepartner
                  übertragen wurden (Rentenanwartschaften von monatlich{" "}
                  <NumberFormat
                    allowNegative={false}
                    decimalScale="2"
                    fixedDecimalScale={true}
                    value={va.va3.drv}
                    displayType={"text"}
                    thousandSeparator="."
                    decimalSeparator=","
                    suffix={" " + va.va3.waehrung}
                  />{" "}
                  bezogen auf den {moment(va.va3.eheEnde).format("DD.MM.YYYY")}{" "}
                  => das entspricht{" "}
                  <NumberFormat
                    allowNegative={false}
                    decimalScale="4"
                    fixedDecimalScale={true}
                    value={va.va3.entgeltPunkte}
                    displayType={"text"}
                    decimalSeparator=","
                    thousandSeparator="."
                  />{" "}
                  Entgeltpunkten und einem aktuellen Rentenwert in Höhe von{" "}
                  <NumberFormat
                    allowNegative={false}
                    decimalScale="2"
                    fixedDecimalScale={true}
                    value={filterWert}
                    displayType={"text"}
                    thousandSeparator="."
                    decimalSeparator=","
                    suffix={" €"}
                  />
                  ). Für Ihre Rente bedeutet dies, dass die anrechenbare Rente
                  voraussichtlich um{" "}
                  <NumberFormat
                    allowNegative={false}
                    decimalScale="2"
                    fixedDecimalScale={true}
                    value={va.va3.entgeltPunkte * filterWert}
                    displayType={"text"}
                    thousandSeparator="."
                    decimalSeparator=","
                    suffix={" €"}
                  />{" "}
                  höher ist, als der Zahlbetrag, den Sie von der drv-bund
                  erhalten.
                </Text>
              </View>
            )}
            {/*Steuervorteilsausgleich*/}
            <Text style={{ marginTop: "5.63mm" }}>
              {detail.va === false ? "3" : "4"}) Steuervorteilsausgleich
            </Text>
            <Text style={{ marginTop: "5.63mm", marginLeft: "13px" }}>
              Zum Ausgleich dafür, dass Sie während Ihrer Dienstzeit den sog.
              Steuerabgeltungszuschlag erhalten haben, wird bei den
              Versorgungsbezügen der sich infolge der Versteuerung von Renten
              ergebende Steuervorteil einbehalten (
              <b>Steuervorteilsausgleich</b>
              ). Der Einbehalt wird in Höhe des Höchst- bzw.
              Regelausgleichssatzes festgesetzt, der sich bei{" "}
              <b>BesGr. {detail.besO + " " + detail.besG}</b> und{" "}
              <b>Steuerklasse {person.stkl}</b> seit dem 01.01.2005 auf{" "}
              <b>{versB.stva} v. H.</b> beläuft (Verordnung über den
              Steuervorteilsausgleich infolge Versorgungsneuregelung
              [Steuervorteilsausgleichs-verordnung – StAusglV], RS 761- siehe
              hierzu auch Anlage zu § 2 der Verordnung).
            </Text>
            <Text style={{ marginTop: "5.63mm", marginLeft: "13px" }}>
              Gerne prüfen wir, ob in Ihrem Falle der Ausgleichssatz ermäßigt
              werden kann.
              <br />
              <br />
              Sofern Sie dies wünschen, legen Sie uns bitte zu gegebener Zeit
              Ihren Einkommensteuerbescheid für das Jahr{" "}
              <b>
                {moment(detail.beg).format("MM") === "11" ||
                moment(detail.beg).format("MM") === "12"
                  ? moment(detail.beg).add(1, "y").format("YYYY")
                  : moment(detail.beg).format("YYYY")}
              </b>{" "}
              vor. Wir möchten Sie in diesem Zusammenhang aber ausdrücklich
              darauf aufmerksam machen, dass Sie zur Vorlage des
              Einkommensteuerbescheides nicht verpflichtet sind. In diesem Fall
              bleibt es aber bei dem Regelausgleichsatz, es sei denn, dass
              nachgewiesenermaßen ein Steuerbescheid nicht beigebracht werden
              kann. Näheres dazu entnehmen Sie bitte dem beiliegenden Merkblatt
              zum Steuervorteilsausgleich.
            </Text>
            {/*Rentenanrechnung*/}
            <Text style={{ marginTop: "5.63mm" }}>
              {detail.va === false ? "4" : "5"}) Rentenanrechnung
            </Text>
            <Text style={{ marginTop: "5.63mm", marginLeft: "13px" }}>
              Auf die Bruttoversorgungsbezüge werden Leistungen aus der
              gesetzlichen Rentenversicherung angerechnet, da Ihr Dienstherr
              hierfür die gesamten Beitragsleistungen erbracht hat (§ 34 Abs. 1
              KVersG). Ebenso sind gegebenenfalls weitere Rententeile nach § 37
              KVersG anrechenbar.
            </Text>
            <Text style={{ marginTop: "5.63mm", marginLeft: "13px" }}>
              Um die Beträge ordnungsgemäß anrechnen zu können, benötigen wir
              den Bescheid der drv-bund über die Festsetzung Ihrer Rente. Wir
              bitten Sie deshalb, uns Ihren Rentenbescheid einschließlich aller
              Anlagen unverzüglich nach Erhalt zu übersenden.
            </Text>
            <Text style={{ marginTop: "5.63mm", marginLeft: "13px" }}>
              Die landeskirchlichen Versorgungsbezüge in der vorstehend
              genannten Höhe erhalten Sie unter dem
              <b>Vorbehalt der Rückforderung als Vorschuss</b> und zwar solange,
              bis die Berechnungen über die anzurechnenden Renten abgeschlossen
              sind. Vorsorglich machen wir Sie darauf aufmerksam, dass wir evtl.
              zu viel ausbezahlte Versorgungsbezüge von Ihnen zurückfordern
              werden, wenn die (noch zu ermittelnden) Anrechnungsbeträge die
              Summe übersteigt, die uns die drv-bund erstattet. Dieser Hinweis
              über eine mögliche Rückforderung gilt auch dann, wenn die drv-bund
              Rentenbeträge oder Teilbeträge unmittelbar an Sie überweist. Für
              diesen Fall empfiehlt es sich, die vereinnahmten Beträge vorerst
              nicht im Rahmen der normalen Lebensführung zu verausgaben. Wir
              werden uns bemühen, Sie über die anrechenbaren Beträge so bald wie
              möglich schriftlich zu informieren, damit Sie möglichst frühzeitig
              finanzielle Planungssicherheit erlangen.
            </Text>
            <Text style={{ marginTop: "5.63mm", marginLeft: "13px" }}>
              Im Übrigen erlauben wir uns darauf hinzuweisen, dass Renten der
              gesetzlichen Rentenversicherungsträger zu den Leibrenten i. S. von
              § 22 Nr. 1 Satz 3 Buchst. a EStG gehören und deshalb grundsätzlich
              der Einkommensteuerpflicht unterliegen und in der entsprechenden
              Einkommensteuererklärung anzugeben sind.
            </Text>
            <Text style={{ marginTop: "5.63mm", marginLeft: "13px" }}>
              Wegen der Rentenantragstellung ist Frau Bianca Behrens aus unserem
              Hause bereits auf Sie zugekommen.
            </Text>
            {/*KV-Beihilfe*/}
            <Text style={{ marginTop: "5.63mm" }}>
              {detail.va === false ? "5" : "6"}) Beihilfe / Krankenversicherung
            </Text>
            <Text style={{ marginTop: "5.63mm", marginLeft: "13px" }}>
              Sie erhalten von der Deutschen Rentenversicherung neben der Rente
              auch einen Zuschuss zu Ihren Kran- kenversicherungsbeiträgen.
              Sobald ein Anspruch auf Zuschuss von mindestens 76,00 € besteht,
              vermin- dert sich Ihr Beihilfebemessungssatz um 20 v. H. Ein
              Verzicht oder Teilverzicht hat trotzdem die Kürzung des
              Bemessungssatzes zur Folge. Im Gegensatz zu staatlichen Beamten
              beträgt deshalb Ihr Beihilfebemes- sungssatz in der Regel 50 v. H.
            </Text>
            <Text style={{ marginTop: "5.63mm", marginLeft: "13px" }}>
              Bei weiteren Fragen zur Beihilfe wenden Sie sich bitte an Ihre
              zuständige Beihilfestelle der Evangelisch- Lutherische Kirche in
              Bayern (zentrale Tel.-Nr. (089) 55 95–300, E-Mail:
              beihilfe@elkb.de).
            </Text>
            <Text style={{ marginTop: "5.63mm" }}>
              Auf Ihre Anzeigepflicht (§ 2 Abs. 1 KVersG i. V. m. Art. 10
              BayBeamtVG) weisen wir Sie besonders hin (siehe anliegendes
              Merkblatt).
            </Text>
            <Text style={{ marginTop: "5.63mm" }}>
              Im Übrigen dürfen wir Sie bitten, die beiliegende
              Empfangsbestätigung auszufüllen, zu unterzeichnen und an uns
              zurückzuschicken.
            </Text>
            <Text style={{ marginTop: "5.63mm" }}>
              Für weitere Auskünfte stehen wir Ihnen gerne jederzeit – auch
              telefonisch – zur Verfügung.
            </Text>
            {/*Rechtsbehelf*/}
            <Rechtsbehelf gericht={anschreiben.gericht} />
            <View wrap={false}>
              <Text style={{ marginTop: "11.26mm" }}>
                Mit freundlichen Grüßen
              </Text>
              <Text>Im Auftrag</Text>
              <Text style={{ marginTop: "8.45mm" }}>SB-P</Text>
            </View>
            {/*Anlagen*/}
            <Text style={{ marginTop: "11.26mm", textDecoration: "underline" }}>
              Anlagen:
            </Text>
            <View style={{ flexDirection: "row" }}>
              <Text style={{ marginRight: "25px" }}>Anlage VB</Text>
              <Text>Festsetzung Versorgungsbezüge</Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text style={{ marginRight: "30.5px" }}>Anlage A</Text>
              <Text>Ermittlung des Ruhegehaltssatzes nach § 12 KVersG</Text>
            </View>
            {anlagen.anlageB === true && (
              <View style={{ flexDirection: "row" }}>
                <Text style={{ marginRight: "30.5px" }}>Anlage B</Text>
                <Text>
                  Ermittlung des Ruhegehaltssatzes nach § 43 Abs. 3 - 5 KVersG
                </Text>
              </View>
            )}
            {anlagen.anlageVA === true && (
              <View style={{ flexDirection: "row" }}>
                <Text style={{ marginRight: "30.5px" }}>Anlage D</Text>
                <Text>Kürzung Versorgungsausgleich</Text>
              </View>
            )}
            <Text style={{ marginTop: "2mm", marginBottom: "2mm" }}>
              Erklärung zum Familienzuschlag
            </Text>
            <Text style={{ marginBottom: "2mm" }}>Empfangsbestätigung</Text>
            <View style={{ flexDirection: "row" }}>
              <Text style={{ marginRight: "17px" }}>Merkblätter:</Text>
              <Text>Anzeigepflichten (M 1)</Text>
            </View>
            <Text style={{ marginLeft: "71px" }}>Steuervorteilsausgleich</Text>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
}
export default BasicDocument;
