import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Col, Row, Table } from "react-bootstrap";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router-dom";
import "./printing.css";

const Stabgz = () => {
  const [loading, setLoading] = useState();
  const [data, setData] = useState();
  const [year, setYear] = useState(2022);
  const [group, setGroup] = useState("a");

  const navigate = useHistory();

  const getData = async () => {
    try {
      setLoading(true);
      const data = await fetch(
        //`http://localhost/versorgung/rest-api/functions_stabgz.php?year=${year}&group=${group}`,
        `https://versorgung.hosting142616.a2e76.netcup.net/rest-api/functions_stabgz.php?year=${year}&group=${group}`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
          },
        }
      );
      if (data) {
        const fetch = await data.json();
        setData(fetch);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [year, group]);

  return (
    <div>
      <Row className="d-print-none">
        <Col>
          <ButtonGroup>
            <Button variant="outline-primary" onClick={() => setYear(2022)}>
              2022
            </Button>
            <Button variant="outline-primary" onClick={() => setYear(2021)}>
              2021
            </Button>
            <Button variant="outline-primary" onClick={() => setYear(2020)}>
              2020
            </Button>
          </ButtonGroup>
        </Col>
        <Col className="text-end">
          <Button
            onClick={() => navigate.push("/stabgz")}
            variant="outline-primary"
          >
            Übersichtslisten
          </Button>
        </Col>
      </Row>
      <div className="d-print-none mt-2">
        <ButtonGroup>
          <Button variant="outline-primary" onClick={() => setGroup("a")}>
            Gruppe A
          </Button>
          <Button variant="outline-primary" onClick={() => setGroup("b")}>
            Gruppe B
          </Button>
          <Button variant="outline-primary" onClick={() => setGroup("c")}>
            Gruppe C
          </Button>
        </ButtonGroup>
      </div>
      <div className="mb-3">
        <b>Evangelisch-Lutherische Kirche in Bayern KdöR</b>
      </div>
      <Row className="small printing">
        <Col xs="5">
          <div>Steuerabgeltungszuschlag {year}</div>
          <div>Erstattungsgruppe {group.toUpperCase()}</div>
          {group === "a" && (
            <div>
              <br />
              <div>ledige (Steuerklasse 1)</div>
              <div>
                verheiratete (Steuerklasse 3), wenn der Ehepartner {">"} 60%
                verdient
              </div>
            </div>
          )}
          {group === "b" && (
            <div>
              <br />
              <div>verheiratete (Steuerklasse 3) - für Berechnung</div>
              <div>verheiratete (Steuerklasse 4,5,6)</div>
            </div>
          )}
          {group === "c" && (
            <div>
              <div>- wenn der Ehegatte zwischen 30% und 60% verdient</div>
              <div>verheiratete (Steuerklasse 3) - für Berechnung</div>
              <div>verheiratete (Steuerklasse 4,5,6)</div>
            </div>
          )}
        </Col>
        <Col>
          <div>Höchstbetrag pro Stufe gilt als Berechnungsgrundlage = 100%</div>
          <div>
            höchstens die Beitragsbemessungsgrenze von{" "}
            {year === 2022 && "7.050,00 "}
            {year === 2021 && "7.100,00 "}
            {year === 2020 && "6.900,00 "}€ (VJ. {year === 2022 && "7.100,00 "}
            {year === 2021 && "6.900,00 "}
            {year === 2020 && "6.700,00 "}
            €)
          </div>
          <div>abzüglich des darauf entfallenden AN-Anteils RV = 9,30 %</div>
          <div>
            In der Gleitzone (erste Variante) wurde der tatsächliche RV-Beitrag
            abgezogen,
          </div>
          <div>die Differenz ist das Gehalt des Staatsbeamten.</div>
        </Col>
      </Row>
      <Table borderless className="printing g-0 small m-0">
        <thead
          style={{
            borderTop: "solid 1px black",
            borderBottom: "solid 1px black",
          }}
        >
          <tr>
            <td className="pb-0">lfd. Nr.</td>
            <td className="pb-0" colSpan={2}>
              Gehaltsgruppe
            </td>
            <td className="pb-0">
              Stpfl. Gehalt
              <br />
              inkl. RV-Beitrag
            </td>
            <td className="pb-0">
              Bemessungs-
              <br />
              grundlage
            </td>
            <td className="pb-0">RV-Beitrag 9,30 %</td>
            <td className="pb-0">
              Stpfl. Gehalt
              <br />
              (abzgl. 9,30%)
            </td>
            <td className="pb-0">Netto</td>
            <td className="pb-0">Steuerbel.</td>
            <td className="pb-0">Brutto (Hochrg.)</td>
            <td className="pb-0">Steuerbel.</td>
            <td className="pb-0">RV endgültig</td>
            <td className="pb-0">
              Steuermehr-
              <br />
              belastung
            </td>
            <td className="pb-0">% zu RV</td>
            <td className="pb-0">% rd.</td>
          </tr>
          <tr>
            <td className="pt-0"></td>
            <td className="pt-0">von</td>
            <td className="pt-0">bis</td>
            <td className="pt-0">= Kirche vorl.</td>
            <td className="pt-0">
              RV-Beitrag
              <br />
              {"(<= 7050,00)"}
            </td>
            <td className="pt-0">bzw. Gleitzone</td>
            <td className="pt-0">= Staat</td>
            <td className="pt-0">
              = Staat <br />= Kirche
            </td>
            <td className="pt-0">= Staat</td>
            <td className="pt-0">= Kirche</td>
            <td className="pt-0">= Kirche</td>
            <td className="pt-0">= Kirche</td>
            <td className="pt-0"></td>
            <td className="pt-0"></td>
            <td className="pt-0"></td>
          </tr>
        </thead>
        <tbody>
          {loading === false &&
            Object.values(data).map((row, id) => {
              return (
                <tr className="text-right" key={id}>
                  <td className="pt-0 pb-0">{id}</td>
                  <td className="pt-0 pb-0">
                    {
                      <NumberFormat
                        displayType="text"
                        decimalSeparator=","
                        thousandSeparator="."
                        suffix=" €"
                        value={row[0]}
                      />
                    }
                  </td>
                  <td className="pt-0 pb-0">
                    {
                      <NumberFormat
                        displayType="text"
                        decimalSeparator=","
                        thousandSeparator="."
                        suffix=" €"
                        fixedDecimalScale
                        decimalScale={2}
                        value={row[1]}
                      />
                    }
                  </td>
                  <td className="pt-0 pb-0">
                    {
                      <NumberFormat
                        displayType="text"
                        decimalSeparator=","
                        thousandSeparator="."
                        suffix=" €"
                        fixedDecimalScale
                        decimalScale={2}
                        value={row[2]}
                      />
                    }
                  </td>
                  <td className="pt-0 pb-0">
                    {
                      <NumberFormat
                        displayType="text"
                        decimalSeparator=","
                        thousandSeparator="."
                        suffix=" €"
                        fixedDecimalScale
                        decimalScale={2}
                        value={row[3]}
                      />
                    }
                  </td>
                  <td className="pt-0 pb-0">
                    {
                      <NumberFormat
                        displayType="text"
                        decimalSeparator=","
                        thousandSeparator="."
                        suffix=" €"
                        fixedDecimalScale
                        decimalScale={2}
                        value={row[4]}
                      />
                    }
                  </td>
                  <td className="pt-0 pb-0">
                    {
                      <NumberFormat
                        displayType="text"
                        decimalSeparator=","
                        thousandSeparator="."
                        suffix=" €"
                        fixedDecimalScale
                        decimalScale={2}
                        value={row[5]}
                      />
                    }
                  </td>
                  <td className="pt-0 pb-0">
                    {
                      <NumberFormat
                        displayType="text"
                        decimalSeparator=","
                        thousandSeparator="."
                        suffix=" €"
                        fixedDecimalScale
                        decimalScale={2}
                        value={row[6]}
                      />
                    }
                  </td>
                  <td className="pt-0 pb-0">
                    {
                      <NumberFormat
                        displayType="text"
                        decimalSeparator=","
                        thousandSeparator="."
                        suffix=" €"
                        fixedDecimalScale
                        decimalScale={2}
                        value={row[7]}
                      />
                    }
                  </td>
                  <td className="pt-0 pb-0">
                    {
                      <NumberFormat
                        displayType="text"
                        decimalSeparator=","
                        thousandSeparator="."
                        suffix=" €"
                        fixedDecimalScale
                        decimalScale={2}
                        value={row[8]}
                      />
                    }
                  </td>
                  <td className="pt-0 pb-0">
                    {
                      <NumberFormat
                        displayType="text"
                        decimalSeparator=","
                        thousandSeparator="."
                        suffix=" €"
                        fixedDecimalScale
                        decimalScale={2}
                        value={row[9]}
                      />
                    }
                  </td>
                  <td className="pt-0 pb-0">
                    {
                      <NumberFormat
                        displayType="text"
                        decimalSeparator=","
                        thousandSeparator="."
                        suffix=" €"
                        fixedDecimalScale
                        decimalScale={2}
                        value={row[10]}
                      />
                    }
                  </td>
                  <td className="pt-0 pb-0">
                    {
                      <NumberFormat
                        displayType="text"
                        decimalSeparator=","
                        thousandSeparator="."
                        suffix=" €"
                        fixedDecimalScale
                        decimalScale={2}
                        value={row[11]}
                      />
                    }
                  </td>
                  <td className="pt-0 pb-0">
                    {
                      <NumberFormat
                        displayType="text"
                        decimalSeparator=","
                        thousandSeparator="."
                        suffix=" %"
                        fixedDecimalScale
                        decimalScale={2}
                        value={row[12]}
                      />
                    }
                  </td>
                  <td className="pt-0 pb-0">
                    {
                      <NumberFormat
                        displayType="text"
                        decimalSeparator=","
                        thousandSeparator="."
                        suffix=" %"
                        fixedDecimalScale
                        decimalScale={0}
                        value={row[13]}
                      />
                    }
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
};

export default Stabgz;
