import React, { useEffect, useState } from "react";
import {
  TreeList,
  Editing,
  Column,
  RequiredRule,
  Lookup,
  Button,
  Sorting,
} from "devextreme-react/tree-list";
import "devextreme/dist/css/dx.light.css";
//https://js.devexpress.com/Demos/WidgetsGallery/Demo/TreeList/RowEditing/React/Light/
//https://js.devexpress.com/Documentation/ApiReference/UI_Components/dxTreeList/Configuration/#onRowRemoved
const MindMap = () => {
  const [loading, setLoading] = useState();
  const [data, setData] = useState();

  const headDataSource = {
    store: data,
    sort: "headline",
  };
  const onEditorPreparing = async (e) => {
    if (e.changes[0]) {
      const request = await fetch(
        //`http://localhost/versorgung/rest-api/other.php?map=auskunft&type=post`,
        `https://versorgung.hosting142616.a2e76.netcup.net/rest-api/other.php?map=auskunft&type=post`,
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
          },
          body: JSON.stringify({
            data: data.filter((data) => {
              if (data.id === e.changes[0].key) {
                return data;
              }
            }),
          }),
        }
      );
    }
  };

  const onInitNewRow = (e) => {
    e.data.head_id = 0;
    e.data.last_edit = new Date();
  };

  const loadMindMap = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        //`http://localhost/versorgung/rest-api/other.php?map=auskunft&type=get`,
        `https://versorgung.hosting142616.a2e76.netcup.net/rest-api/other.php?map=auskunft&type=get`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
          },
        }
      );
      const data = await response.json();
      if (data) {
        setData(data);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadMindMap();
  }, []);

  return (
    <div>
      {loading === false && (
        <TreeList
          id="data"
          dataSource={data}
          showRowLines={true}
          showBorders={true}
          columnAutoWidth={true}
          keyExpr="id"
          parentIdExpr="head_id"
          //onEditorPreparing={onEditorPreparing}
          onInitNewRow={onInitNewRow}
          rowAlternationEnabled={true}
          onSaved={onEditorPreparing}
        >
          <Sorting mode="none" />
          <Editing
            allowUpdating={true}
            allowDeleting={true}
            allowAdding={true}
            mode="row"
          />
          <Column caption="Auskunft" dataField="headline">
            <RequiredRule />
          </Column>
          <Column dataField="grd" caption="Rechtsgrundlage"></Column>
          <Column dataField="unterlagen" caption="weitere Unterlagen"></Column>
          <Column dataField="head_id" caption="Untergeordnet">
            <Lookup
              dataSource={headDataSource}
              valueExpr="id"
              displayExpr="headline"
            />
            <RequiredRule />
          </Column>
          <Column
            allowEditing={false}
            dataField="last_edit"
            caption="bearbeitet"
            dataType="date"
            format={"dd.MM.yyyy"}
          ></Column>
          <Column type="buttons">
            <Button name="edit" />
            <Button name="delete" />
          </Column>
        </TreeList>
      )}
    </div>
  );
};

export default MindMap;
