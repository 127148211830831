import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  Select,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { Col, Row, Table } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import va_pf from "./helper/va_pf.json";
import va_beamt from "./helper/va_beamt.json";
import { kuerzVA, indiVA, DmToEuro } from "./helper/functions";

import {
  besA,
  besB,
  besC,
  besP,
  besW,
  stufenA,
  stufenB,
  stufenC,
  stufenW,
  stufenP,
} from "./helper/besoldung2009";
import {
  besA1990,
  besB1990,
  besP1990,
  stufenA1990,
  stufenB1990,
  stufenP1990,
} from "./helper/besoldung1990";
import NumberFormat from "react-number-format";

import deLocale from "date-fns/locale/de";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import rentenwert from "./helper/rentenwert.json";

const VA = () => {
  const dispatch = useDispatch();
  const [selectedVA, setSelectedVA] = useState("va1");
  const va = useSelector((state) => state.va[selectedVA]);
  const [eheEnde, setEheEnde] = useState(va.eheEnde);
  const count = useSelector((state) => state.va.count);
  const beruf = useSelector((state) => state.detail.data.status);
  const view = useSelector((state) => state.Vorgang.view);

  useEffect(() => {
    setEheEnde(va.eheEnde);
  }, [selectedVA]);

  const handleVAChange = (e) => {
    if (e.name === "kuerzung") {
      dispatch({
        type: "SET_VA",
        va: selectedVA,
        payload: {
          ...va,
          kuerzung: -1 * e.betrag,
        },
      });
    } else if (e.name === "eheEnde") {
      //Rentenwert
      const ArrayWert = rentenwert.filter(
        (value) => value.beg <= e.value && value.end > e.value
      );
      const filterWert = ArrayWert[0] ? ArrayWert[0].wert : 0;

      const numbString = va.drv.replace(",", ".");
      const entgeltPunkte =
        Math.round((numbString / filterWert) * 10000) / 10000;
      dispatch({
        type: "SET_VA",
        va: selectedVA,
        payload: {
          ...va,
          eheEnde: e.value,
          rentenWertEheEnde: filterWert,
          entgeltPunkte: entgeltPunkte,
        },
      });
    } else if (e.target.name === "anpFak") {
      dispatch({
        type: "SET_VA",
        va: selectedVA,
        payload: {
          ...va,
          anpFak: !va.anpFak,
        },
      });
    } else if (e.target.name === "drv") {
      const numbString = e.target.value.replace(",", ".");
      const filterWert =
        Math.round((numbString / va.rentenWertEheEnde) * 10000) / 10000;
      dispatch({
        type: "SET_VA",
        va: selectedVA,
        payload: {
          ...va,
          drv: e.target.value,
          entgeltPunkte: filterWert,
        },
      });
    } else {
      dispatch({
        type: "SET_VA",
        va: selectedVA,
        payload: {
          ...va,
          [e.target.name]: e.target.value,
        },
      });
    }
  };

  const getStufen = () => {
    let Stufen = "";
    if (va.besO === "A") {
      Stufen = stufenA.filter((value, index) => {
        return value.gruppe === va.besG;
      });
    } else if (va.besO === "B") {
      Stufen = stufenB.filter((value, index) => {
        return value.gruppe === va.besG;
      });
    } else if (va.besO === "C") {
      Stufen = stufenC.filter((value, index) => {
        return value.gruppe === va.besG;
      });
    } else if (va.besO === "W") {
      Stufen = stufenW.filter((value, index) => {
        return value.gruppe === va.besG;
      });
    } else if (va.besO === "P") {
      Stufen = stufenP.filter((value, index) => {
        return value.gruppe === va.besG;
      });
    }

    return Stufen.length > 0 ? Stufen[0].stufe : 0;
  };

  const getStufen1990 = () => {
    let Stufen = "";
    if (va.besO1990 === "A") {
      Stufen = stufenA1990.filter((value, index) => {
        return value.gruppe === va.besG1990;
      });
    } else if (va.besO1990 === "B") {
      Stufen = stufenB1990.filter((value, index) => {
        return value.gruppe === va.besG1990;
      });
    } else if (va.besO1990 === "P") {
      Stufen = stufenP1990.filter((value, index) => {
        return value.gruppe === va.besG1990;
      });
    }

    return Stufen.length > 0 ? Stufen[0].stufe : 0;
  };
  //array mit den stufen
  const stufen = getStufen();
  const stufen1990 = getStufen1990();

  const Va = beruf === "Pfarrer" ? va_pf : va_beamt;
  const vaFilter = Va.filter((value) => {
    return (
      value.von <= va.besO + va.besG &&
      value.bis >= va.besO + va.besG &&
      value.date > va.eheEnde
    );
  });

  const newKuerz = [];
  let dateIndi = "";
  let dateBezuge = "";

  const TableElements = [];

  vaFilter.map((va_step, index) => {
    //normaler Kürzungsbetrag
    const numbString = va.betrag && va.betrag.replace(",", ".");
    newKuerz[index] =
      index === 0
        ? Math.round(
            (+numbString + +kuerzVA(+numbString, va_step.fest)) * 100
          ) / 100
        : Math.round(
            (+newKuerz[index - 1] +
              +kuerzVA(newKuerz[index - 1], va_step.fest)) *
              100
          ) / 100;

    //individuelle Erhöhung
    if (va_step.indi) {
      dateIndi = va_step.date === "1990-01-01" ? "1990" : "2009";
      //dient dazu um am 01.03.2009 die Besoldung von der Periode davor zu bekommen.
      dateBezuge = va_step.date === "1990-01-01" ? "1990-01-01" : "2008-01-01";
      va_step.fest = indiVA(
        dateBezuge,
        va["besO" + dateIndi],
        va["besG" + dateIndi],
        va["stufe" + dateIndi],
        va["zulagen" + dateIndi],
        va_step.indi
      )[0];
      va_step.bezuege = indiVA(
        dateBezuge,
        va["besO" + dateIndi],
        va["besG" + dateIndi],
        va["stufe" + dateIndi],
        va["zulagen" + dateIndi],
        va_step.indi
      )[1];
      //normaler Kürzungsbetrag
      newKuerz[index] =
        index === 0
          ? Math.round(
              (+numbString + +kuerzVA(+numbString, va_step.fest)) * 100
            ) / 100
          : Math.round(
              (+newKuerz[index - 1] +
                +kuerzVA(newKuerz[index - 1], va_step.fest)) *
                100
            ) / 100;
    }

    //Euroumstellung
    if (va_step.fest === 0) {
      newKuerz[index] = DmToEuro(newKuerz[index - 1]);
    }

    if (va_step.faktor && va.anpFak === true) {
      const lastFaktor =
        va_step.date === "2003-11-01"
          ? 1
          : va_step.date === "2003-07-01"
          ? 1
          : va_step.date === "2004-04-01"
          ? 0.99458
          : va_step.date === "2004-08-01"
          ? 0.98917
          : va_step.date === "2007-10-01"
          ? 0.98375
          : va_step.date === "2009-03-01"
          ? 0.97833
          : va_step.date === "2010-03-01"
          ? 0.97292
          : va_step.date === "2012-01-01"
          ? 0.9675
          : va_step.date === "2012-11-01" && 0.96208;
      newKuerz[index] =
        Math.round(((newKuerz[index] * va_step.faktor) / lastFaktor) * 100) /
        100;
    }

    TableElements.push(
      <tr key={index}>
        <td>{moment(va_step.date).format("DD.MM.YYYY")}</td>
        <td>
          {va_step.fest + "%"}
          {va_step.indi && (
            <Row>
              <Col md="6">
                {va_step.indi === 60
                  ? "(60 DM Sockelbetrag als individuelle Erhöhung)"
                  : "(40 € Sockelbetrag als individuelle Erhöhung)"}

                {va_step.indi === 40 &&
                  va.eheEnde <= "1998-01-01" &&
                  " Achtung Stufenabsenkung durch Reformgesetz beachten"}
              </Col>
              <Col md="6">
                {va_step.indi === 60 && (
                  <span>
                    {"60 DM * 100 / "}
                    <NumberFormat
                      decimalScale="2"
                      fixedDecimalScale={true}
                      value={va_step.bezuege}
                      displayType={"text"}
                      thousandSeparator="."
                      decimalSeparator=","
                      suffix={" DM"}
                    />
                  </span>
                )}
                {va_step.indi === 40 && (
                  <span>
                    {"40 € * 100 / "}
                    <NumberFormat
                      decimalScale="2"
                      fixedDecimalScale={true}
                      value={va_step.bezuege}
                      displayType={"text"}
                      thousandSeparator="."
                      decimalSeparator=","
                      suffix={" €"}
                    />
                  </span>
                )}
              </Col>

              <Col md="auto" className="mt-2">
                <FormControl
                  disabled={view}
                  size="small"
                  required
                  style={{ minWidth: "200px" }}
                  variant="outlined"
                >
                  <InputLabel>Besoldungsordnung</InputLabel>
                  <Select
                    disabled={view}
                    error={!va["besO" + dateIndi] ? true : false}
                    native
                    label="Besoldungsordnung"
                    name={"besO" + dateIndi}
                    value={va["besO" + dateIndi]}
                    onChange={handleVAChange}
                  >
                    {dateIndi === "1990" ? (
                      <>
                        <option aria-label="None" value="" />
                        <option value={"A"}>A</option>
                        <option value={"B"}>B</option>
                        <option value={"P"}>P</option>
                      </>
                    ) : (
                      <>
                        <option aria-label="None" value="" />
                        <option value={"A"}>A</option>
                        <option value={"B"}>B</option>
                        <option value={"C"}>C</option>
                        <option value={"P"}>P</option>
                        <option value={"W"}>W</option>
                      </>
                    )}
                  </Select>
                </FormControl>
              </Col>

              <Col md="auto" className="mt-2">
                <FormControl
                  disabled={view}
                  size="small"
                  style={{ minWidth: "200px" }}
                  required
                  variant="outlined"
                >
                  <InputLabel>Besoldungsgruppe</InputLabel>
                  <Select
                    disabled={view}
                    error={!va["besG" + dateIndi] ? true : false}
                    native
                    label="Besoldungsgruppe"
                    name={"besG" + dateIndi}
                    value={va["besG" + dateIndi]}
                    onChange={handleVAChange}
                  >
                    <option aria-label="None" value="" />
                    {dateIndi === "1990" ? (
                      va["besO" + dateIndi] === "A" ? (
                        besA1990.map((x, y) => (
                          <option value={x < 10 ? "0" + x : x} key={y}>
                            {x}
                          </option>
                        ))
                      ) : va["besO" + dateIndi] === "B" ? (
                        besB1990.map((x, y) => (
                          <option value={x < 10 ? "0" + x : x} key={y}>
                            {x}
                          </option>
                        ))
                      ) : va["besO" + dateIndi] === "P" ? (
                        besP1990.map((x, y) => (
                          <option value={x < 10 ? "0" + x : x} key={y}>
                            {x}
                          </option>
                        ))
                      ) : (
                        <option aria-label="None" value="" />
                      )
                    ) : va["besO" + dateIndi] === "A" ? (
                      besA.map((x, y) => (
                        <option value={x < 10 ? "0" + x : x} key={y}>
                          {x}
                        </option>
                      ))
                    ) : va["besO" + dateIndi] === "B" ? (
                      besB.map((x, y) => (
                        <option value={x < 10 ? "0" + x : x} key={y}>
                          {x}
                        </option>
                      ))
                    ) : va["besO" + dateIndi] === "C" ? (
                      besC.map((x, y) => (
                        <option value={x < 10 ? "0" + x : x} key={y}>
                          {x}
                        </option>
                      ))
                    ) : va["besO" + dateIndi] === "P" ? (
                      besP.map((x, y) => (
                        <option value={x < 10 ? "0" + x : x} key={y}>
                          {x}
                        </option>
                      ))
                    ) : va["besO" + dateIndi] === "W" ? (
                      besW.map((x, y) => (
                        <option value={x < 10 ? "0" + x : x} key={y}>
                          {x}
                        </option>
                      ))
                    ) : (
                      <option aria-label="None" value="" />
                    )}
                  </Select>
                </FormControl>
              </Col>

              <Col md="auto" className="mt-2">
                <FormControl size="small" required variant="outlined">
                  <InputLabel>Stufe</InputLabel>
                  <Select
                    error={!va["stufe" + dateIndi] ? true : false}
                    native
                    label="Stufe"
                    name={"stufe" + dateIndi}
                    value={va["stufe" + dateIndi]}
                    onChange={handleVAChange}
                    disabled={
                      va["besO" + dateIndi] === "B" || view === true
                        ? true
                        : false
                    }
                  >
                    <option aria-label="None" value="" />
                    {dateIndi === "2009"
                      ? stufen !== 0 &&
                        stufen.map((x, y) => <option key={y}>{x}</option>)
                      : stufen1990 !== 0 &&
                        stufen1990.map((x, y) => <option key={y}>{x}</option>)}
                  </Select>
                </FormControl>
              </Col>
              <Col md="auto" className="mt-2">
                <TextField
                  disabled={view}
                  size="small"
                  label="Zulagen?"
                  value={va["zulagen" + dateIndi]}
                  onChange={handleVAChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  name={"zulagen" + dateIndi}
                />
              </Col>
            </Row>
          )}
          {va_step.fest === 0 && <div>(Währungsumstellung auf Euro)</div>}
          {va_step.faktor && va.anpFak === true && (
            <div>
              vermindert durch Faktoren nach §69e (* {va_step.faktor} /{" "}
              {va_step.date === "2003-11-01"
                ? 1
                : va_step.date === "2003-07-01"
                ? 1
                : va_step.date === "2004-04-01"
                ? 0.99458
                : va_step.date === "2004-08-01"
                ? 0.98917
                : va_step.date === "2007-10-01"
                ? 0.98375
                : va_step.date === "2009-03-01"
                ? 0.97833
                : va_step.date === "2010-03-01"
                ? 0.97292
                : va_step.date === "2012-01-01"
                ? 0.9675
                : va_step.date === "2012-11-01" && 0.96208}
              )
            </div>
          )}
        </td>
        {/*}
                <td>
                    {
                        index === 0 ?
                        kuerzVA(+va.betrag,va_step.fest)
                        : +kuerzVA(newKuerz[index-1],va_step.fest)
                    }
                </td>
                {*/}
        <td>
          <NumberFormat
            decimalScale="2"
            fixedDecimalScale={true}
            value={newKuerz[index]}
            displayType={"text"}
            thousandSeparator="."
            decimalSeparator=","
            suffix={
              va_step.fest === 0
                ? " €"
                : va_step.date < "2003-01-11"
                ? " DM"
                : " €"
            }
          />
        </td>
      </tr>
    );
  });

  const handleAddVA = () => {
    dispatch({ type: "ADD_VA" });
  };

  useEffect(() => {
    handleVAChange({
      name: "kuerzung",
      betrag: newKuerz[newKuerz.length - 1],
    });
  }, [newKuerz[newKuerz.length - 1]]);

  const appendLeadingZeroes = (n) => {
    if (n <= 9) {
      return "0" + n;
    }
    return n;
  };
  useEffect(() => {
    if (Object.prototype.toString.call(eheEnde) === "[object Date]") {
      handleVAChange({
        name: "eheEnde",
        value:
          eheEnde.getFullYear() +
          "-" +
          appendLeadingZeroes(eheEnde.getMonth() + 1) +
          "-" +
          appendLeadingZeroes(eheEnde.getDate()),
      });
    }
  }, [eheEnde]);

  //Buttons bei mehreren VAs
  const Buttons = [];
  for (let index = 0; index < count; index++) {
    Buttons.push(
      <Button
        key={index}
        onClick={() => setSelectedVA(`va${index + 1}`)}
        variant="outlined"
      >{`VA${index + 1}`}</Button>
    );
  }

  return (
    <div>
      <h3>aktuell {selectedVA}</h3>
      {count > 1 && Buttons}
      <Button variant="outlined" onClick={handleAddVA}>
        weiteren VA anlegen
      </Button>
      <Row>
        <Col md="auto" className="mt-2">
          <FormControl
            required
            style={{ minWidth: "200px" }}
            variant="outlined"
          >
            <InputLabel>Besoldungsordnung</InputLabel>
            <Select
              disabled={view}
              error={!va.besO ? true : false}
              native
              label="Besoldungsordnung"
              name="besO"
              value={va.besO}
              onChange={handleVAChange}
            >
              <option aria-label="None" value="" />
              <option value={"A"}>A</option>
              <option value={"B"}>B</option>
              <option value={"C"}>C</option>
              <option value={"P"}>P</option>
              <option value={"W"}>W</option>
            </Select>
          </FormControl>
        </Col>

        <Col md="auto" className="mt-2">
          <FormControl
            style={{ minWidth: "200px" }}
            required
            variant="outlined"
          >
            <InputLabel>Besoldungsgruppe</InputLabel>
            <Select
              disabled={view}
              error={!va.besG ? true : false}
              native
              label="Besoldungsgruppe"
              name="besG"
              value={va.besG}
              onChange={handleVAChange}
            >
              <option aria-label="None" value="" />
              {va.besO === "A" ? (
                besA.map((x, y) => (
                  <option value={x < 10 ? "0" + x : x} key={y}>
                    {x}
                  </option>
                ))
              ) : va.besO === "B" ? (
                besB.map((x, y) => (
                  <option value={x < 10 ? "0" + x : x} key={y}>
                    {x}
                  </option>
                ))
              ) : va.besO === "C" ? (
                besC.map((x, y) => (
                  <option value={x < 10 ? "0" + x : x} key={y}>
                    {x}
                  </option>
                ))
              ) : va.besO === "P" ? (
                besP.map((x, y) => (
                  <option value={x < 10 ? "0" + x : x} key={y}>
                    {x}
                  </option>
                ))
              ) : va.besO === "W" ? (
                besW.map((x, y) => (
                  <option value={x < 10 ? "0" + x : x} key={y}>
                    {x}
                  </option>
                ))
              ) : (
                <option aria-label="None" value="" />
              )}
            </Select>
          </FormControl>
        </Col>

        <Col md="auto" className="mt-2">
          <FormControl required variant="outlined">
            <InputLabel>Stufe</InputLabel>
            <Select
              error={!va.stufe ? true : false}
              native
              label="Stufe"
              name="stufe"
              value={va.stufe}
              onChange={handleVAChange}
              disabled={va.besO === "B" || view === true ? true : false}
            >
              <option aria-label="None" value="" />
              {stufen !== 0 &&
                stufen.map((x, y) => <option key={y}>{x}</option>)}
            </Select>
          </FormControl>
        </Col>
        <Col md="auto" className="mt-2">
          <LocalizationProvider locale={deLocale} dateAdapter={AdapterDateFns}>
            <DatePicker
              disabled={view}
              label="Ehezeitende"
              name="eheEnde"
              mask="__.__.____"
              value={eheEnde}
              onChange={(newVal) => setEheEnde(newVal)}
              renderInput={(params) => (
                <TextField required variant="outlined" {...params} />
              )}
            />
          </LocalizationProvider>
        </Col>
        <Col md="auto" className="mt-2">
          <FormControl required variant="outlined">
            <InputLabel>Währung</InputLabel>
            <Select
              disabled={view}
              native
              label="Währung"
              name="waehrung"
              value={va.waehrung}
              onChange={handleVAChange}
            >
              <option value={"DM"}>DM</option>
              <option value={"€"}>€</option>
            </Select>
          </FormControl>
        </Col>
        <Col md="auto" className="mt-2">
          <TextField
            disabled={view}
            label="Ausgangsbetrag"
            value={va.betrag}
            onChange={handleVAChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">{va.waehrung}</InputAdornment>
              ),
            }}
            variant="outlined"
            name="betrag"
          />
        </Col>

        <Col md="auto" className="mt-2">
          <FormControlLabel
            control={
              <Checkbox
                disabled={view}
                color="primary"
                checked={va.anpFak}
                onChange={handleVAChange}
                name="anpFak"
              />
            }
            label="mit Anpassungsfaktoren?"
          />
        </Col>
      </Row>

      {/*Rentenausgleich für Bescheid*/}
      <Row>
        <Col>Versorgungsausgleich in der DRV</Col>
      </Row>
      <Row className="mb-2">
        <Col md="auto" className="mt-2">
          <TextField
            disabled={view}
            label="Ausgleichsbetrag"
            value={va.drv}
            onChange={handleVAChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">{va.waehrung}</InputAdornment>
              ),
            }}
            variant="outlined"
            name="drv"
          />
        </Col>
        <Col md="auto" className="mt-2">
          <TextField
            disabled
            label="Rentenwert"
            value={va.rentenWertEheEnde}
            onChange={handleVAChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">{va.waehrung}</InputAdornment>
              ),
            }}
            variant="outlined"
            name="rentenWertEheEnde"
          />
        </Col>
        <Col md="auto" className="mt-2">
          <TextField
            disabled
            label="Entgeltpunkte"
            value={va.entgeltPunkte}
            onChange={handleVAChange}
            variant="outlined"
            name="entgeltPunkte"
          />
        </Col>
      </Row>

      <Table striped size="sm">
        <thead>
          <tr>
            <td>Zeitpunkt</td>
            <td>Anpassung</td>
            {/*}
                        <td>
                            Erhöhung-€
                        </td>
                        {*/}
            <td>Kürzungsbetrag</td>
          </tr>
        </thead>
        <tbody>{TableElements}</tbody>
      </Table>
    </div>
  );
};

export default VA;
