import { SET_BEZUG } from "../constants/actionTypes";

const initialState = {
  data: {
    grundg: 0,
    fz: 0,
    struk: 0,
    rgfB: 0,
    zulagen: [],
    sumZulagen: 0,
    anzZul: 1,
  },
};

const bezug = (state = initialState, action) => {
  switch (action.type) {
    case SET_BEZUG:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default bezug;
