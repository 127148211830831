import {
  SET_MISCH_NEEDED,
  SET_ZEITEN_CHANGE,
  SET_ZEITEN_BOOL,
  SET_ANZ_ZEITEN,
  SET_ANZ_ZEITEN_MINUS,
  SET_ZEIT,
  SET_GESAMTZEIT,
  SET_ZEIT21,
  SET_ZEIT21HGR,
  SET_ZEIT27,
  SET_ZEIT27HGR,
  SET_ANZAHL_ZEITEN,
  SET_MAN_RGS,
} from "../constants/actionTypes";

const initialState = {
  mischNeeded: true,
  anzZei: 1,
  zeitenBool: false,
  zeitenChange: "",
  zeiten: {
    z1: {
      ze: "Z001",
      za: 1,
      n: 1,
      rgfD: { jahre: 0, tage: 0 },
    },
  },
  zeiten21: {
    z1: {
      ze: "Z001",
      za: 1,
      n: 1,
      rgfD: { jahre: 0, tage: 0 },
    },
  },
  zeiten21HGR: {
    z1: {
      ze: "Z001",
      za: 1,
      n: 1,
      rgfD: { jahre: 0, tage: 0 },
    },
  },
  zeiten27: {
    z1: {
      ze: "Z001",
      za: 1,
      n: 1,
      rgfD: { jahre: 0, tage: 0 },
    },
  },
  zeiten27HGR: {
    z1: {
      ze: "Z001",
      za: 1,
      n: 1,
      rgfD: { jahre: 0, tage: 0 },
    },
  },
  gesamtzeit: {
    gesamtZ: [0, 0, 0, 0, 0, 0, 0, 0, 0],
    gesamtZMisch: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    gesamtZ85Neu: [0, 0, 0, 0, 0, 0, 0, 0, 0],
    gesamtZ85Misch: [0, 0, 0, 0, 0, 0, 0, 0, 0],
  },
  manRGS: "0",
};
const Zeiten = (state = initialState, action) => {
  switch (action.type) {
    case SET_ANZ_ZEITEN:
      return {
        ...state,
        anzZei: state.anzZei + 1,
      };
    case SET_ANZ_ZEITEN_MINUS:
      return {
        ...state,
        anzZei: state.anzZei - 1,
      };
    case SET_ANZAHL_ZEITEN:
      return {
        ...state,
        anzZei: action.payload,
      };
    case SET_ZEIT:
      return {
        ...state,
        zeiten: action.payload,
      };
    case SET_GESAMTZEIT:
      return {
        ...state,
        gesamtzeit: action.payload,
      };
    case SET_ZEIT21:
      return {
        ...state,
        zeiten21: action.payload,
      };
    case SET_ZEIT21HGR:
      return {
        ...state,
        zeiten21HGR: action.payload,
      };
    case SET_ZEIT27:
      return {
        ...state,
        zeiten27: action.payload,
      };
    case SET_ZEIT27HGR:
      return {
        ...state,
        zeiten27HGR: action.payload,
      };
    case SET_ZEITEN_BOOL:
      return {
        ...state,
        zeitenBool: !state.zeitenBool,
      };
    case SET_ZEITEN_CHANGE:
      return {
        ...state,
        zeitenChange: action.payload,
      };
    case SET_MISCH_NEEDED:
      return {
        ...state,
        mischNeeded: action.payload,
      };
    case SET_MAN_RGS:
      return {
        ...state,
        manRGS: action.payload,
      };
    default:
      return state;
  }
};

export default Zeiten;
