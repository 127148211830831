import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  Select,
  TextField,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import {
  grundg,
  getMaxStufe,
  Art26Abs6,
  zulage,
  fetchBVG,
} from "./helper/functions";

const Hgr85 = () => {
  const dispatch = useDispatch();
  const hgr85 = useSelector((state) => state.HGR85.data);
  const detail = useSelector((state) => state.detail.data);
  const rente = useSelector((state) => state.rente.data);
  const bezug = useSelector((state) => state.bezug.data);
  const versB = useSelector((state) => state.versB.data);
  const data = useSelector((state) => state.person.data);
  const zeiten = useSelector((state) => state.Zeiten);
  const mischNeeded = useSelector((state) => state.Zeiten.mischNeeded);
  const view = useSelector((state) => state.Vorgang.view);

  const fiktiverRGS = detail.rente
    ? mischNeeded === false
      ? zeiten.gesamtzeit.gesamtZ85Neu[4]
      : zeiten.gesamtzeit.gesamtZ85Misch[10]
    : 0;

  const e = {};
  //Höchstgrenze
  const stufeArray = getMaxStufe(detail.besO, detail.besG);
  const stufe = Math.max(...stufeArray);
  const grund = grundg(detail.besO + detail.besG, stufe, rente.beg).betrag;
  const newFzPer = data.fzPer.replace(",", ".");
  let fam = zulage("fz1", rente.beg, detail.besO + detail.besG)["betrag"];
  fam =
    data.fam === "1" || data.fam === "2" || data.fam === "5" || data.fam === "9"
      ? Math.round(fam * +newFzPer) / 100
      : 0;
  const strZulage = zulage("struk", rente.beg, detail.besO + detail.besG)[
    "betrag"
  ];
  const rgfB =
    Math.round((+grund + +fam + +bezug.sumZulagen + +strZulage) * 100) / 100;
  const rgs = fiktiverRGS;
  const ruhegehalt = Math.round(((rgfB * rgs) / 100) * 100) / 100;
  const abschlagEuro =
    -Math.round(((versB.abschlagPro * ruhegehalt) / 100) * 100) / 100;
  const aufschlagEuro =
    Math.round(((versB.aufschlagPro * ruhegehalt) / 100) * 100) / 100;
  const summeRuhe = +ruhegehalt + +abschlagEuro + +aufschlagEuro;
  const hgr = +summeRuhe + +versB.fzK;

  //Ruhensbetrag
  //Gesamtversorgung
  const versBezugAfterMin = versB.versBezugAfterMin;
  let unfallClean =
    +`${hgr85.unfall}`.replace(",", ".") - hgr85.unfallUnbeachtet;
  if (unfallClean < 0) {
    unfallClean = 0;
  }
  const rente85 =
    +rente.rente85nachHöher +
    +`${hgr85.ezvk}`.replace(",", ".") +
    +`${unfallClean}`.replace(",", ".") +
    +`${hgr85.ausland}`.replace(",", ".") +
    +`${hgr85.berufs}`.replace(",", ".");
  const gesamtVers = Math.round((+versBezugAfterMin + +rente85) * 100) / 100;

  //Anrechnung
  const ruhe =
    +gesamtVers > +hgr
      ? +gesamtVers - +hgr > +rente85
        ? -Math.round(+rente85 * 100) / 100
        : -Math.round((+gesamtVers - +hgr) * 100) / 100
      : 0;

  //Art 26 Abs. 6 ?
  const returnArt26Abs6 = Art26Abs6(versB, rente85);

  const handle85Change = (e) => {
    if (e.target.name === "ruhe") {
      dispatch({
        type: "SET_HGR85",
        payload: {
          ...hgr85,
          art26abs6: e.target.art26abs6,
          ruhe85: e.target.ruhe85,
        },
      });
    } else {
      dispatch({
        type: "SET_HGR85",
        payload: {
          ...hgr85,
          [e.target.name]: e.target.value,
        },
      });
    }
  };

  const getBVG = async () => {
    const value = await fetchBVG(rente.beg, hgr85.mde);
    if (value) {
      dispatch({
        type: "SET_HGR85",
        payload: { ...hgr85, unfallUnbeachtet: value },
      });
    }
  };

  useEffect(() => {}, []);

  useEffect(() => {
    e.target = { name: "ruhe", art26abs6: returnArt26Abs6, ruhe85: ruhe };
    handle85Change(e);
  }, [ruhe]);

  useEffect(() => {
    getBVG();
  }, [hgr85.mde, hgr85.unfall]);

  return (
    <div>
      {/*Kopfteil*/}
      <div>
        <b>Rente(n) i. S. d. Art. 85 BayBeamtVG</b>
      </div>
      {/*gesetzl. Rente */}
      <Row>
        <Col>aus der gesetzlichen Rentenversicherung</Col>
        <Col>
          <NumberFormat
            decimalScale="2"
            fixedDecimalScale={true}
            value={rente.rente85nachHöher}
            displayType={"text"}
            thousandSeparator="."
            decimalSeparator=","
            suffix={" €"}
          />
        </Col>
      </Row>
      {/*ezvk*/}
      <Row className="mt-2">
        <Col>
          aus einer Alters- und Hinterbliebenenversorgung des öffentlichen
          Dienst
        </Col>
        <Col>
          <TextField
            disabled={view}
            size="small"
            label="Betrag"
            value={hgr85.ezvk}
            onChange={handle85Change}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
            variant="outlined"
            name={"ezvk"}
          />
        </Col>
      </Row>
      {/*Unfallversicherung*/}
      <Row className="mt-2">
        <Col>aus der gesetzlichen Unfallversicherung</Col>
        <Col>
          <TextField
            disabled={view}
            size="small"
            label="Betrag"
            value={hgr85.unfall}
            onChange={handle85Change}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
            variant="outlined"
            name="unfall"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormControl required variant="outlined" size="small">
            <InputLabel>MdE</InputLabel>
            <Select
              disabled={view}
              native
              label="MdE"
              name="mde"
              value={hgr85.mde}
              onChange={handle85Change}
            >
              <option aria-label="None" value="" />
              <option value={10}>10 %</option>
              <option value={20}>20 %</option>
              <option value={30}>30 %</option>
              <option value={40}>40 %</option>
              <option value={50}>50 %</option>
              <option value={60}>60 %</option>
              <option value={70}>70 %</option>
              <option value={80}>80 %</option>
              <option value={90}>90 %</option>
              <option value={100}>100 %</option>
            </Select>
          </FormControl>
        </Col>
        <Col>
          {hgr85.mde && hgr85.unfallUnbeachtet && (
            <div>
              davon anrechnungsfrei
              <br />
              <NumberFormat
                displayType="text"
                value={hgr85.unfallUnbeachtet}
                decimalSeparator=","
                thousandSeparator="."
                suffix=" €"
                allowLeadingZeros
                decimalScale="2"
                fixedDecimalScale
              />
            </div>
          )}
        </Col>
      </Row>
      {/*Berufsständige*/}
      <Row className="mt-2">
        <Col>aus einer berufsständischen Versorgungseinrichtung</Col>
        <Col>
          <TextField
            disabled={view}
            size="small"
            label="Betrag"
            value={hgr85.berufs}
            onChange={handle85Change}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
            variant="outlined"
            name={"berufs"}
          />
        </Col>
      </Row>
      {/*ausland*/}
      <Row className="mt-2">
        <Col>eines ausländischen Versicherungsträger</Col>
        <Col>
          <TextField
            disabled={view}
            size="small"
            label="Betrag"
            value={hgr85.ausland}
            onChange={handle85Change}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
            variant="outlined"
            name={"ausland"}
          />
        </Col>
      </Row>

      {/*Summe*/}
      <Row className="mt-2 border-top">
        <Col>
          <b>Summe der Rente(n) nach Art. 85 BayBeamtVG</b>
        </Col>
        <Col>
          <NumberFormat
            decimalScale="2"
            fixedDecimalScale={true}
            value={rente85}
            displayType={"text"}
            thousandSeparator="."
            decimalSeparator=","
            suffix={" €"}
          />
        </Col>
      </Row>

      <br />
      <div>
        <b>Berechnung der Höchstgrenze nach Art. 85 BayBeamtVG</b>
      </div>
      {/*Grundbezug*/}
      <Row>
        <Col>Grundbezug</Col>
        <Col>
          <NumberFormat
            decimalScale="2"
            fixedDecimalScale={true}
            value={grund}
            displayType={"text"}
            thousandSeparator="."
            decimalSeparator=","
            suffix={" €"}
          />
        </Col>
      </Row>
      {bezug.fz > 0 && (
        <Row>
          <Col>Familienzuschlag (Stufe 1)</Col>
          <Col>
            <NumberFormat
              decimalScale="2"
              fixedDecimalScale={true}
              value={fam}
              displayType={"text"}
              thousandSeparator="."
              decimalSeparator=","
              suffix={" €"}
            />
          </Col>
        </Row>
      )}
      {bezug.struk > 0 && (
        <Row>
          <Col>Strukturzulage</Col>
          <Col>
            <NumberFormat
              decimalScale="2"
              fixedDecimalScale={true}
              value={strZulage}
              displayType={"text"}
              thousandSeparator="."
              decimalSeparator=","
              suffix={" €"}
            />
          </Col>
        </Row>
      )}
      {bezug.sumZulagen > 0 && (
        <Row>
          <Col>Summe sonstiger Zulagen</Col>
          <Col>
            <NumberFormat
              decimalScale="2"
              fixedDecimalScale={true}
              value={bezug.sumZulagen}
              displayType={"text"}
              thousandSeparator="."
              decimalSeparator=","
              suffix={" €"}
            />
          </Col>
        </Row>
      )}
      <Row className="border-top">
        <Col>
          <b>ruhegehaltfähige Bezüge</b>
        </Col>
        <Col>
          <NumberFormat
            decimalScale="2"
            fixedDecimalScale={true}
            value={rgfB}
            displayType={"text"}
            thousandSeparator="."
            decimalSeparator=","
            suffix={" €"}
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col>Ruhegehaltsatz (fiktiv)</Col>
        <Col>
          <NumberFormat
            decimalScale="2"
            fixedDecimalScale={true}
            value={rgs}
            displayType={"text"}
            thousandSeparator="."
            decimalSeparator=","
            suffix={" %"}
          />
        </Col>
      </Row>
      <Row>
        <Col>Ruhegehalt (fiktiv)</Col>
        <Col>
          <NumberFormat
            decimalScale="2"
            fixedDecimalScale={true}
            value={ruhegehalt}
            displayType={"text"}
            thousandSeparator="."
            decimalSeparator=","
            suffix={" €"}
          />
        </Col>
      </Row>
      {detail.grund !== "1" &&
        versB.abschlagPro !== 0 &&
        !isNaN(versB.abschlagPro) && (
          <div>
            <Row>
              <Col>
                Versorgungsabschlag
                <div>{versB.abschlagPro} %</div>
              </Col>

              <Col className="align-self-end">
                <NumberFormat
                  decimalScale="2"
                  fixedDecimalScale={true}
                  value={abschlagEuro}
                  displayType={"text"}
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix={" €"}
                />
              </Col>
            </Row>
            <Row>
              <Col>Summe Ruhegehalt</Col>
              <Col>
                <NumberFormat
                  decimalScale="2"
                  fixedDecimalScale={true}
                  value={summeRuhe}
                  displayType={"text"}
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix={" €"}
                />
              </Col>
            </Row>
          </div>
        )}
      {detail.grund === "1" &&
        versB.aufschlagPro !== 0 &&
        !isNaN(versB.aufschlagPro) && (
          <div>
            <Row>
              <Col>Versorgungsaufschlag</Col>
              <Col>
                <NumberFormat
                  decimalScale="2"
                  fixedDecimalScale={true}
                  value={aufschlagEuro}
                  displayType={"text"}
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix={" €"}
                />
              </Col>
            </Row>
            <Row>
              <Col>Summe Ruhegehalt</Col>
              <Col>
                <NumberFormat
                  decimalScale="2"
                  fixedDecimalScale={true}
                  value={summeRuhe}
                  displayType={"text"}
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix={" €"}
                />
              </Col>
            </Row>
          </div>
        )}
      {versB.fzK > 0 && (
        <Row>
          <Col>Familienzuschlag (Kind)</Col>
          <Col>
            <NumberFormat
              decimalScale="2"
              fixedDecimalScale={true}
              value={versB.fzK}
              displayType={"text"}
              thousandSeparator="."
              decimalSeparator=","
              suffix={" €"}
            />
          </Col>
        </Row>
      )}
      <Row className="border-top font-weight-bold">
        <Col>Höchstgrenze</Col>
        <Col>
          <NumberFormat
            decimalScale="2"
            fixedDecimalScale={true}
            value={hgr}
            displayType={"text"}
            thousandSeparator="."
            decimalSeparator=","
            suffix={" €"}
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col>kirchlicher Versorgungsbezug</Col>
        <Col>
          <NumberFormat
            decimalScale="2"
            fixedDecimalScale={true}
            value={versB.versBezugAfterMin}
            displayType={"text"}
            thousandSeparator="."
            decimalSeparator=","
            suffix={" €"}
          />
        </Col>
      </Row>
      <Row>
        <Col>Rentenbetrag nach Art. 85 BayBeamtVG</Col>
        <Col>
          <NumberFormat
            decimalScale="2"
            fixedDecimalScale={true}
            value={rente85}
            displayType={"text"}
            thousandSeparator="."
            decimalSeparator=","
            suffix={" €"}
          />
        </Col>
      </Row>
      <Row className="border-top font-weight-bold">
        <Col>Gesamtversorgung</Col>
        <Col>
          <NumberFormat
            decimalScale="2"
            fixedDecimalScale={true}
            value={gesamtVers}
            displayType={"text"}
            thousandSeparator="."
            decimalSeparator=","
            suffix={" €"}
          />
        </Col>
      </Row>
      {ruhe < 0 && (
        <Row className="font-weight-bold">
          <Col>übersteigt die Höchstgrenze um</Col>
          <Col>
            <NumberFormat
              decimalScale="2"
              fixedDecimalScale={true}
              value={-1 * ruhe}
              displayType={"text"}
              thousandSeparator="."
              decimalSeparator=","
              suffix={" €"}
            />{" "}
            (Ruhensbetrag)
          </Col>
        </Row>
      )}
      {ruhe === 0 && (
        <Row className="font-weight-bold">
          <Col>
            Gesamtversorgung übersteigt die Höchstgrenze <u>nicht</u>
          </Col>
        </Row>
      )}
      <br />
      {ruhe < 0 && (
        <div>
          <Row>
            <Col>kirchlicher Versorgungsbezug</Col>
            <Col>
              <NumberFormat
                decimalScale="2"
                fixedDecimalScale={true}
                value={versB.versBezugAfterMin}
                displayType={"text"}
                thousandSeparator="."
                decimalSeparator=","
                suffix={" €"}
              />
            </Col>
          </Row>
          <Row className="font-weight-bold">
            <Col>Ruhensbetrag</Col>
            <Col>
              <NumberFormat
                decimalScale="2"
                fixedDecimalScale={true}
                value={-1 * ruhe}
                displayType={"text"}
                thousandSeparator="."
                decimalSeparator=","
                suffix={" €"}
              />
            </Col>
          </Row>
          <Row className="border-top">
            <Col>Nach Art. 85 BayBeamtVG geregelter Versorgungsbezug</Col>
            <Col>
              <NumberFormat
                fixedDecimalScale={true}
                decimalScale="2"
                value={versB.versBezugAfterMin - -1 * ruhe}
                displayType={"text"}
                decimalSeparator=","
                suffix={" €"}
              />
            </Col>
          </Row>
        </div>
      )}
      {
        /* Art. 26 Abs. 6*/
        rente85 > 0 && ruhe <= 0 && versB.mindestversorgung === true && (
          <div>
            <br />
            <Row>
              <Col>
                <Box className="p-1" border={1} borderRadius={16}>
                  Ruhensbetrag nach Art. 26 Abs. 6 BayBeamtVG
                </Box>
              </Col>
              <Col></Col>
            </Row>
            <div>
              {returnArt26Abs6 === false &&
                `Der nach Art. 85 BayBeamtVG geregelte Versorgungsbezug (${
                  Math.round(
                    (+versB.versBezugAfterMin + +versB.rente85) * 100
                  ) / 100
                }) übersteigt die "erdiente" Versorgung (${
                  versB.versBezugAfterFz
                }) nicht. Daher findet Art. 26 Abs. 6 BayBeamtVG keine Anwendung!`}
              {returnArt26Abs6 === 0 && (
                <Row>
                  <Col>Kein weiterer Kürzungsbetrag</Col>
                </Row>
              )}
              {returnArt26Abs6 > 0 && (
                <div>
                  <Row>
                    <Col>
                      <u>Art. 26 Abs. 6 Satz 1 BayBeamtVG</u>
                    </Col>
                  </Row>
                  <Row className="pl-2">
                    <Col>Mindestversorgung</Col>
                    <Col>
                      <NumberFormat
                        decimalScale="2"
                        fixedDecimalScale={true}
                        value={versB.versBezugAfterMin}
                        displayType={"text"}
                        thousandSeparator="."
                        decimalSeparator=","
                        suffix={" €"}
                      />
                    </Col>
                  </Row>
                  <Row className="pl-2">
                    <Col>erdienter Versorgungsbezug</Col>
                    <Col>
                      <NumberFormat
                        decimalScale="2"
                        fixedDecimalScale={true}
                        value={versB.versBezugAfterFz}
                        displayType={"text"}
                        thousandSeparator="."
                        decimalSeparator=","
                        suffix={" €"}
                      />
                    </Col>
                  </Row>
                  <Row className="pl-2 border-top font-weight-bold">
                    <Col>Differenz = weiterer Ruhensbetrag</Col>
                    <Col>
                      <NumberFormat
                        decimalScale="2"
                        fixedDecimalScale={true}
                        value={versB.versBezugAfterMin - versB.versBezugAfterFz}
                        displayType={"text"}
                        thousandSeparator="."
                        decimalSeparator=","
                        suffix={" €"}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row className="pl-2">
                    <Col>
                      Nach Art. 85 BayBeamtVG geregelter Versorgungsbezug
                    </Col>
                    <Col>
                      <NumberFormat
                        fixedDecimalScale={true}
                        decimalScale="2"
                        value={versB.versBezugAfterMin - -1 * ruhe}
                        displayType={"text"}
                        decimalSeparator=","
                        suffix={" €"}
                      />
                    </Col>
                  </Row>
                  <Row className="pl-2">
                    <Col>abzgl. weiterer Ruhensbetrag</Col>
                    <Col>
                      <NumberFormat
                        decimalScale="2"
                        fixedDecimalScale={true}
                        value={versB.versBezugAfterMin - versB.versBezugAfterFz}
                        displayType={"text"}
                        thousandSeparator="."
                        decimalSeparator=","
                        suffix={" €"}
                      />
                    </Col>
                  </Row>
                  <Row className="pl-2 border-top font-weight-bold">
                    <Col>Rest-Versorgungsbezug</Col>
                    <Col>
                      <NumberFormat
                        decimalScale="2"
                        fixedDecimalScale={true}
                        value={
                          versB.versBezugAfterMin -
                          -1 * ruhe -
                          (versB.versBezugAfterMin - versB.versBezugAfterFz)
                        }
                        displayType={"text"}
                        thousandSeparator="."
                        decimalSeparator=","
                        suffix={" €"}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <u>
                        Mindestzahlbetrag nach Art. 26 Abs. 6 Satz 3 BayBeamtVG
                      </u>
                    </Col>
                  </Row>
                  <Row className="pl-2">
                    <Col>Rest-Versorgungsbezug</Col>
                    <Col>
                      <NumberFormat
                        decimalScale="2"
                        fixedDecimalScale={true}
                        value={
                          versB.versBezugAfterMin -
                          -1 * ruhe -
                          (versB.versBezugAfterMin - versB.versBezugAfterFz)
                        }
                        displayType={"text"}
                        thousandSeparator="."
                        decimalSeparator=","
                        suffix={" €"}
                      />
                    </Col>
                  </Row>
                  <Row className="pl-2">
                    <Col>zuzügl. Rente</Col>
                    <Col>
                      <NumberFormat
                        decimalScale="2"
                        fixedDecimalScale={true}
                        value={rente.rente85nachHöher}
                        displayType={"text"}
                        thousandSeparator="."
                        decimalSeparator=","
                        suffix={" €"}
                      />
                    </Col>
                  </Row>
                  <Row className="pl-2 border-top font-weight-bold">
                    <Col>Gesamtversorgung</Col>
                    <Col>
                      <NumberFormat
                        decimalScale="2"
                        fixedDecimalScale={true}
                        value={
                          versB.versBezugAfterMin -
                          -1 * ruhe -
                          (versB.versBezugAfterMin - versB.versBezugAfterFz) +
                          rente.rente85nachHöher
                        }
                        displayType={"text"}
                        thousandSeparator="."
                        decimalSeparator=","
                        suffix={" €"}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row className="pl-2">
                    <Col>Mindestversorgung</Col>
                    <Col>
                      <NumberFormat
                        decimalScale="2"
                        fixedDecimalScale={true}
                        value={versB.versBezugAfterMin}
                        displayType={"text"}
                        thousandSeparator="."
                        decimalSeparator=","
                        suffix={" €"}
                      />
                    </Col>
                  </Row>
                  <Row className="pl-2">
                    <Col>übersteigt die Gesamtversorgung</Col>
                    <Col>
                      <NumberFormat
                        decimalScale="2"
                        fixedDecimalScale={true}
                        value={
                          versB.versBezugAfterMin -
                          -1 * ruhe -
                          (versB.versBezugAfterMin - versB.versBezugAfterFz) +
                          rente.rente85nachHöher
                        }
                        displayType={"text"}
                        thousandSeparator="."
                        decimalSeparator=","
                        suffix={" €"}
                      />
                    </Col>
                  </Row>
                  <Row className="pl-2 border-top font-weight-bold">
                    <Col>um (=Aufstockungsbetrag)</Col>
                    <Col>
                      <NumberFormat
                        decimalScale="2"
                        fixedDecimalScale={true}
                        value={
                          versB.versBezugAfterMin -
                          (versB.versBezugAfterMin -
                            -1 * ruhe -
                            (versB.versBezugAfterMin - versB.versBezugAfterFz) +
                            rente.rente85nachHöher)
                        }
                        displayType={"text"}
                        thousandSeparator="."
                        decimalSeparator=","
                        suffix={" €"}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row className="pl-2">
                    <Col>Rest-Versorgungsbezug</Col>
                    <Col>
                      <NumberFormat
                        decimalScale="2"
                        fixedDecimalScale={true}
                        value={
                          versB.versBezugAfterMin -
                          -1 * ruhe -
                          (versB.versBezugAfterMin - versB.versBezugAfterFz)
                        }
                        displayType={"text"}
                        thousandSeparator="."
                        decimalSeparator=","
                        suffix={" €"}
                      />
                    </Col>
                  </Row>
                  <Row className="pl-2">
                    <Col>zuzügl. Aufstockungsbetrag</Col>
                    <Col>
                      <NumberFormat
                        decimalScale="2"
                        fixedDecimalScale={true}
                        value={
                          versB.versBezugAfterMin -
                          (versB.versBezugAfterMin -
                            -1 * ruhe -
                            (versB.versBezugAfterMin - versB.versBezugAfterFz) +
                            rente.rente85nachHöher)
                        }
                        displayType={"text"}
                        thousandSeparator="."
                        decimalSeparator=","
                        suffix={" €"}
                      />
                    </Col>
                  </Row>
                  <Row className="pl-2 border-top font-weight-bold">
                    <Col>Mindestzahlbetrag</Col>
                    <Col>
                      <NumberFormat
                        decimalScale="2"
                        fixedDecimalScale={true}
                        value={
                          versB.versBezugAfterMin -
                          -1 * ruhe -
                          (versB.versBezugAfterMin - versB.versBezugAfterFz) +
                          (versB.versBezugAfterMin -
                            (versB.versBezugAfterMin -
                              -1 * ruhe -
                              (versB.versBezugAfterMin -
                                versB.versBezugAfterFz) +
                              rente.rente85nachHöher))
                        }
                        displayType={"text"}
                        thousandSeparator="."
                        decimalSeparator=","
                        suffix={" €"}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <u>Vergleich nach Art. 26 Abs. 6 Satz 4 BayBeamtVG</u>
                    </Col>
                  </Row>
                  <Row className="pl-2">
                    <Col>erdienter Versorgungsbezug</Col>
                    <Col>
                      <NumberFormat
                        decimalScale="2"
                        fixedDecimalScale={true}
                        value={versB.versBezugAfterFz}
                        displayType={"text"}
                        thousandSeparator="."
                        decimalSeparator=","
                        suffix={" €"}
                      />
                    </Col>
                  </Row>
                  <Row className="pl-2">
                    <Col>
                      übersteigt den (ggf. erhöhten) Rest-Versorgungsbezug
                    </Col>
                    <Col>
                      <NumberFormat
                        decimalScale="2"
                        fixedDecimalScale={true}
                        value={
                          versB.versBezugAfterMin -
                          -1 * ruhe -
                          (versB.versBezugAfterMin - versB.versBezugAfterFz) +
                          (versB.versBezugAfterMin -
                            (versB.versBezugAfterMin -
                              -1 * ruhe -
                              (versB.versBezugAfterMin -
                                versB.versBezugAfterFz) +
                              rente.rente85nachHöher))
                        }
                        displayType={"text"}
                        thousandSeparator="."
                        decimalSeparator=","
                        suffix={" €"}
                      />
                    </Col>
                  </Row>
                  <Row className="pl-2 border-top font-weight-bold">
                    <Col>um (=Aufstockungsbetrag)</Col>
                    <Col>
                      <NumberFormat
                        decimalScale="2"
                        fixedDecimalScale={true}
                        value={
                          versB.versBezugAfterFz -
                            (versB.versBezugAfterMin -
                              -1 * ruhe -
                              (versB.versBezugAfterMin -
                                versB.versBezugAfterFz) +
                              (versB.versBezugAfterMin -
                                (versB.versBezugAfterMin -
                                  -1 * ruhe -
                                  (versB.versBezugAfterMin -
                                    versB.versBezugAfterFz) +
                                  rente.rente85nachHöher))) <
                          0
                            ? 0
                            : versB.versBezugAfterFz -
                              (versB.versBezugAfterMin -
                                -1 * ruhe -
                                (versB.versBezugAfterMin -
                                  versB.versBezugAfterFz) +
                                (versB.versBezugAfterMin -
                                  (versB.versBezugAfterMin -
                                    -1 * ruhe -
                                    (versB.versBezugAfterMin -
                                      versB.versBezugAfterFz) +
                                    rente.rente85nachHöher)))
                        }
                        displayType={"text"}
                        thousandSeparator="."
                        decimalSeparator=","
                        suffix={" €"}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row className="pl-2">
                    <Col>(ggf. erhöhter) Rest-Versorgungsbezug</Col>
                    <Col>
                      <NumberFormat
                        decimalScale="2"
                        fixedDecimalScale={true}
                        value={
                          versB.versBezugAfterMin -
                          -1 * ruhe -
                          (versB.versBezugAfterMin - versB.versBezugAfterFz) +
                          (versB.versBezugAfterMin -
                            (versB.versBezugAfterMin -
                              -1 * ruhe -
                              (versB.versBezugAfterMin -
                                versB.versBezugAfterFz) +
                              rente.rente85nachHöher))
                        }
                        displayType={"text"}
                        thousandSeparator="."
                        decimalSeparator=","
                        suffix={" €"}
                      />
                    </Col>
                  </Row>
                  <Row className="pl-2">
                    <Col>zuzüglich Aufstockungsbetrag</Col>
                    <Col>
                      <NumberFormat
                        decimalScale="2"
                        fixedDecimalScale={true}
                        value={
                          versB.versBezugAfterFz -
                            (versB.versBezugAfterMin -
                              -1 * ruhe -
                              (versB.versBezugAfterMin -
                                versB.versBezugAfterFz) +
                              (versB.versBezugAfterMin -
                                (versB.versBezugAfterMin -
                                  -1 * ruhe -
                                  (versB.versBezugAfterMin -
                                    versB.versBezugAfterFz) +
                                  rente.rente85nachHöher))) <
                          0
                            ? 0
                            : versB.versBezugAfterFz -
                              (versB.versBezugAfterMin -
                                -1 * ruhe -
                                (versB.versBezugAfterMin -
                                  versB.versBezugAfterFz) +
                                (versB.versBezugAfterMin -
                                  (versB.versBezugAfterMin -
                                    -1 * ruhe -
                                    (versB.versBezugAfterMin -
                                      versB.versBezugAfterFz) +
                                    rente.rente85nachHöher)))
                        }
                        displayType={"text"}
                        thousandSeparator="."
                        decimalSeparator=","
                        suffix={" €"}
                      />
                    </Col>
                  </Row>
                  <Row className="pl-2 border-top font-weight-bold">
                    <Col>Zahlbetrag</Col>
                    <Col>
                      <NumberFormat
                        decimalScale="2"
                        fixedDecimalScale={true}
                        value={
                          versB.versBezugAfterMin -
                          -1 * ruhe -
                          (versB.versBezugAfterMin - versB.versBezugAfterFz) +
                          (versB.versBezugAfterMin -
                            (versB.versBezugAfterMin -
                              -1 * ruhe -
                              (versB.versBezugAfterMin -
                                versB.versBezugAfterFz) +
                              rente.rente85nachHöher)) +
                          (versB.versBezugAfterFz -
                            (versB.versBezugAfterMin -
                              -1 * ruhe -
                              (versB.versBezugAfterMin -
                                versB.versBezugAfterFz) +
                              (versB.versBezugAfterMin -
                                (versB.versBezugAfterMin -
                                  -1 * ruhe -
                                  (versB.versBezugAfterMin -
                                    versB.versBezugAfterFz) +
                                  rente.rente85nachHöher))) <
                          0
                            ? 0
                            : versB.versBezugAfterFz -
                              (versB.versBezugAfterMin -
                                -1 * ruhe -
                                (versB.versBezugAfterMin -
                                  versB.versBezugAfterFz) +
                                (versB.versBezugAfterMin -
                                  (versB.versBezugAfterMin -
                                    -1 * ruhe -
                                    (versB.versBezugAfterMin -
                                      versB.versBezugAfterFz) +
                                    rente.rente85nachHöher))))
                        }
                        displayType={"text"}
                        thousandSeparator="."
                        decimalSeparator=","
                        suffix={" €"}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      Nach Art. 85 BayBeamtVG geregelter Versorgungsbezug
                    </Col>
                    <Col>
                      <NumberFormat
                        fixedDecimalScale={true}
                        decimalScale="2"
                        value={versB.versBezugAfterMin - -1 * ruhe}
                        displayType={"text"}
                        thousandSeparator="."
                        decimalSeparator=","
                        suffix={" €"}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>abzügl. geregelter Zahlbetrag</Col>
                    <Col>
                      <NumberFormat
                        decimalScale="2"
                        fixedDecimalScale={true}
                        value={
                          versB.versBezugAfterMin -
                          -1 * ruhe -
                          (versB.versBezugAfterMin - versB.versBezugAfterFz) +
                          (versB.versBezugAfterMin -
                            (versB.versBezugAfterMin -
                              -1 * ruhe -
                              (versB.versBezugAfterMin -
                                versB.versBezugAfterFz) +
                              rente.rente85nachHöher)) +
                          (versB.versBezugAfterFz -
                            (versB.versBezugAfterMin -
                              -1 * ruhe -
                              (versB.versBezugAfterMin -
                                versB.versBezugAfterFz) +
                              (versB.versBezugAfterMin -
                                (versB.versBezugAfterMin -
                                  -1 * ruhe -
                                  (versB.versBezugAfterMin -
                                    versB.versBezugAfterFz) +
                                  rente.rente85nachHöher))) <
                          0
                            ? 0
                            : versB.versBezugAfterFz -
                              (versB.versBezugAfterMin -
                                -1 * ruhe -
                                (versB.versBezugAfterMin -
                                  versB.versBezugAfterFz) +
                                (versB.versBezugAfterMin -
                                  (versB.versBezugAfterMin -
                                    -1 * ruhe -
                                    (versB.versBezugAfterMin -
                                      versB.versBezugAfterFz) +
                                    rente.rente85nachHöher))))
                        }
                        displayType={"text"}
                        thousandSeparator="."
                        decimalSeparator=","
                        suffix={" €"}
                      />
                    </Col>
                  </Row>
                  <Row className="border-top font-weight-bold">
                    <Col>weiterer Kürzungsbetrag</Col>
                    <Col>
                      <NumberFormat
                        decimalScale="2"
                        fixedDecimalScale={true}
                        value={returnArt26Abs6}
                        displayType={"text"}
                        decimalSeparator=","
                        suffix={" €"}
                      />
                    </Col>
                  </Row>
                </div>
              )}
            </div>
          </div>
        )
      }
    </div>
  );
};

export default Hgr85;
