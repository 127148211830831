import { Text, View } from "@react-pdf/renderer";
import React from "react";

const Rechtsbehelf = (props) => {
  return (
    <View
      style={{
        border: "1px solid black",
        paddingLeft: "10px",
        paddingRight: "10px",
        paddingBottom: "10px",
        marginTop: "5.63mm",
      }}
    >
      <Text
        style={{
          textAlign: "center",
          marginTop: "2mm",
          marginBottom: "5.63mm",
        }}
      >
        Rechtsbehelfsbelehrung
      </Text>
      <Text>
        Gegen diesen Bescheid kann innerhalb eines Monats nach seiner
        Bekanntgabe Widerspruch erhoben werden. Der Widerspruch ist schrift-
        lich oder zur Niederschrift beim Landeskirchenamt in 80333 München,
        Katharina-von-Bora-Str. 5-13, einzulegen.
      </Text>
      <Text>
        Sollte das Landeskirchenamt über den Widerspruch binnen drei Monaten
        sachlich nicht entschieden haben, so kann Klage beim Bayerischen
        Verwaltungsgericht in {props.gericht.pf_plz} {props.gericht.ort},{" "}
        {props.gericht.postfach}, schriftlich oder zur Niederschrift des
        Urkundsbeamten der Geschäftsstelle erhoben werden. Die Klage muss den
        Kläger, den Beklagten (Evang.-Luth. Kirche in Bayern) und den
        Streitgegenstand bezeichnen und soll einen bestimmten Antrag enthalten.
        Die zur Begründung dienenden Tatsachen und Beweismittel sollen
        angegeben, der angefochtene Bescheid soll in Urschrift oder Abschrift
        beigefügt werden. Der Klage und allen Schriftsätzen sollen Abschriften
        für die übrigen Beteiligten beigefügt werden.
      </Text>
    </View>
  );
};

export default Rechtsbehelf;
