export const besA = [
    3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, "14A", 15, 16
];

export const besB = [
    3, 6, 9
];

export const besC = [
    1, 2, 3, 4
];

export const besP = [
    1, 2
]

export const besW = [
    1, 2, 3
]

export const stufenA = [
    {gruppe:"03", stufe:[2,3,4,5,6,7,8,9]},
    {gruppe:"04", stufe:[2,3,4,5,6,7,8,9]},
    {gruppe:"05", stufe:[2,3,4,5,6,7,8,9]},
    {gruppe:"06", stufe:[2,3,4,5,6,7,8,9]},
    {gruppe:"07", stufe:[2,3,4,5,6,7,8,9]},
    {gruppe:"08", stufe:[2,3,4,5,6,7,8,9,10]},
    {gruppe:"09", stufe:[2,3,4,5,6,7,8,9,10]},
    {gruppe:"10", stufe:[2,3,4,5,6,7,8,9,10]},
    {gruppe:"11", stufe:[3,4,5,6,7,8,9,10,11]},
    {gruppe:"12", stufe:[4,5,6,7,8,9,10,11]},
    {gruppe:"13", stufe:[5,6,7,8,9,10,11]},
    {gruppe:"14", stufe:[5,6,7,8,9,10,11]},
    {gruppe:"14A", stufe:[5,6,7,8,9,10,11]},
    {gruppe:"15", stufe:[6,7,8,9,10,11]},
    {gruppe:"16", stufe:[6,7,8,9,10,11]},
]

export const stufenB = [
    {gruppe:"03", stufe:[1]},
    {gruppe:"06", stufe:[1]},
    {gruppe:"09", stufe:[1]}
]

export const stufenC = [
    {gruppe:"01", stufe:[1,2,3,4,5,6,7,8,9,10,11,12,13,14]},
    {gruppe:"02", stufe:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]},
    {gruppe:"03", stufe:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]},
    {gruppe:"04", stufe:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]},
]

export const stufenP = [
    {gruppe:"01", stufe:[5,6,7,8,9,10,11]},
    {gruppe:"02", stufe:[5,6,7,8,9,10,11]}
]

export const stufenW = [
    {gruppe:"01", stufe:[1]},
    {gruppe:"02", stufe:[1,2,3]},
    {gruppe:"03", stufe:[1,2,3]},
]