import React from "react";
import { Text, View, Svg, Rect } from "@react-pdf/renderer";

const Head = (props) => {
  return (
    <View>
      <Text
        style={{
          paddingLeft: "19mm",
          fontVariant: "small-caps",
          fontSize: "20pt",
        }}
      >
        EVANGELISCH-LUTHERISCHE KIRCHE IN BAYERN
      </Text>
      <Text style={{ paddingLeft: "19mm", fontSize: "20pt" }}>
        DER LANDESKIRCHENRAT -{" "}
        <Text style={{ fontSize: "14pt" }}>Landeskirchenamt</Text>
      </Text>
      <View style={{ flexDirection: "row" }}>
        <View>
          <Text
            style={{
              fontSize: "8pt",
              paddingTop: "20mm",
              textDecoration: "underline",
              paddingLeft: "19mm",
            }}
          >
            Landeskirchenamt - Postfach 20 07 51 - 80007 München
          </Text>
          <Text style={{ fontSize: "8pt", paddingLeft: "77mm" }}>6000</Text>
        </View>
        {props.status === "Entwurf" && (
          <View
            style={{
              border: "1px solid black",
              marginLeft: "18%",
              marginTop: "7mm",
              padding: "10px",
            }}
          >
            <Text>Befördert am ____________</Text>
            <Text
              style={{
                marginTop: "10px",
                paddingLeft: "30px",
                letterSpacing: "3",
                fontSize: "14pt",
              }}
            >
              ENTWURF
            </Text>
          </View>
        )}
      </View>

      <View style={{ flexGrow: 1, flexDirection: "row" }}>
        <View
          style={{
            textAlign: "justify",
            width: "55%",
            marginTop: "5mm",
            fontSize: "11pt",
          }}
        >
          <Text
            style={{
              paddingLeft: props.status === "Entwurf" ? "15mm" : "19mm",
            }}
          >
            {props.status === "Entwurf" && "I.  "}
            {props.person.geschl === 1 ? "Herrn " : "Frau "}
            {props.person.titel === 1
              ? "Dr."
              : props.person.titel === 2
              ? "Prof."
              : props.person.titel === 3
              ? "Prof. Dr."
              : null}
          </Text>
          <Text style={{ paddingLeft: "19mm" }}>
            {props.person.vorname + " " + props.person.nachname}
          </Text>
          <Text style={{ paddingLeft: "19mm" }}>
            {props.anschreiben.strasse + " " + props.anschreiben.nummer}
          </Text>
          <Text style={{ paddingLeft: "19mm" }}>
            {props.anschreiben.plz + " " + props.anschreiben.ort}
          </Text>
        </View>
        <View style={{ flexGrow: 2 }}>
          <Text style={{ marginTop: "7mm" }}>Auskunft bei Herrn Baumgartl</Text>
          <Text>Telefon</Text>
          <Text>Fax</Text>
          <Text>Email</Text>
        </View>
      </View>
      <View style={{ paddingLeft: "19mm", flexGrow: 1, flexDirection: "row" }}>
        <View
          style={{
            textAlign: "justify",
            width: "60%",
            fontSize: "11pt",
            paddingTop: "28mm",
            paddingBottom: "11mm",
            fontSize: "11pt",
          }}
        >
          <Text>Az.: {" " + props.anschreiben.az}</Text>
        </View>
        <View
          style={{
            flexGrow: 2,
            fontSize: "11pt",
            paddingTop: "28mm",
          }}
        >
          <Text>München, {props.datum}</Text>
        </View>
      </View>
      <View style={{ paddingLeft: "19mm" }}>
        <Text style={{ fontSize: "11pt", fontWeight: 700 }}>
          {props.betreff1}
        </Text>
        <Text style={{ fontWeight: 700 }}>{props.betreff2}</Text>
      </View>
      <Text
        style={{
          paddingLeft: "19mm",
          marginTop: "11mm",
          marginBottom: "0.56cm",
        }}
      >
        {props.person.geschl === 1
          ? "Sehr geehrter Herr " + props.person.nachname + ","
          : "Sehr geehrte Frau " + props.person.nachname + ","}
      </Text>
      {/*Kästchen*/}
      <Svg style={{ left: "515", top: "4", position: "absolute" }}>
        <Rect
          stroke="black"
          strokeWidth={1}
          width="20"
          height="20"
          fillOpacity={0}
        />
      </Svg>
      <Svg style={{ left: "515", top: "34", position: "absolute" }}>
        <Rect
          stroke="black"
          strokeWidth={1}
          width="20"
          height="40"
          fillOpacity={0}
        />
      </Svg>
      <Svg style={{ left: "545", top: "4", position: "absolute" }}>
        <Rect
          stroke="black"
          strokeWidth={1}
          width="20"
          height="20"
          fillOpacity={0}
        />
      </Svg>
    </View>
  );
};

export default Head;
