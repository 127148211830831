import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { calc_regel } from "./helper/functions";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const Head = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.person.data);
  const detail = useSelector((state) => state.detail.data);
  const view = useSelector((state) => state.Vorgang.view);

  const handleDataChange = (e) => {
    if (e.target.name === "birthDate") {
      const regel = calc_regel(e.target.value);
      dispatch({
        type: "SET_DATA",
        payload: { ...data, birthDate: e.target.value, regel: regel },
      });
      dispatch({ type: "SET_DETAIL", payload: { ...detail, beg: regel } });
    } else if (e.target.name === "fzK") {
      dispatch({ type: "SET_DATA", payload: { ...data, fzK: e.target.value } });
    } else if (e.target.name === "GdB") {
      dispatch({ type: "SET_DATA", payload: { ...data, GdB: !data.GdB } });
    } else if (e.target.name === "geschl") {
      dispatch({
        type: "SET_DATA",
        payload: { ...data, geschl: +e.target.value },
      });
    } else {
      dispatch({
        type: "SET_DATA",
        payload: { ...data, [e.target.name]: e.target.value },
      });
    }
  };

  useEffect(() => {
    if (detail.beg === "") {
      const regel = calc_regel(data.birthDate);
      dispatch({ type: "SET_DETAIL", payload: { ...detail, beg: regel } });
    }
  }, []);
  return (
    <div>
      <Row className="mt-2">
        <Col>
          <TextField
            disabled={true}
            id="outlined-error-helper-text"
            label="Personalnummer"
            name="id"
            value={data.id}
            onChange={handleDataChange}
            variant="outlined"
          />
        </Col>
      </Row>
      <Row>
        <Col className="mt-2">
          <FormControl variant="outlined">
            <InputLabel>Titel</InputLabel>
            <Select
              disabled={view}
              native
              label="Titel"
              name="titel"
              value={data.titel}
              onChange={handleDataChange}
            >
              <option aria-label="None" value="" />
              <option value={1}>Dr.</option>
              <option value={2}>Prof.</option>
              <option value={3}>Prof. Dr.</option>
            </Select>
          </FormControl>
        </Col>

        <Col className="mt-2">
          <TextField
            disabled={view}
            error={!data.vorname ? true : false}
            id="outlined-basic"
            label="Vorname"
            variant="outlined"
            required
            name="vorname"
            value={data.vorname}
            onChange={handleDataChange}
          />
        </Col>

        <Col className="mt-2">
          <TextField
            disabled={view}
            error={!data.nachname ? true : false}
            id="outlined-basic"
            label="Nachname"
            variant="outlined"
            required
            name="nachname"
            value={data.nachname}
            onChange={handleDataChange}
          />
        </Col>
        <Col className="mt-2">
          <TextField
            disabled={view}
            error={!data.birthDate ? true : false}
            variant="outlined"
            required
            id="date"
            label="Geburtsdatum"
            type="date"
            name="birthDate"
            defaultValue={data.birthDate}
            onChange={handleDataChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Col>
        <Col className="mt-2">
          <FormControl
            disabled={view}
            required
            style={{ minWidth: "140px" }}
            variant="outlined"
          >
            <InputLabel>Geschlecht</InputLabel>
            <Select
              disabled={view}
              error={!data.geschl ? true : false}
              native
              label="Geschlecht"
              name="geschl"
              value={data.geschl}
              onChange={handleDataChange}
            >
              <option aria-label="None" value="" />
              <option value={1}>M</option>
              <option value={2}>W</option>
            </Select>
          </FormControl>
        </Col>
      </Row>
      <Row>
        <Col className="mt-2">
          <FormControl
            disabled={view}
            required
            style={{ minWidth: "160px" }}
            variant="outlined"
          >
            <InputLabel>Familienstand</InputLabel>
            <Select
              disabled={view}
              error={data.fam === "" ? true : false}
              native
              label="familienstand"
              name="fam"
              value={data.fam}
              onChange={handleDataChange}
            >
              <option aria-label="None" value="" />
              <option value={0}>ledig</option>
              <option value={1}>verh.</option>
              <option value={2}>verwitwet</option>
              <option value={3}>geschieden</option>
              <option value={5}>getr. lebend</option>
              <option value={9}>reg. Partnerschaft</option>
            </Select>
          </FormControl>
        </Col>

        <Col className="mt-2">
          <FormControl
            disabled={view}
            required
            style={{ minWidth: "160px" }}
            variant="outlined"
          >
            <InputLabel>Steuerklasse</InputLabel>
            <Select
              disabled={view}
              error={!data.stkl ? true : false}
              native
              label="Steuerklasse"
              name="stkl"
              value={data.stkl}
              onChange={handleDataChange}
            >
              <option aria-label="None" value="" />
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
            </Select>
          </FormControl>
        </Col>

        <Col className="mt-2">
          <TextField
            disabled={view}
            id="outlined-error-helper-text"
            label="Familienzuschlag Stufe 1 (%)"
            name="fzPer"
            value={data.fzPer}
            onChange={handleDataChange}
            variant="outlined"
            error={data.fzPer > 100}
          />
        </Col>

        <Col className="mt-2">
          <TextField
            disabled={view}
            id="outlined-error-helper-text"
            label="Familienzuschlag (Kind)"
            name="fzK"
            value={data.fzK}
            onChange={handleDataChange}
            variant="outlined"
          />
        </Col>

        <Col>
          <FormControlLabel
            control={
              <Checkbox
                disabled={view}
                color="primary"
                checked={data.GdB}
                onChange={handleDataChange}
                name="GdB"
              />
            }
            label="Schwerbehinderung?"
          />
        </Col>
      </Row>
    </div>
  );
};

export default Head;
