import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";

import person from "./reducers/person";
import detail from "./reducers/detail";
import rente from "./reducers/rente";
import bezug from "./reducers/bezug";
import va from "./reducers/va";
import Zeiten from "./reducers/zeiten";
import versB from "./reducers/versB";
import HGR83 from "./reducers/hgr83";
import HGR85 from "./reducers/hgr85";
import Anschreiben from "./reducers/anschreiben";
import Vorgang from "./reducers/vorgang";
import vorgaenge from "./reducers/vorgaenge";
import diffBez from "./reducers/diffBezug";

export default combineReducers({
  person,
  detail,
  rente,
  bezug,
  va,
  Zeiten,
  versB,
  HGR83,
  HGR85,
  Anschreiben,
  Vorgang,
  vorgaenge,
  diffBez,
  router: routerReducer,
});
