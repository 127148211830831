import React from "react";

import Button from "react-bootstrap/Button";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Search from "./search";
import { convCreator, convStatus } from "./helper/functions";
import moment from "moment";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";

const Start = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const routeChange = (newPath) => {
    history.push(newPath);
  };

  const person = useSelector((state) => state.person.data);
  const vorgaenge = useSelector((state) => state.vorgaenge.data);

  const setData = async (id) => {
    const response = await fetch(
      //`http://localhost/versorgung/rest-api/other.php?typ=load&v=other`,
      `https://versorgung.hosting142616.a2e76.netcup.net/rest-api/other.php?typ=load&v=other`,
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify({ id: id }),
      }
    );
    if (response) {
      const fetch = await response.json();
      dispatch({ type: "SET_DATA", payload: fetch.person });
      dispatch({ type: "SET_DETAIL", payload: fetch.detail });
      dispatch({ type: "SET_RENTE", payload: fetch.rente });
      dispatch({ type: "SET_VA_INITIAL", payload: fetch.va });
      dispatch({ type: "SET_BEZUG", payload: fetch.bezug });
      dispatch({ type: "SET_VERSB", payload: fetch.versb });
      dispatch({ type: "SET_HGR85", payload: fetch.hgr85 });
      dispatch({ type: "SET_ANSCHREIBEN", payload: fetch.anschreiben });

      dispatch({ type: "SET_MISCH_NEEDED", payload: fetch.zeiten.mischNeeded });
      dispatch({ type: "SET_ANZAHL_ZEITEN", payload: fetch.zeiten.anzZei });
      dispatch({ type: "SET_GESAMTZEIT", payload: fetch.zeiten.gesamtzeit });
      dispatch({ type: "SET_ZEIT", payload: fetch.zeiten.zeiten });
      dispatch({ type: "SET_ZEIT21", payload: fetch.zeiten.zeiten21 });
      dispatch({ type: "SET_ZEIT27", payload: fetch.zeiten.zeiten27 });
      dispatch({ type: "SET_ZEIT21HGR", payload: fetch.zeiten.zeiten21HGR });
      dispatch({ type: "SET_ZEIT27HGR", payload: fetch.zeiten.zeiten27HGR });
      dispatch({ type: "SET_ZEITEN_BOOL", payload: fetch.zeiten.zeitenBool });
      dispatch({
        type: "SET_ZEITEN_CHANGE",
        payload: fetch.zeiten.zeitenChange,
      });

      dispatch({
        type: "SET_VORGANG",
        payload: { id: id, created: fetch.created },
      });
    }
  };

  const handleEdit = async (id) => {
    await setData(id);
    routeChange("festsetzung");
  };

  const handleView = async (id) => {
    await setData(id);
    dispatch({ type: "SET_VIEW" });
    routeChange("festsetzung");
  };

  return (
    <div>
      <Row>
        <Col>
          Suche:
          <Search />
        </Col>
        <Col>
          Aktuell gewählte Person
          {person.id ? (
            <div>
              {person.id} - {person.nachname + " ," + person.vorname}
            </div>
          ) : (
            <div> keine </div>
          )}
        </Col>
      </Row>
      {vorgaenge && (
        <div>
          {Object.keys(vorgaenge)
            .filter((key) => vorgaenge[key].type === 1)
            .map((vorgang, id) => {
              return (
                <div key={id}>
                  {id === 0 && (
                    <div>
                      <div className="h3 pb-1 border-bottom border-dark">
                        Auskunft
                      </div>
                      <Row>
                        <Col>ID</Col>
                        <Col>Status</Col>
                        <Col>Erstellt</Col>
                        <Col>Von</Col>
                        <Col>Geändert</Col>
                        <Col>Von</Col>
                        <Col>Aktion</Col>
                      </Row>
                    </div>
                  )}

                  <Row>
                    <Col>{vorgaenge[vorgang].id}</Col>
                    <Col>{convStatus(vorgaenge[vorgang].status)} </Col>
                    <Col>
                      {moment(vorgaenge[vorgang].created, "X").format(
                        "DD.MM.YY"
                      )}{" "}
                    </Col>
                    <Col>{convCreator(vorgaenge[vorgang].created_from)} </Col>
                    <Col>
                      {moment(vorgaenge[vorgang].modified, "X").format(
                        "DD.MM.YY"
                      )}{" "}
                    </Col>
                    <Col>{convCreator(vorgaenge[vorgang].modified_from)}</Col>
                    <Col>
                      <EditIcon
                        style={{
                          cursor: vorgaenge[vorgang].status === 1 && "pointer",
                        }}
                        color={
                          vorgaenge[vorgang].status === 1
                            ? "inherit"
                            : "disabled"
                        }
                        onClick={() => {
                          vorgaenge[vorgang].status === 1 &&
                            handleEdit(vorgaenge[vorgang].id);
                        }}
                      />
                      <VisibilityIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleView(vorgaenge[vorgang].id);
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              );
            })}
          {Object.keys(vorgaenge)
            .filter((key) => vorgaenge[key].type === 2)
            .map((vorgang, id) => {
              return (
                <div key={id}>
                  {id === 0 && (
                    <div>
                      <div className="h3 pb-1 border-bottom border-dark">
                        Festsetzung
                      </div>
                      <Row>
                        <Col>ID</Col>
                        <Col>Status</Col>
                        <Col>Erstellt</Col>
                        <Col>Von</Col>
                        <Col>Geändert</Col>
                        <Col>Von</Col>
                        <Col>Aktion</Col>
                      </Row>
                    </div>
                  )}

                  <Row>
                    <Col>{vorgaenge[vorgang].id}</Col>
                    <Col>{convStatus(vorgaenge[vorgang].status)} </Col>
                    <Col>
                      {moment(vorgaenge[vorgang].created, "X").format(
                        "DD.MM.YY"
                      )}{" "}
                    </Col>
                    <Col>{convCreator(vorgaenge[vorgang].created_from)} </Col>
                    <Col>
                      {moment(vorgaenge[vorgang].modified, "X").format(
                        "DD.MM.YY"
                      )}{" "}
                    </Col>
                    <Col>{convCreator(vorgaenge[vorgang].modified_from)}</Col>
                    <Col>
                      {
                        <EditIcon
                          style={{
                            cursor:
                              vorgaenge[vorgang].status === 1 && "pointer",
                          }}
                          color={
                            vorgaenge[vorgang].status === 1
                              ? "primary"
                              : "disabled"
                          }
                          onClick={() => {
                            vorgaenge[vorgang].status === 1 &&
                              handleEdit(vorgaenge[vorgang].id);
                          }}
                        />
                      }
                    </Col>
                  </Row>
                </div>
              );
            })}
        </div>
      )}
      {person.id !== 0 && (
        <div className="h3 mt-2 pb-1">Einen neuen Vorgang anlegen</div>
      )}
      {person.id !== 0 && (
        <Row>
          <Col className="pb-1" md="auto">
            <Button variant="outline-primary" size="lg" block>
              Auskunft
            </Button>{" "}
          </Col>
          <Col className="pb-1" md="auto">
            <Button
              variant="outline-secondary"
              size="lg"
              block
              onClick={() => routeChange("festsetzung")}
            >
              Festsetzung
            </Button>{" "}
          </Col>
          <Col className="pb-1" md="auto">
            <Button variant="outline-success" size="lg" block>
              Versorgungsausgleich
            </Button>{" "}
          </Col>
          <Col md="auto">
            <Button
              variant="outline-warning"
              size="lg"
              block
              onClick={() => routeChange("anrechnung")}
            >
              Anrechenbare Zahlungen
            </Button>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <Button
            onClick={() => routeChange("stabgz")}
            variant="outline-primary"
            size="lg"
            block
          >
            StAbgZ
          </Button>
          <Button
            onClick={() => routeChange("mindMap")}
            variant="outline-primary"
            size="lg"
            block
          >
            MindMap
          </Button>
          <Button
            onClick={() => routeChange("umlage")}
            variant="outline-primary"
            size="lg"
            block
          >
            Umlage
          </Button>
          <Button
            onClick={() => routeChange("sz")}
            variant="outline-primary"
            size="lg"
            block
          >
            Sonderzahlung
          </Button>
          <Button
            onClick={() => routeChange("fz")}
            variant="outline-primary"
            size="lg"
            block
          >
            Familienzuschlag
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default Start;
