import { SET_HGR83, ADD_HGR83 } from "../constants/actionTypes";

const initialState = {
  hgr83_1: {
    ruhe83: 0,
    arbeitg: "",
    art: "1",
    erh: 0,
    mindest: true,
    bezugsArt: "monatlich",
    beg: "0000-00-00",
    end: "0000-00-00",
    einkommen: 0,
  },
  count: 1,
};

const HGR83 = (state = initialState, action) => {
  switch (action.type) {
    case SET_HGR83:
      return {
        ...state,
        [action.hgr83]: action.payload,
      };
    case ADD_HGR83:
      return {
        ...state,
        ["hgr83_" + (state.count + 1)]: {
          ruhe83: 0,
          arbeitg: "",
          art: "1",
          erh: 0,
          mindest: true,
          bezugsArt: "monatlich",
          beg: "0000-00-00",
          end: "0000-00-00",
          einkommen: 0,
        },
        count: state.count + 1,
      };
    default:
      return state;
  }
};

export default HGR83;
