import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { dienstzeiten } from "./helper/dienstzeiten";
import TextField from "@material-ui/core/TextField";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";
import { calcDateDiff } from "./helper/functions";

const Zeiten = () => {
  const dispatch = useDispatch();
  const zeiten = useSelector((state) => state.Zeiten.zeiten);
  const zeiten21 = useSelector((state) => state.Zeiten.zeiten21);
  const zeiten27 = useSelector((state) => state.Zeiten.zeiten27);
  const zeitenBool = useSelector((state) => state.Zeiten.zeitenBool);
  const zeitenChange = useSelector((state) => state.Zeiten.zeitenChange);
  const iteration = useSelector((state) => state.Zeiten.anzZei);
  const detail = useSelector((state) => state.detail.data);
  const inputElements = [];
  const view = useSelector((state) => state.Vorgang.view);

  const handleClickAdd = () => {
    dispatch({ type: "SET_ANZ_ZEITEN" });
  };

  const handleZeitenChange = (e) => {
    //trigeres useEffect to calcDateDiff
    dispatch({ type: "SET_ZEITEN_BOOL" });
    //set to the last edited zeit
    dispatch({ type: "SET_ZEITEN_CHANGE", payload: e.target.id });

    dispatch({
      type: "SET_ZEIT",
      payload: {
        ...zeiten,
        [e.target.id]: {
          ...zeiten[e.target.id],
          [e.target.name]: e.target.value,
        },
      },
    });
  };

  const handleZeitenDelete = (id) => {
    for (let index = 1; index <= Object.keys(zeiten).length; index++) {
      if (index === id) {
        for (let index2 = 1; index2 < Object.keys(zeiten).length; index2++) {
          //es gibt eine weitere Zeit
          if (zeiten[`z${index + index2}`]) {
            const zwischenSpeicher = zeiten[`z${index + index2}`];
            delete zeiten[`z${index + index2}`];
            dispatch({
              type: "SET_ZEIT",
              payload: {
                ...zeiten,
                [`z${index}`]: zwischenSpeicher,
              },
            });
          } else {
            delete zeiten[`z${index}`];
            dispatch({
              type: "SET_ZEIT",
              payload: {
                ...zeiten,
              },
            });
          }
        }
      }
    }
    dispatch({ type: "SET_ANZ_ZEITEN_MINUS" });
  };

  useEffect(() => {
    if (zeitenChange) {
      const rgfZ = calcDateDiff(zeiten[zeitenChange]);
      const rgfZ21 = calcDateDiff(zeiten21[zeitenChange]);
      const rgfZ27 = calcDateDiff(zeiten27[zeitenChange]);

      //ungefiltert
      dispatch({
        type: "SET_ZEIT",
        payload: {
          ...zeiten,
          [zeitenChange]: rgfZ,
          [zeitenChange]: {
            ...zeiten[zeitenChange],
            rgfD: { jahre: rgfZ[0], tage: rgfZ[1] },
          },
        },
      });

      //filter 21
      dispatch({
        type: "SET_ZEIT21",
        payload: {
          ...zeiten21,
          [zeitenChange]: rgfZ21,
          [zeitenChange]: {
            ...zeiten21[zeitenChange],
            rgfD: { jahre: rgfZ21[0], tage: rgfZ21[1] },
          },
        },
      });

      if (detail.begBeamt < "1992-01-01") {
        //filter27
        dispatch({
          type: "SET_ZEIT27",
          payload: {
            ...zeiten27,
            [zeitenChange]: rgfZ27,
            [zeitenChange]: {
              ...zeiten27[zeitenChange],
              rgfD: { jahre: rgfZ27[0], tage: rgfZ27[1] },
            },
          },
        });
      }
    }
  }, [zeitenChange, zeitenBool]);

  for (let index = 0; index + 1 < iteration; index++) {
    //underline the last element (iteration-index) === 1
    const style_underline =
      iteration - index === 1
        ? "col-auto align-self-end border-bottom border-dark"
        : "col-auto align-self-end";

    inputElements.push(
      <div className="row align-items-end" key={index}>
        <div className="col-auto">
          <TextField
            disabled={view}
            id={`z${index + 2}`}
            name="ze"
            select
            label="Dienstzeit"
            value={
              zeiten[`z${index + 2}`] && zeiten[`z${index + 2}`].ze
                ? zeiten[`z${index + 2}`].ze
                : 0
            }
            onChange={handleZeitenChange}
            SelectProps={{
              native: true,
            }}
            variant="standard"
          >
            <option key={0} value={0}>
              bitte wählen
            </option>
            {dienstzeiten.map(
              (option) =>
                option.value !== "Z042" && (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                )
            )}
          </TextField>
        </div>
        <div className="col">
          <TextField
            disabled={view}
            id={`z${index + 2}`}
            name="begDa"
            label="von"
            type="date"
            value={zeiten[`z${index + 2}`] ? zeiten[`z${index + 2}`].begDa : ""}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleZeitenChange}
          />
        </div>
        <div className="col">
          <TextField
            disabled={view}
            id={`z${index + 2}`}
            name="endDa"
            label="bis"
            type="date"
            error={
              zeiten[`z${index + 2}`] &&
              zeiten[`z${index + 2}`].endDa < zeiten[`z${index + 2}`].begDa
                ? true
                : false
            }
            helperText={
              zeiten[`z${index + 2}`] &&
              zeiten[`z${index + 2}`].endDa < zeiten[`z${index + 2}`].begDa &&
              "Ende < Anfang"
            }
            value={zeiten[`z${index + 2}`] ? zeiten[`z${index + 2}`].endDa : ""}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleZeitenChange}
          />
        </div>
        <div className="col">
          <TextField
            disabled={view}
            id={`z${index + 2}`}
            label="zähler"
            name="za"
            type="number"
            value={zeiten[`z${index + 2}`] ? zeiten[`z${index + 2}`].za : ""}
            onChange={handleZeitenChange}
          />
        </div>
        <div className="col">
          <TextField
            disabled={view}
            name="n"
            id={`z${index + 2}`}
            label="nenner"
            type="number"
            value={zeiten[`z${index + 2}`] ? zeiten[`z${index + 2}`].n : ""}
            onChange={handleZeitenChange}
          />
        </div>
        <div className={style_underline}>
          {zeiten[`z${index + 2}`] && zeiten[`z${index + 2}`].rgfD
            ? zeiten[`z${index + 2}`].rgfD.jahre + " Jahre"
            : "invalid"}
        </div>
        <div className={style_underline}>
          {zeiten[`z${index + 2}`] && zeiten[`z${index + 2}`].rgfD
            ? zeiten[`z${index + 2}`].rgfD.tage + " Tage"
            : "invalid"}
        </div>
        <div className="col-auto">
          <RemoveCircleOutlineOutlinedIcon
            style={{ cursor: "pointer" }}
            color={view !== true ? "inherit" : "disabled"}
            onClick={() => {
              view !== true && handleZeitenDelete(index + 2);
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="row align-items-end">
        <div className="col-auto">
          <TextField
            disabled={view}
            id="z1"
            name="ze"
            select
            label="Dienstzeit"
            value={zeiten["z1"].ze ? zeiten["z1"].ze : ""}
            onChange={handleZeitenChange}
            SelectProps={{
              native: true,
            }}
            variant="standard"
          >
            {dienstzeiten.map(
              (option) =>
                option.value !== "Z042" && (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                )
            )}
          </TextField>
        </div>
        <div className="col">
          <TextField
            disabled={view}
            id="z1"
            name="begDa"
            label="von"
            type="date"
            value={zeiten["z1"].begDa ? zeiten["z1"].begDa : ""}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleZeitenChange}
          />
        </div>
        <div className="col">
          <TextField
            disabled={view}
            error={zeiten["z1"].endDa < zeiten["z1"].begDa ? true : false}
            helperText={
              zeiten["z1"].endDa < zeiten["z1"].begDa && "Ende < Anfang"
            }
            id="z1"
            name="endDa"
            label="bis"
            type="date"
            value={zeiten["z1"].endDa ? zeiten["z1"].endDa : ""}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleZeitenChange}
          />
        </div>
        <div className="col">
          <TextField
            disabled={view}
            id="z1"
            label="zähler"
            name="za"
            type="number"
            value={zeiten["z1"] ? zeiten["z1"].za : ""}
            onChange={handleZeitenChange}
          />
        </div>
        <div className="col">
          <TextField
            disabled={view}
            name="n"
            id="z1"
            label="nenner"
            type="number"
            value={zeiten["z1"] ? zeiten["z1"].n : ""}
            onChange={handleZeitenChange}
          />
        </div>
        <div className="col-auto align-self-end">
          {zeiten.z1.rgfD.jahre + " Jahre"}
        </div>
        <div className="col-auto align-self-end">
          {zeiten.z1.rgfD.tage + " Tage"}
        </div>
        <div className="col-auto text-white">
          <RemoveCircleOutlineOutlinedIcon />
        </div>
      </div>
      {inputElements}
      <div className="align-items-center">
        <AddCircleOutlineOutlinedIcon
          style={{ cursor: "pointer" }}
          color={view !== true ? "inherit" : "disabled"}
          onClick={view !== true ? handleClickAdd : undefined}
        />
      </div>
    </div>
  );
};

export default Zeiten;
