import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Bezüge from "../bezuge";
import {
  calc_fz_ub,
  fetchBVG,
  getAbschlag,
  getAufschlag,
  getUnabMind,
  get_stva,
} from "../helper/functions";
import Hgr83 from "../hgr83";
import Hgr85 from "../hgr85";
import MischRecht from "../MischRecht";
import NeuesRecht from "../neuesRecht";
import Rente from "../rente";
import TabsNav from "../tabs";
import { TabPanel } from "../tabsPanel";
import VA from "../va";
import VersBezug from "../versBezuge";
import AnrechnungAllgemein from "./allgemein";

const Anrechnung = () => {
  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));
  const classes = useStyles();

  const [tabVal, setTabVal] = useState(0);
  const reduxZeiten = useSelector((state) => state.Zeiten);
  const detail = useSelector((state) => state.detail.data);
  const mischNeeded = useSelector((state) => state.Zeiten.mischNeeded);
  const data = useSelector((state) => state.person.data);
  const vaReducer = useSelector((state) => state.va);
  const va = vaReducer.va1;
  const bezug = useSelector((state) => state.bezug.data);
  const hgr85 = useSelector((state) => state.HGR85.data);
  const hgr83 = useSelector((state) => state.HGR83);
  const rente = useSelector((state) => state.rente.data);
  const versB = useSelector((state) => state.versB.data);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  //getting all these data from the last "Festsetzung"
  const setData = async () => {
    setLoading(true);
    const response = await fetch(
      //`http://localhost/versorgung/rest-api/other.php?typ=load&v=anrechnung`,
      `https://versorgung.hosting142616.a2e76.netcup.net/rest-api/other.php?typ=load&v=anrechnung`,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
      }
    );
    if (response) {
      const fetch = await response.json();
      setLoading(false);
      dispatch({ type: "SET_DATA", payload: fetch.person });
      dispatch({ type: "SET_DETAIL", payload: fetch.detail });
      dispatch({ type: "SET_RENTE", payload: fetch.rente });
      dispatch({ type: "SET_VA_INITIAL", payload: fetch.va });
      dispatch({ type: "SET_BEZUG", payload: fetch.bezug });
      dispatch({ type: "SET_VERSB", payload: fetch.versb });
      dispatch({ type: "SET_HGR85", payload: fetch.hgr85 });
      dispatch({ type: "SET_ANSCHREIBEN", payload: fetch.anschreiben });

      dispatch({ type: "SET_MISCH_NEEDED", payload: fetch.zeiten.mischNeeded });
      dispatch({ type: "SET_ANZAHL_ZEITEN", payload: fetch.zeiten.anzZei });
      dispatch({ type: "SET_GESAMTZEIT", payload: fetch.zeiten.gesamtzeit });
      dispatch({ type: "SET_ZEIT", payload: fetch.zeiten.zeiten });
      dispatch({ type: "SET_ZEIT21", payload: fetch.zeiten.zeiten21 });
      dispatch({ type: "SET_ZEIT27", payload: fetch.zeiten.zeiten27 });
      dispatch({ type: "SET_ZEIT21HGR", payload: fetch.zeiten.zeiten21HGR });
      dispatch({ type: "SET_ZEIT27HGR", payload: fetch.zeiten.zeiten27HGR });
      dispatch({ type: "SET_ZEITEN_BOOL", payload: fetch.zeiten.zeitenBool });
      dispatch({
        type: "SET_ZEITEN_CHANGE",
        payload: fetch.zeiten.zeitenChange,
      });
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabVal(newValue);
  };

  useEffect(() => {
    setData();
  }, []);

  useEffect(() => {
    //wenn zeitpunkt gewählt, besoldung setzten und rechnen
    if (versB.zeitpunkt !== "" && versB.zeitpunkt !== "0000-00-00") {
      let mindestversorgung = false;
      //Abschlag
      //Prüfung ob Abschlagsfrei nach 40/45 Jahren in der Funktion getAbschlag
      const abschlag = getAbschlag(data, detail, reduxZeiten.zeiten);
      const abschlagPro = abschlag[2] > abschlag[0] ? abschlag[0] : abschlag[2];
      const abschlagfreiJahre = abschlag[4] ? abschlag[4] : 0;
      let rgs =
        reduxZeiten.gesamtzeit.gesamtZ[4] >=
        reduxZeiten.gesamtzeit.gesamtZMisch[10]
          ? reduxZeiten.gesamtzeit.gesamtZ[4]
          : reduxZeiten.gesamtzeit.gesamtZMisch[10];

      const convManRGS = reduxZeiten.manRGS.replace(",", ".");
      if (+convManRGS > rgs) {
        rgs = +convManRGS;
      }

      const versBezug = reduxZeiten.gesamtzeit
        ? Math.round(((rgs * bezug.rgfB) / 100) * 100) / 100
        : 0;
      const abschlagEur =
        -Math.round(((versBezug * abschlagPro) / 100) * 100) / 100;

      //Aufschlag
      const aufschlag = getAufschlag(data, detail);
      const aufschlagEur =
        Math.round(((versBezug * aufschlag[1]) / 100) * 100) / 100;

      //Neuer Versorgungsbezug
      const versBezugAfterAbAuf =
        Math.round((versBezug + abschlagEur + aufschlagEur) * 100) / 100;

      //Familienzuschlag kind
      const newFzK = data.fzK.replace(",", ".");
      const fzK = calc_fz_ub(detail, newFzK);
      const versBezugAfterFz =
        Math.round((versBezugAfterAbAuf + fzK) * 100) / 100;

      //abgleich amtsunabh. Mindestversorgung
      const newFzPer = data.fzPer.replace(",", ".");
      const unabMind = getUnabMind(versB.zeitpunkt, newFzPer, newFzK);

      //abgleich amtsabhän. Mindestversorgung
      const abhMind = Math.round(bezug.rgfB * 0.35 * 100) / 100 + fzK;

      //überschreiben der versorgungsbezüge
      let versBezugAfterMin = 0;
      if (versBezugAfterFz <= unabMind && unabMind > abhMind) {
        versBezugAfterMin = unabMind;
        mindestversorgung = true;
      } else if (versBezugAfterFz <= abhMind && abhMind > unabMind) {
        versBezugAfterMin = abhMind;
        mindestversorgung = true;
      } else {
        versBezugAfterMin = versBezugAfterFz;
      }

      //VA
      const versBezugAfterVA =
        Math.round((versBezugAfterMin + va.kuerzung) * 100) / 100;

      //Unfallausgleich
      let unfallausgleich = 0;
      if (detail.grund === "5") {
        unfallausgleich = fetchBVG(versB.zeitpunkt, hgr85.mde);
      }
      const versBezugAfterUnfall =
        Math.round((versBezugAfterVA + unfallausgleich) * 100) / 100;

      //Stva
      let stva_eur = 0;
      let stva = 0;
      //wenn unabh. Mindestv. dann 0
      if (versBezugAfterMin === unabMind) {
        stva_eur = 0;
        stva = 0;
      } else {
        stva = get_stva(
          versB.zeitpunkt,
          detail.besO + detail.besG,
          data.stkl
        ).satz;
        stva_eur = -Math.round(((stva * versBezugAfterMin) / 100) * 100) / 100;
      }
      const versBezugAfterStva =
        Math.round((versBezugAfterUnfall + stva_eur) * 100) / 100;

      //Rente-VNG
      const versBezugAfterVNGRente =
        Math.round((versBezugAfterStva - rente.renteVNGnachHöher) * 100) / 100;

      //Rente-85
      const versBezugAfter85Rente =
        Math.round((versBezugAfterVNGRente + hgr85.ruhe85) * 100) / 100;

      //Rente-85-26abs6
      const versBezugAfter26abs6 =
        Math.round((versBezugAfter85Rente - hgr85.art26abs6) * 100) / 100;

      dispatch({
        type: "SET_VERSB",
        payload: {
          ...versB,
          mindestversorgung: mindestversorgung,
          versBezug: versBezug,
          abschlagPro: abschlagPro,
          abschlagProRech: abschlag[2],
          abschlagEur: abschlagEur,
          abschlagEnde: abschlag[1],
          abschlagfreiJahre: abschlagfreiJahre,
          versBezugAfterAbAuf: versBezugAfterAbAuf,
          deziJahreAb: abschlag[3],
          aufschlagPro: aufschlag[1],
          deziJahreAuf: aufschlag[2],
          aufschlagEnde: aufschlag[0],
          aufschlagEur: aufschlagEur,
          unabMind: unabMind,
          abhMind: abhMind,
          versBezugAfterMin: versBezugAfterMin,
          fzK: fzK,
          versBezugAfterFz: versBezugAfterFz,
          stva: stva,
          stva_eur: stva_eur,
          renteVNG: -rente.renteVNGnachHöher,
          versBezugAfterVNGRente: versBezugAfterVNGRente,
          kuerzungVA: va.kuerzung,
          versBezugAfterVA: versBezugAfterVA,
          rente85: hgr85.ruhe85,
          versBezugAfter85Rente: versBezugAfter85Rente,
          art26abs6: hgr85.art26abs6,
          versBezugAfter26abs6: versBezugAfter26abs6,
          versBezugAfterStva: versBezugAfterStva,
          versBezugAfterUnfall: versBezugAfterUnfall,
          unfallausgleich: hgr85.unfallUnbeachtet,
        },
      });
    }
  }, [
    reduxZeiten.gesamtzeit,
    rente,
    hgr85,
    bezug,
    data.fzK,
    data.birthDate,
    detail.grund,
    detail.beg,
    versB.zeitpunkt,
    va.kuerzung,
  ]);

  return (
    <div>
      <Backdrop open={loading} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <TabsNav
        vorgang="anrechnung"
        value={tabVal}
        handleTabChange={handleTabChange}
        mischNeeded={mischNeeded}
        vaNeeded={detail.va}
        renteNeeded={detail.rente}
        einkommenNeeded={detail.besch}
      />
      <TabPanel value={tabVal} index={0}>
        <AnrechnungAllgemein />
      </TabPanel>
      <TabPanel value={tabVal} index={4}>
        <Bezüge vorgang="anrechnung" />
        <Row>
          <Col>Ruhegehaltsatz:</Col>
          <Col>
            {reduxZeiten.gesamtzeit.gesamtZ[4] >=
            reduxZeiten.gesamtzeit.gesamtZMisch[10]
              ? reduxZeiten.gesamtzeit.gesamtZ[4]
              : reduxZeiten.gesamtzeit.gesamtZMisch[10]}{" "}
            %
            {reduxZeiten.gesamtzeit.gesamtZ[4] >=
              reduxZeiten.gesamtzeit.gesamtZMisch[10] &&
            reduxZeiten.manRGS.replace(",", ".") >
              reduxZeiten.gesamtzeit.gesamtZ[4]
              ? " (man. RGS " + reduxZeiten.manRGS + " %)"
              : reduxZeiten.gesamtzeit.gesamtZMisch[10] >
                  reduxZeiten.gesamtzeit.gesamtZ[4] &&
                reduxZeiten.manRGS.replace(",", ".") >
                  reduxZeiten.gesamtzeit.gesamtZMisch[10] &&
                " (man. RGS " + reduxZeiten.manRGS + " %)"}
          </Col>
        </Row>
        <VersBezug />
      </TabPanel>
      <TabPanel value={tabVal} index={5}>
        <VA />
      </TabPanel>
      <TabPanel value={tabVal} index={10}>
        <Hgr83 />
      </TabPanel>
      <TabPanel value={tabVal} index={6}>
        <Rente />
      </TabPanel>
      <TabPanel value={tabVal} index={7}>
        <Hgr85 />
      </TabPanel>
      <TabPanel value={tabVal} index={8}>
        {mischNeeded === false ? (
          <NeuesRecht
            viewManRGS={false}
            gesamtzeit={reduxZeiten.gesamtzeit.gesamtZ85Neu}
            anzZei={Object.keys(reduxZeiten.zeiten21HGR).length}
            zeiten={reduxZeiten.zeiten21HGR}
          />
        ) : (
          <MischRecht
            gesamtzeit={reduxZeiten.gesamtzeit.gesamtZ85Misch}
            anzZei={Object.keys(reduxZeiten.zeiten27HGR).length}
            zeiten={reduxZeiten.zeiten27HGR}
          />
        )}
      </TabPanel>
    </div>
  );
};

export default Anrechnung;
