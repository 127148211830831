import { SET_VERSB } from "../constants/actionTypes";
const initialState = {
  data: {
    versBezug: 0,
    abschlagPro: 0,
    abschlagEur: 0,
    abschlagEnde: "",
    fzK: 0,
    fzKPer: 100,
    mindestversorgung: false,
    unfallausgleich: 0,
    zeitpunkt: "0000-00-00",
  },
};

const versB = (state = initialState, action) => {
  switch (action.type) {
    case SET_VERSB:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default versB;
