export const SET_DATA = "SET_DATA";
export const SET_DETAIL = "SET_DETAIL";
export const SET_RENTE = "SET_RENTE";
export const SET_RENTE_CHANGE = "SET_RENTE_CHANGE";
export const SET_BEZUG = "SET_BEZUG";

export const ADD_VA = "ADD_VA";
export const SET_VA = "SET_VA";
export const SET_VA_INITIAL = "SET_VA_INITIAL";

export const SET_ANZ_ZEITEN = "SET_ANZ_ZEITEN";
export const SET_ANZ_ZEITEN_MINUS = "SET_ANZ_ZEITEN_MINUS";
export const SET_ANZAHL_ZEITEN = "SET_ANZAHL_ZEITEN";
export const SET_GESAMTZEIT = "SET_GESAMTZEIT";
export const SET_ZEIT = "SET_ZEIT";
export const SET_ZEIT21 = "SET_ZEIT21";
export const SET_ZEIT27 = "SET_ZEIT27";
export const SET_ZEIT21HGR = "SET_ZEIT21HGR";
export const SET_ZEIT27HGR = "SET_ZEIT27HGR";
export const SET_ZEITEN_BOOL = "SET_ZEITEN_BOOL";
export const SET_ZEITEN_CHANGE = "SET_ZEITEN_CHANGE";
export const SET_MISCH_NEEDED = "SET_MISCH_NEEDED";
export const SET_MAN_RGS = "SET_MAN_RGS";

export const SET_VERSB = "SET_VERSB";

export const SET_HGR83 = "SET_HGR83";
export const ADD_HGR83 = "ADD_HGR83";
export const SET_HGR84 = "SET_HGR84";
export const SET_HGR85 = "SET_HGR85";

export const SET_ANSCHREIBEN = "SET_ANSCHREIBEN";
export const SET_ANLAGEN = "SET_ANLAGEN";

export const SET_VORGANG = "SET_VORGANG";
export const SET_VIEW = "SET_VIEW";

export const SET_VORGAENGE = "SET_VORGAENGE";

export const SET_DIFF_BEZ = "SET_DIFF_BEZ";
