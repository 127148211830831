import React from "react";
import NumberFormat from "react-number-format";
import moment from "moment";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

const VersBezug = () => {
  const versB = useSelector((state) => state.versB.data);
  const detail = useSelector((state) => state.detail.data);
  const hgr85 = useSelector((state) => state.HGR85.data);
  const diffBes = useSelector((state) => state.diffBez);

  return (
    <div>
      <Row>
        <Col>Ruhegehalt:</Col>
        <Col>
          <NumberFormat
            decimalScale="2"
            fixedDecimalScale={true}
            value={versB.versBezug}
            displayType={"text"}
            thousandSeparator="."
            decimalSeparator=","
            suffix={" €"}
          />
        </Col>
        {diffBes[1].map((step) => (
          <Col>
            <NumberFormat
              decimalScale="2"
              fixedDecimalScale={true}
              value={step[3]}
              displayType={"text"}
              thousandSeparator="."
              decimalSeparator=","
              suffix={" €"}
            />
          </Col>
        ))}
      </Row>
      {versB.abschlagfreiJahre > 0 && (
        <Row>
          <Col>
            <b>
              Abschlagsfrei aufgrund Dienstjahre {">"}{" "}
              {detail.grund === "2" ? " 45" : " 40"}
            </b>
          </Col>
        </Row>
      )}
      {detail.grund !== "1" &&
        versB.abschlagPro !== 0 &&
        !isNaN(versB.abschlagPro) && (
          <div>
            <Row>
              <Col>
                Versorgungsabschlag:
                <div>
                  für den Zeitraum {moment(detail.beg).format("DD.MM.yyyy")}{" "}
                  {" - "} {versB.abschlagEnde}
                </div>
                <div>
                  = {versB.deziJahreAb} Jahre * 3,6 % pro Jahr ={" "}
                  {versB.abschlagProRech} %
                </div>
                {versB.abschlagProRech > versB.abschlagPro && (
                  <div>begrenzt auf {versB.abschlagPro} %</div>
                )}
              </Col>

              <Col className="align-self-end">
                <NumberFormat
                  decimalScale="2"
                  fixedDecimalScale={true}
                  value={versB.abschlagEur}
                  displayType={"text"}
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix={" €"}
                />
              </Col>
            </Row>
            <Row>
              <Col>verminderte Versorgungsbezüge:</Col>
              <Col>
                <NumberFormat
                  decimalScale="2"
                  fixedDecimalScale={true}
                  value={versB.versBezugAfterAbAuf}
                  displayType={"text"}
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix={" €"}
                />
              </Col>
            </Row>
          </div>
        )}
      {detail.grund === "1" &&
        versB.aufschlagPro !== 0 &&
        !isNaN(versB.aufschlagPro) && (
          <div>
            <Row>
              <Col>
                Versorgungsaufschlag:
                <div>
                  für den Zeitraum {moment(detail.beg).format("DD.MM.yyyy")}{" "}
                  {" - "} {versB.aufschlagEnde}
                </div>
                <div>
                  = {versB.deziJahreAuf} Jahre * 3,6 % pro Jahr ={" "}
                  {versB.aufschlagPro} %
                </div>
              </Col>
              <Col>
                <NumberFormat
                  decimalScale="2"
                  fixedDecimalScale={true}
                  value={versB.aufschlagEur}
                  displayType={"text"}
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix={" €"}
                />
              </Col>
            </Row>
            <Row>
              <Col>erhöhte Versorgungsbezüge:</Col>
              <Col>
                <NumberFormat
                  decimalScale="2"
                  fixedDecimalScale={true}
                  value={versB.versBezugAfterAbAuf}
                  displayType={"text"}
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix={" €"}
                />
              </Col>
            </Row>
          </div>
        )}
      {versB.fzK > 0 && (
        <div>
          <Row>
            <Col>Familienzuschlag (Kinderanteil):</Col>
            <Col>
              <NumberFormat
                decimalScale="2"
                fixedDecimalScale={true}
                value={versB.fzK}
                displayType={"text"}
                thousandSeparator="."
                decimalSeparator=","
                suffix={" €"}
              />
            </Col>
          </Row>
          <Row>
            <Col>Versorgungsbezüge (zwischensumme):</Col>
            <Col>
              <NumberFormat
                decimalScale="2"
                fixedDecimalScale={true}
                value={versB.versBezugAfterFz}
                displayType={"text"}
                thousandSeparator="."
                decimalSeparator=","
                suffix={" €"}
              />
            </Col>
          </Row>
        </div>
      )}
      {versB.abhMind > versB.versBezugAfterAbAuf ||
      versB.unabMind > versB.versBezugAfterAbAuf ? (
        <div>
          <div>Abgleich mit Mindestversorgung</div>
          <Row>
            <Col>amtsbezogene Mindestversorgung</Col>
            <Col>
              <NumberFormat
                decimalScale="2"
                fixedDecimalScale={true}
                value={versB.abhMind}
                displayType={"text"}
                thousandSeparator="."
                decimalSeparator=","
                suffix={" €"}
              />
            </Col>
            {diffBes[1].map((step) => (
              <Col>
                <NumberFormat
                  decimalScale="2"
                  fixedDecimalScale={true}
                  value={step[10]}
                  displayType={"text"}
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix={" €"}
                />
              </Col>
            ))}
          </Row>
          <Row>
            <Col>amtsunabhängige Mindestversorgung</Col>
            <Col>
              <NumberFormat
                decimalScale="2"
                fixedDecimalScale={true}
                value={versB.unabMind}
                displayType={"text"}
                thousandSeparator="."
                decimalSeparator=","
                suffix={" €"}
              />
            </Col>
            {diffBes[1].map((step) => (
              <Col>
                <NumberFormat
                  decimalScale="2"
                  fixedDecimalScale={true}
                  value={step[9]}
                  displayType={"text"}
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix={" €"}
                />
              </Col>
            ))}
          </Row>
          <Row>
            <Col>Versorgungsbezüge</Col>
            <Col>
              <NumberFormat
                decimalScale="2"
                fixedDecimalScale={true}
                value={versB.versBezugAfterMin}
                displayType={"text"}
                thousandSeparator="."
                decimalSeparator=","
                suffix={" €"}
              />
            </Col>
            {diffBes[1].map((step) => (
              <Col>
                <NumberFormat
                  decimalScale="2"
                  fixedDecimalScale={true}
                  value={step[11]}
                  displayType={"text"}
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix={" €"}
                />
              </Col>
            ))}
          </Row>
        </div>
      ) : null}
      <br />
      <Row>
        <Col>Anrechnungs-, Kürzungs- und Ruhensbeträge:</Col>
      </Row>
      <Row>
        <Col>erdientes Ruhegehalt</Col>
        <Col>
          <NumberFormat
            decimalScale="2"
            fixedDecimalScale={true}
            value={versB.versBezugAfterMin}
            displayType={"text"}
            thousandSeparator="."
            decimalSeparator=","
            suffix={" €"}
          />
        </Col>
        {diffBes[1].map((step) => (
          <Col>
            <NumberFormat
              decimalScale="2"
              fixedDecimalScale={true}
              value={step[11]}
              displayType={"text"}
              thousandSeparator="."
              decimalSeparator=","
              suffix={" €"}
            />
          </Col>
        ))}
      </Row>
      {detail.grund === "5" && (
        <Row>
          <Col>Dienstunfallausgleich (MdE {hgr85.mde} %)</Col>
          <Col>
            {versB.unfallausgleich > 0 && (
              <NumberFormat
                decimalScale="2"
                fixedDecimalScale={true}
                value={versB.unfallausgleich}
                displayType={"text"}
                thousandSeparator="."
                decimalSeparator=","
                suffix={" €"}
              />
            )}
          </Col>
        </Row>
      )}
      {versB.kuerzungVA !== 0 && (
        <Row>
          <Col>Kürzung wg. Versorgungsausgleich</Col>
          <Col>
            <NumberFormat
              decimalScale="2"
              fixedDecimalScale={true}
              value={versB.kuerzungVA}
              displayType={"text"}
              thousandSeparator="."
              decimalSeparator=","
              suffix={" €"}
            />
          </Col>
        </Row>
      )}
      {versB.stva > 0 && (
        <Row>
          <Col>
            <div>Steuervorteilsausgleich:</div>
            <div>{versB.stva + " %"}</div>
          </Col>
          <Col className="align-self-end">
            <NumberFormat
              decimalScale="2"
              fixedDecimalScale={true}
              value={versB.stva_eur}
              displayType={"text"}
              thousandSeparator="."
              decimalSeparator=","
              suffix={" €"}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col>Rentenanrechnung nach VNG</Col>
        <Col className="align-self-end">
          <NumberFormat
            decimalScale="2"
            fixedDecimalScale={true}
            value={versB.renteVNG}
            displayType={"text"}
            thousandSeparator="."
            decimalSeparator=","
            suffix={" €"}
          />
        </Col>
      </Row>
      {hgr85.ruhe85 < 0 && (
        <Row>
          <Col>Ruhensbetrag nach Art. 85 BayBeamtVG</Col>
          <Col>
            <NumberFormat
              decimalScale="2"
              fixedDecimalScale={true}
              value={hgr85.ruhe85}
              displayType={"text"}
              thousandSeparator="."
              decimalSeparator=","
              suffix={" €"}
            />
          </Col>
        </Row>
      )}
      {hgr85.art26abs6 > 0 && (
        <Row>
          <Col>zusätzlicher Ruhensbetrag nach Art. 26 Abs. 6 BayBeamtVG</Col>
          <Col>
            <NumberFormat
              decimalScale="2"
              fixedDecimalScale={true}
              value={-1 * hgr85.art26abs6}
              displayType={"text"}
              thousandSeparator="."
              decimalSeparator=","
              suffix={" €"}
            />
          </Col>
        </Row>
      )}
      <Row className="border-top">
        <Col>zu zahlende Versorgung (brutto)</Col>
        <Col>
          <NumberFormat
            decimalScale="2"
            fixedDecimalScale={true}
            value={versB.versBezugAfter26abs6}
            displayType={"text"}
            thousandSeparator="."
            decimalSeparator=","
            suffix={" €"}
          />
        </Col>
      </Row>
    </div>
  );
};

export default VersBezug;
