import { SET_VORGAENGE } from "../constants/actionTypes";

const initialState = {
  data: {
  },
};

const vorgaenge = (state = initialState, action) => {
  switch (action.type) {
    case SET_VORGAENGE:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default vorgaenge;
