import { SET_DATA } from "../constants/actionTypes";

const initialState = {
  data: {
    id: 0,
    vorname: "",
    nachname: "",
    GdB: false,
    fzPer: "0",
    fzK: "0",
    stkl: 3,
    titel: "",
    birthDate: "",
    fam: "",
    geschl: "",
  },
};

const person = (state = initialState, action) => {
  switch (action.type) {
    case SET_DATA:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default person;
