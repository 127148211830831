import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";

const FZ = () => {
  const [response, setResponse] = useState("");

  const [e1, setE1] = useState({
    dienstwohnung: false,
    dienstumfang: 100,
    oed: false,
    elkb: true,
    k1: false,
    k2: false,
    k3: false,
    k4: false,
    k5: false,
    k6: false,
    k7: false,
    k8: false,
    k9: false,
    k10: false,
  });

  const [e2, setE2] = useState({
    dienstwohnung: false,
    dienstumfang: 0,
    oed: false,
    elkb: false,
    besitzstandK: false,
    k1: false,
    k2: false,
    k3: false,
    k4: false,
    k5: false,
    k6: false,
    k7: false,
    k8: false,
    k9: false,
    k10: false,
  });

  const handleDUChange = (e) => {
    if (e.target.id === "1") {
      setE1({ ...e1, dienstumfang: e.target.value });
    } else {
      setE2({ ...e2, dienstumfang: e.target.value });
    }
  };
  const handleOEDChange = (e) => {
    if (e.target.checked) {
      setE2({ ...e2, elkb: false, oed: e.target.checked });
    } else {
      setE2({ ...e2, oed: e.target.checked });
    }
  };
  const handleELKBChange = (e) => {
    if (e.target.checked) {
      setE2({ ...e2, elkb: e.target.checked, oed: false });
    } else {
      setE2({ ...e2, elkb: e.target.checked });
    }
  };
  const handleKChange = (e) => {
    setE2({ ...e2, besitzstandK: e.target.checked });
  };
  const handleDWChange = (e) => {
    if (e.target.id === "1") {
      setE1({ ...e1, dienstwohnung: e.target.checked });
    } else {
      if (e.target.checked) {
        setE2({
          ...e2,
          dienstwohnung: e.target.checked,
          elkb: e.target.checked,
        });
      } else {
        setE2({ ...e2, dienstwohnung: e.target.checked });
      }
    }
  };
  const handleKindChange = (e) => {
    if (e.target.name === "1") {
      setE1({ ...e1, [`k${e.target.id}`]: e.target.checked });
    } else {
      setE2({ ...e2, [`k${e.target.id}`]: e.target.checked });
    }
  };

  const startCalc = async () => {
    try {
      const send = await fetch(
        //`http://localhost/versorgung/rest-api/fz.php`
        "https://versorgung.hosting142616.a2e76.netcup.net/rest-api/fz.php",
        {
          method: "POST",
          body: JSON.stringify({ e1: e1, e2: e2 }),
        }
      );
      if (send) {
        setResponse(await send.json());
      }
    } catch (error) {}
  };

  return (
    <div>
      <div>Berechnung Familienzuschlag</div>
      <Row>
        <Col md="6">
          <Card>
            <Card.Header>
              <Card.Title>Ehegatte 1</Card.Title>
            </Card.Header>
            <Card.Body>
              <div className="pt-2 pb-2 justify-content-between d-flex">
                <div className="d-inline">Dienstwohnung?</div>
                <Form.Check // prettier-ignore
                  className="d-inline"
                  type="switch"
                  id={1}
                  value={e1.dienstwohnung}
                  checked={e1.dienstwohnung}
                  onChange={(e) => handleDWChange(e)}
                />
              </div>
              <div>
                <InputGroup>
                  <Form.Control
                    placeholder="Dienstumfang"
                    aria-label="Dienstumfang"
                    aria-describedby="basic-addon2"
                    value={e1.dienstumfang}
                    id={1}
                    onChange={(e) => handleDUChange(e)}
                  />
                  <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
                </InputGroup>
              </div>
              {[...Array(10)].map((x, id) => {
                return (
                  <div className="pt-2 pb-2 justify-content-between d-flex">
                    <div className="d-inline">
                      {e1.elkb && e2.elkb && "Kindergeld "}Kind {id + 1}?
                    </div>
                    <Form.Check // prettier-ignore
                      className="d-inline"
                      type="switch"
                      id={id + 1}
                      name={1}
                      checked={e1[`k${id + 1}`]}
                      value={e1[`k${id + 1}`]}
                      onChange={(e) => handleKindChange(e)}
                    />
                  </div>
                );
              })}
            </Card.Body>
          </Card>
        </Col>
        <Col md="6">
          <Card>
            <Card.Header>
              <Card.Title>Ehegatte 2</Card.Title>
            </Card.Header>
            <Card.Body>
              <div className="pt-2 pb-2 justify-content-between d-flex">
                <div className="d-inline">Dienstwohnung?</div>
                <Form.Check // prettier-ignore
                  className="d-inline"
                  type="switch"
                  id={2}
                  value={e2.dienstwohnung}
                  checked={e2.dienstwohnung}
                  onChange={(e) => handleDWChange(e)}
                />
              </div>
              <div>
                <InputGroup>
                  <Form.Control
                    placeholder="Dienstumfang"
                    aria-label="Dienstumfang"
                    aria-describedby="basic-addon2"
                    value={e2.dienstumfang}
                    id={2}
                    onChange={(e) => handleDUChange(e)}
                  />
                  <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
                </InputGroup>
              </div>
              <div className="pt-2 pb-2 justify-content-between d-flex">
                <div className="d-inline">ÖR bei ELKB?</div>
                <Form.Check // prettier-ignore
                  className="d-inline"
                  type="switch"
                  id="custom-switch"
                  checked={e2.elkb}
                  value={e2.elkb}
                  onChange={(e) => handleELKBChange(e)}
                />
              </div>
              <div className="pt-2 pb-2 justify-content-between d-flex">
                <div className="d-inline">öffentlicher Dienst?</div>
                <Form.Check // prettier-ignore
                  className="d-inline"
                  type="switch"
                  id="custom-switch"
                  checked={e2.oed}
                  value={e2.oed}
                  onChange={(e) => handleOEDChange(e)}
                />
              </div>
              <div className="pt-2 pb-2 justify-content-between d-flex">
                <div className="d-inline">Besitzstand Kind (Ang. öD)?</div>
                <Form.Check // prettier-ignore
                  className="d-inline"
                  type="switch"
                  id="custom-switch"
                  checked={e2.besitzstandK}
                  value={e2.besitzstandK}
                  onChange={(e) => handleKChange(e)}
                />
              </div>
              {[...Array(10)].map((x, id) => {
                return (
                  <div className="pt-2 pb-2 justify-content-between d-flex">
                    <div className="d-inline">
                      {e1.elkb && e2.elkb && "Kindergeld "}Kind {id + 1}?
                    </div>
                    <Form.Check // prettier-ignore
                      className="d-inline"
                      type="switch"
                      id={id + 1}
                      name={2}
                      checked={e2[`k${id + 1}`]}
                      value={e2[`k${id + 1}`]}
                      onChange={(e) => handleKindChange(e)}
                    />
                  </div>
                );
              })}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Button onClick={startCalc}>berechnen</Button>
      {response !== "" && (
        <div>
          {response[0]} €{response[1] && <> (nur SZ)</>}
        </div>
      )}
    </div>
  );
};

export default FZ;
