import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function TabsNav(props) {
  const classes = useStyles();
  const versB = useSelector((state) => state.versB.data);
  const detail = useSelector((state) => state.detail.data);
  return (
    <div className={"no-print " + classes.root}>
      <Tabs
        value={props.value}
        onChange={props.handleTabChange}
        variant="scrollable"
        scrollButtons="on"
        aria-label="scrollable force tabs example"
      >
        <Tab value={0} label="Allg. Daten" />
        {props.vorgang !== "anrechnung" && (
          <Tab value={1} label="Dienstzeitverlauf" />
        )}
        {props.vorgang !== "anrechnung" && (
          <Tab value={2} label="Neues Recht" />
        )}
        {props.mischNeeded === true && props.vorgang !== "anrechnung" && (
          <Tab value={3} label="Altes Recht" />
        )}
        {props.vorgang === "anrechnung" &&
          versB.zeitpunkt !== "" &&
          versB.zeitpunkt !== "0000-00-00" &&
          versB.zeitpunkt > "0200-00-00" &&
          versB.zeitpunkt >= detail.beg && (
            <Tab value={4} label="Versorgungsbezüge" />
          )}
        {props.vaNeeded === true && (
          <Tab value={5} label="Versorgungsausgleich" />
        )}
        {props.einkommenNeeded === true && (
          <Tab value={10} label="HGR Art. 83" />
        )}
        {props.renteNeeded === true && (
          <Tab value={6} label="Rentenanrechnung" />
        )}
        {props.renteNeeded === true && <Tab value={7} label="HGR Art. 85" />}
        {props.renteNeeded === true && (
          <Tab value={8} label="RGS für Art. 85" />
        )}
        <Tab value={9} label="Bescheide" />
      </Tabs>
    </div>
  );
}
