export const dienstzeiten = [
    {
      value: 'Z001',
      label: 'Vorbereitungsdienst',
    },
    {
      value: 'Z002',
      label: 'Dienstzeit',
    },
    {
      value: 'Z042',
      label: 'Zurechnungszeit',
    }
  ];