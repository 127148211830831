import moment from "moment";
import bes from "./bes.json";
import zulagen from "./zulagen.json";
import stva from "./stva.json";
import { stufenA, stufenB, stufenC, stufenW, stufenP } from "./besoldung";

export const calc_regel = (date) => {
  //Tag aus date auslesen
  const day = moment(date).format("D");

  //Wenn Tag ist 1 dann 65 jahre dazu, ansonsten 65 + 1 um auf den nächsten ersten zu kommen
  const regel =
    day === "1"
      ? moment(date).add(65, "y").startOf("month")
      : moment(date).add({ years: 65, month: 1 }).startOf("month");

  //Hole verlängerung aus eigener funktion
  const verla = verl(date);

  //addiere verlängerung als object
  const long = moment(regel).add(verla).format("YYYY-MM-DD");

  return long;
};

export const calc_abschlag_schwerb = (date) => {
  //Tag aus date auslesen
  const day = moment(date).format("D");

  //Wenn Tag ist 1 dann 63 jahre dazu, ansonsten 63 + 1 um auf den nächsten ersten zu kommen
  const regel =
    day === "1"
      ? moment(date).add(63, "y").startOf("month")
      : moment(date).add({ years: 63, month: 1 }).startOf("month");

  //Hole verlängerung aus eigener funktion
  const verla = verl(date);

  //addiere verlängerung als object
  const long = moment(regel).add(verla).format("YYYY-MM-DD");

  return long;
};

export const calc_abschlag_du = (date, beg) => {
  //Tag aus date auslesen
  const day = moment(date).format("D");

  //Wenn Tag ist 1 dann 63 jahre dazu, ansonsten 63 + 1 um auf den nächsten ersten zu kommen
  const regel =
    day === "1"
      ? moment(date).add(63, "y").startOf("month")
      : moment(date).add({ years: 63, month: 1 }).startOf("month");

  //"VERLÄNGERUNG ERMITTELN
  switch (beg) {
    case beg < "2012-01-01":
      return moment(regel).format("YYYY-MM-DD");
    case beg < "2012-02-01":
      return moment(regel).add(1, "M").format("YYYY-MM-DD");
    case beg < "2012-03-01":
      return moment(regel).add(2, "M").format("YYYY-MM-DD");
    case beg < "2012-04-01":
      return moment(regel).add(3, "M").format("YYYY-MM-DD");
    case beg < "2012-05-01":
      return moment(regel).add(4, "M").format("YYYY-MM-DD");
    case beg < "2012-06-01":
      return moment(regel).add(5, "M").format("YYYY-MM-DD");
    case beg < "2013-01-01":
      return moment(regel).add(6, "M").format("YYYY-MM-DD");
    case beg < "2014-01-01":
      return moment(regel).add(7, "M").format("YYYY-MM-DD");
    case beg < "2015-01-01":
      return moment(regel).add(8, "M").format("YYYY-MM-DD");
    case beg < "2016-01-01":
      return moment(regel).add(9, "M").format("YYYY-MM-DD");
    case beg < "2017-01-01":
      return moment(regel).add(10, "M").format("YYYY-MM-DD");
    case beg < "2018-01-01":
      return moment(regel).add(11, "M").format("YYYY-MM-DD");
    case beg < "2019-01-01":
      return moment(regel).add(1, "y").format("YYYY-MM-DD");
    case beg < "2020-01-01":
      return moment(regel).add({ years: 1, months: 2 }).format("YYYY-MM-DD");
    case beg < "2021-01-01":
      return moment(regel).add({ years: 1, months: 4 }).format("YYYY-MM-DD");
    case beg < "2022-01-01":
      return moment(regel).add({ years: 1, months: 6 }).format("YYYY-MM-DD");
    case beg < "2023-01-01":
      return moment(regel).add({ years: 1, months: 8 }).format("YYYY-MM-DD");
    case beg < "2024-01-01":
      return moment(regel).add({ years: 1, months: 10 }).format("YYYY-MM-DD");
    default:
      return moment(regel).add(2, "y").format("YYYY-MM-DD");
  }
};

export const verl = (date) => {
  const year = moment(date).format("YYYY");

  if (year === "1947") {
    return { years: 0, month: 1 };
  } else if (year === "1948") {
    return { years: 0, month: 2 };
  } else if (year === "1949") {
    return { years: 0, month: 3 };
  } else if (year === "1950") {
    return { years: 0, month: 4 };
  } else if (year === "1951") {
    return { years: 0, month: 5 };
  } else if (year === "1952") {
    return { years: 0, month: 6 };
  } else if (year === "1953") {
    return { years: 0, month: 7 };
  } else if (year === "1954") {
    return { years: 0, month: 8 };
  } else if (year === "1955") {
    return { years: 0, month: 9 };
  } else if (year === "1956") {
    return { years: 0, month: 10 };
  } else if (year === "1957") {
    return { years: 0, month: 11 };
  } else if (year === "1958") {
    return { years: 1, month: 0 };
  } else if (year === "1959") {
    return { years: 1, month: 2 };
  } else if (year === "1960") {
    return { years: 1, month: 4 };
  } else if (year === "1961") {
    return { years: 1, month: 6 };
  } else if (year === "1962") {
    return { years: 1, month: 8 };
  } else if (year === "1963") {
    return { years: 1, month: 10 };
  } else {
    return { years: 2, month: 0 };
  }
};

export const calc_21 = (date) => {
  const momentObj = moment(date);
  const when_21 = momentObj.add(21, "y");
  return when_21.format("YYYY-MM-DD");
};

export const calc_27 = (date) => {
  const momentObj = moment(date);
  const when_27 = momentObj.add(27, "y");
  return when_27.format("YYYY-MM-DD");
};

export const calc_64 = (date) => {
  const momentObj = moment(date);
  const when_27 = momentObj.add(64, "y");
  return when_27.format("YYYY-MM-DD");
};

//wegen DU - daher auch gleich auf den Ende des Monats gesetzt
export const calc_62 = (date) => {
  //Tag aus date auslesen
  const day = moment(date).format("D");

  //Wenn Tag ist 1 dann 62 jahre dazu, ansonsten 61 + 11 um auf den nächsten ersten zu kommen
  const when_62 =
    day !== "1"
      ? moment(date).add(62, "y").endOf("month")
      : moment(date).add({ years: 61, month: 11 }).endOf("month");

  return when_62.format("YYYY-MM-DD");
};

//wegen DU - daher auch gleich auf den Ende des Monats gesetzt
export const calc_55 = (date) => {
  //Tag aus date auslesen
  const day = moment(date).format("D");

  //Wenn Tag ist 1 dann 55 jahre dazu, ansonsten 54 + 11 um auf den nächsten ersten zu kommen
  const when_55 =
    day !== "1"
      ? moment(date).add(55, "y").endOf("month")
      : moment(date).add({ years: 54, month: 11 }).endOf("month");

  return when_55.format("YYYY-MM-DD");
};

export const grundg = (gruppe, stufe, date) => {
  const Mdate = moment(date).format("YYYY-MM-DD");

  const filterf = (value, index, array) => {
    return (
      value.gruppe === gruppe &&
      +value.stufe === +stufe &&
      value.beg <= Mdate &&
      value.end > Mdate
    );
  };

  const grundgehalt = bes.filter(filterf);

  if (grundgehalt[0] && +grundgehalt[0]["betrag"] > 0) {
    return grundgehalt[0];
  } else {
    return 0;
  }
};

export const zulage = (art, date, bes) => {
  const Mdate = moment(date).format("YYYY-MM-DD");

  const filterf = (value, index, array) => {
    return (
      value.art === art &&
      value.beg <= Mdate &&
      value.end > Mdate &&
      value.von <= bes &&
      value.bis >= bes
    );
  };

  const zulage = zulagen.filter(filterf);

  if (zulage.length === 0) {
    zulage.betrag = 0;
  }

  return zulage[0] ? zulage[0] : 0;
};

export const getMaxStufe = (besO, besG) => {
  let Stufen = "";
  if (besO === "A") {
    Stufen = stufenA.filter((value, index) => {
      return value.gruppe === besG;
    });
  } else if (besO === "B") {
    Stufen = stufenB.filter((value, index) => {
      return value.gruppe === besG;
    });
  } else if (besO === "C") {
    Stufen = stufenC.filter((value, index) => {
      return value.gruppe === besG;
    });
  } else if (besO === "W") {
    Stufen = stufenW.filter((value, index) => {
      return value.gruppe === besG;
    });
  } else if (besO === "P") {
    Stufen = stufenP.filter((value, index) => {
      return value.gruppe === besG;
    });
  }

  return Stufen.length > 0 ? Stufen[0].stufe : 0;
};

export const calculatePercent = (percent, num) => {
  const val = (num * +percent) / 100;
  const val2 = parseFloat(val);
  return +((val2 * 100) / 100);
};

export const calcDateDiff = (zeit) => {
  if (!zeit) {
    return ["error", "error"];
  }

  const begDa = moment(zeit.begDa);
  const endDa = moment(zeit.endDa).add(1, "d");

  //diff2 scheint besser zu funktionieren
  const diff2 = endDa.diff(begDa, "years");

  //volle Jahre ermitteln
  //const diff  = moment.duration(endDa.diff(begDa)).years();

  //vollen Jahre auf begDa addieren
  const begDaY = moment(begDa).add(diff2, "y");

  //mit mod. begDa ein neues diff bilden
  const diffNew = Math.round(moment.duration(endDa.diff(begDaY)).asDays());

  //Teildienst?
  if (+zeit.za / +zeit.n !== 1) {
    const tageFromJahre = +diff2 * 365;
    const tageFromTage = diffNew;
    const tageVoll = tageFromJahre + tageFromTage;
    const gesTageAnt = tageVoll * (zeit.za / zeit.n);
    const jahreAnt = Math.floor(gesTageAnt / 365);
    const tageAnt = Math.round((gesTageAnt - jahreAnt * 365) * 100) / 100;

    const rgfZ = zeit.begDa > zeit.endDa ? [0, 0] : [jahreAnt, tageAnt];
    return rgfZ;
  }
  //Änderung 13.08.2021 von begDa größer gleich zu nur größer, damit auch einzelene Tage gezählt werden
  //Bsp 01.01.2021 - 01.01.2021 sollte einen Tag ergeben. mit dem alten code ergibt dies 0, daher Änderung am 13.08.2021
  const rgfZ = zeit.begDa > zeit.endDa ? [0, 0] : [diff2, diffNew];
  return rgfZ;
};
//Splitten der Mischrechtzeiten am 31.12.1991
export const splitMisch = (zeit) => {
  for (let index = 1; index <= Object.keys(zeit).length; index++) {
    if (
      zeit[`z${index}`].begDa < "1992-01-01" &&
      zeit[`z${index}`].endDa > "1991-12-31"
    ) {
      for (let index2 = Object.keys(zeit).length; index2 > index; index2--) {
        zeit[`z${index2 + 1}`] = {};
        zeit[`z${index2 + 1}`] = zeit[`z${index2}`];
      }

      zeit[`z${index + 1}`] = {};
      zeit[`z${index + 1}`].endDa = zeit[`z${index}`].endDa;
      zeit[`z${index + 1}`].begDa = "1992-01-01";
      zeit[`z${index + 1}`].ze = zeit[`z${index}`].ze;
      zeit[`z${index + 1}`].za = zeit[`z${index}`].za;
      zeit[`z${index + 1}`].n = zeit[`z${index}`].n;
      zeit[`z${index + 1}`].rgfD = {};
      zeit[`z${index + 1}`].rgfD["jahre"] = calcDateDiff(
        zeit[`z${index + 1}`]
      )[0];
      zeit[`z${index + 1}`].rgfD["tage"] = calcDateDiff(
        zeit[`z${index + 1}`]
      )[1];
      zeit[`z${index}`].endDa = "1991-12-31";
      zeit[`z${index}`].rgfD["jahre"] = calcDateDiff(zeit[`z${index}`])[0];
      zeit[`z${index}`].rgfD["tage"] = calcDateDiff(zeit[`z${index}`])[1];
    }
  }
  return zeit;
};

//Mischrecht
export const calcGesamtzeitMisch = (zeiten, date) => {
  let jahre_vor_92 = 0;
  let tage_vor_92 = 0;
  let jahre_nach_92 = 0;
  let tage_nach_92 = 0;
  const when27 = calc_27(date);
  let zeiten_vor_27 = false;
  let skippedTimes = 0;
  let stop = false;
  let kummu = {};

  for (let index = 1; index <= Object.keys(zeiten).length; index++) {
    if (
      zeiten[`z${index}`].rgfD &&
      typeof zeiten[`z${index}`].rgfD["jahre"] === "number" &&
      typeof zeiten[`z${index}`].rgfD["tage"] === "number"
    ) {
      //die letzte Dienstzeit wird nicht durch gegangen, da dann index+1 einen Fehler wirft.
      //die letze Dienstzeit wird dann in der else abgefragt.
      //Wenn gar nichts zu trifft, siehe unten
      if (
        Object.keys(zeiten).length >= 2 &&
        index !== Object.keys(zeiten).length
      ) {
        const a = moment(zeiten[`z${index}`].endDa);
        const b = moment(zeiten[`z${index + 1}`].begDa);

        if (+zeiten[`z${index + 1}`].za / +zeiten[`z${index + 1}`].n !== 1) {
          stop = true;
        } else if (+zeiten[`z${index}`].za / +zeiten[`z${index}`].n !== 1) {
          stop = true;
        } else if (zeiten[`z${index}`].endDa === "1991-12-31") {
          stop = true;
        } else if (b.diff(a, "days") === 1) {
          skippedTimes += 1;
        } else {
          stop = true;
        }

        if (stop === true) {
          kummu[`z${index}`] = calcDateDiff({
            begDa: zeiten[`z${index - skippedTimes}`].begDa,
            endDa: zeiten[`z${index}`].endDa,
            za: zeiten[`z${index}`].za,
            n: zeiten[`z${index}`].n,
          });
          stop = false;
          skippedTimes = 0;
          if (zeiten[`z${index}`].endDa <= "1991-12-31") {
            jahre_vor_92 += kummu[`z${index}`][0];
            tage_vor_92 += kummu[`z${index}`][1];
          } else {
            jahre_nach_92 += kummu[`z${index}`][0];
            tage_nach_92 += kummu[`z${index}`][1];
          }
        }
      } else if (
        index === Object.keys(zeiten).length &&
        Object.keys(kummu).length !== 0
      ) {
        kummu[`z${index}`] = calcDateDiff({
          begDa: zeiten[`z${index - skippedTimes}`].begDa,
          endDa: zeiten[`z${index}`].endDa,
          za: zeiten[`z${index}`].za,
          n: zeiten[`z${index}`].n,
        });
        if (zeiten[`z${index}`].endDa <= "1991-12-31") {
          jahre_vor_92 += kummu[`z${index}`][0];
          tage_vor_92 += kummu[`z${index}`][1];
        } else {
          jahre_nach_92 += kummu[`z${index}`][0];
          tage_nach_92 += kummu[`z${index}`][1];
        }
      }
    } else {
      jahre_vor_92 = "NaN";
      tage_vor_92 = "NaN";
      jahre_nach_92 = "NaN";
      tage_nach_92 = "NaN";
    }
  }

  const dezi_vor_92 =
    Math.round((jahre_vor_92 + tage_vor_92 / 365) * 100) / 100;
  const zusätzliches_jahr = tage_vor_92 > 182 ? 1 : 0;
  const Zeiten_vor_92 =
    jahre_vor_92 + zusätzliches_jahr <= 15
      ? (jahre_vor_92 + zusätzliches_jahr) * 2
      : (jahre_vor_92 + zusätzliches_jahr) * 2 -
        (jahre_vor_92 + zusätzliches_jahr) +
        15;
  const ten_jahre_frist = 10 - jahre_vor_92 > 0 ? 10 - jahre_vor_92 : 0;
  const ten_jahre_frist_tage = 10 - jahre_vor_92 > 0 ? tage_vor_92 : 0;
  const Zeiten_nach_92 =
    Math.round(
      (jahre_nach_92 -
        ten_jahre_frist +
        ten_jahre_frist_tage / 365 +
        tage_nach_92 / 365) *
        100
    ) / 100;
  const ten_years =
    Math.round((10 - jahre_vor_92 - tage_vor_92 / 365) * 100) / 100;
  const rgs_vor_faktor = 35 + Zeiten_nach_92 + Zeiten_vor_92;
  const rgs_nach_faktor = Math.round(rgs_vor_faktor * 0.95667 * 100) / 100;
  const rgs = rgs_nach_faktor > 71.75 ? 71.75 : rgs_nach_faktor;
  return [
    jahre_vor_92,
    tage_vor_92,
    dezi_vor_92,
    jahre_nach_92,
    tage_nach_92,
    ten_years,
    Zeiten_vor_92,
    Zeiten_nach_92,
    rgs_vor_faktor,
    rgs_nach_faktor,
    rgs,
    zeiten_vor_27,
    when27,
    kummu,
  ];
};
//neues Recht
export const calcGesamtzeitNeu = (zeiten, date) => {
  let jahre = 0;
  let tage = 0;
  let deziJahre = 0;
  const when21 = calc_21(date);
  let zeiten_vor_21 = false;
  let skippedTimes = 0;
  let stop = false;
  let kummu = {};

  for (let index = 1; index <= Object.keys(zeiten).length; index++) {
    if (
      zeiten[`z${index}`].rgfD &&
      typeof zeiten[`z${index}`].rgfD["jahre"] === "number" &&
      typeof zeiten[`z${index}`].rgfD["tage"] === "number"
    ) {
      //die letzte Dienstzeit wird nicht durch gegangen, da dann index+1 einen Fehler wirft.
      //die letze Dienstzeit wird dann in der else abgefragt.
      //Wenn gar nichts zu trifft, siehe unten
      if (
        Object.keys(zeiten).length >= 2 &&
        index !== Object.keys(zeiten).length
      ) {
        const a = moment(zeiten[`z${index}`].endDa);
        const b = moment(zeiten[`z${index + 1}`].begDa);

        if (+zeiten[`z${index + 1}`].za / +zeiten[`z${index + 1}`].n !== 1) {
          stop = true;
        } else if (+zeiten[`z${index}`].za / +zeiten[`z${index}`].n !== 1) {
          stop = true;
        } else if (b.diff(a, "days") === 1) {
          skippedTimes += 1;
        } else {
          stop = true;
        }

        if (stop === true) {
          kummu[`z${index}`] = calcDateDiff({
            begDa: zeiten[`z${index - skippedTimes}`].begDa,
            endDa: zeiten[`z${index}`].endDa,
            za: zeiten[`z${index}`].za,
            n: zeiten[`z${index}`].n,
          });
          stop = false;
          skippedTimes = 0;
        }
      } else if (
        index === Object.keys(zeiten).length &&
        Object.keys(kummu).length !== 0
      ) {
        kummu[`z${index}`] = calcDateDiff({
          begDa: zeiten[`z${index - skippedTimes}`].begDa,
          endDa: zeiten[`z${index}`].endDa,
          za: zeiten[`z${index}`].za,
          n: zeiten[`z${index}`].n,
        });
      }
    }
  }

  //Wenn bisher nichts kummuliert wurde. D. h. die Dienstzeit läuft von Anfang bis Ende durch
  if (Object.keys(kummu).length === 0) {
    kummu[`z${Object.keys(zeiten).length}`] = calcDateDiff({
      begDa: zeiten[`z1`].begDa,
      endDa: zeiten[`z${Object.keys(zeiten).length}`].endDa,
      za: 1,
      n: 1,
    });
  }
  for (let index = 1; index <= Object.keys(zeiten).length; index++) {
    if (
      zeiten[`z${index}`].rgfD &&
      typeof zeiten[`z${index}`].rgfD["jahre"] === "number" &&
      typeof zeiten[`z${index}`].rgfD["tage"] === "number"
    ) {
      if (kummu[`z${index}`]) {
        //when keine Zeiten vor 21 dann ganz normal
        jahre += kummu[`z${index}`][0];
        tage += kummu[`z${index}`][1];
      }
    } else {
      jahre = "NaN";
      tage = "NaN";
    }
  }

  deziJahre = Math.round((jahre + tage / 365) * 100) / 100;

  const zeitFaktor = Math.round(deziJahre * 1.79375 * 100) / 100;

  const rechRgs = Math.round((zeitFaktor + 7.175) * 100) / 100;
  const rgs = rechRgs > 71.75 ? 71.75 : rechRgs;

  return [
    jahre,
    tage,
    deziJahre,
    zeitFaktor,
    rgs,
    rechRgs,
    zeiten_vor_21,
    when21,
    kummu,
  ];
};
//RGS für Rentenanrechnung
export const calcGesamtzeit85Neu = (zeiten) => {
  let jahre = 0;
  let tage = 0;
  let deziJahre = 0;
  let skippedTimes = 0;
  let stop = false;
  let kummu = {};

  for (let index = 1; index <= Object.keys(zeiten).length; index++) {
    if (
      zeiten[`z${index}`].rgfD &&
      typeof zeiten[`z${index}`].rgfD["jahre"] === "number" &&
      typeof zeiten[`z${index}`].rgfD["tage"] === "number"
    ) {
      //die letzte Dienstzeit wird nicht durch gegangen, da dann index+1 einen Fehler wirft.
      //die letze Dienstzeit wird dann in der else abgefragt.
      //Wenn gar nichts zu trifft, siehe unten
      if (
        Object.keys(zeiten).length >= 2 &&
        index !== Object.keys(zeiten).length
      ) {
        const a = moment(zeiten[`z${index}`].endDa);
        const b = moment(zeiten[`z${index + 1}`].begDa);

        if (+zeiten[`z${index + 1}`].za / +zeiten[`z${index + 1}`].n !== 1) {
          stop = true;
        } else if (+zeiten[`z${index}`].za / +zeiten[`z${index}`].n !== 1) {
          stop = true;
        } else if (b.diff(a, "days") === 1) {
          skippedTimes += 1;
        } else {
          stop = true;
        }

        if (stop === true) {
          kummu[`z${index}`] = calcDateDiff({
            begDa: zeiten[`z${index - skippedTimes}`].begDa,
            endDa: zeiten[`z${index}`].endDa,
            za: zeiten[`z${index}`].za,
            n: zeiten[`z${index}`].n,
          });
          stop = false;
          skippedTimes = 0;
        }
      } else if (
        index === Object.keys(zeiten).length &&
        Object.keys(kummu).length !== 0
      ) {
        kummu[`z${index}`] = calcDateDiff({
          begDa: zeiten[`z${index - skippedTimes}`].begDa,
          endDa: zeiten[`z${index}`].endDa,
          za: zeiten[`z${index}`].za,
          n: zeiten[`z${index}`].n,
        });
      }
    }
  }

  //Wenn bisher nichts kummuliert wurde. D. h. die Dienstzeit läuft von Anfang bis Ende durch
  if (Object.keys(kummu).length === 0) {
    kummu[`z${Object.keys(zeiten).length}`] = calcDateDiff({
      begDa: zeiten[`z1`].begDa,
      endDa: zeiten[`z${Object.keys(zeiten).length}`].endDa,
      za: 1,
      n: 1,
    });
  }
  for (let index = 1; index <= Object.keys(zeiten).length; index++) {
    if (
      zeiten[`z${index}`].rgfD &&
      typeof zeiten[`z${index}`].rgfD["jahre"] === "number" &&
      typeof zeiten[`z${index}`].rgfD["tage"] === "number"
    ) {
      if (kummu[`z${index}`]) {
        //when keine Zeiten vor 21 dann ganz normal
        jahre += kummu[`z${index}`][0];
        tage += kummu[`z${index}`][1];
      }
    } else {
      jahre = "NaN";
      tage = "NaN";
    }
  }

  deziJahre = Math.round((jahre + tage / 365) * 100) / 100;

  const zeitFaktor = Math.round(deziJahre * 1.79375 * 100) / 100;

  const rechRgs = Math.round((zeitFaktor + 7.175) * 100) / 100;
  const rgs = rechRgs > 71.75 ? 71.75 : rechRgs;

  return [jahre, tage, deziJahre, zeitFaktor, rgs, rechRgs, false, 0, kummu];
};

export const calcGesamtzeit85Misch = (zeiten) => {
  let jahre_vor_92 = 0;
  let tage_vor_92 = 0;
  let jahre_nach_92 = 0;
  let tage_nach_92 = 0;
  let skippedTimes = 0;
  let stop = false;
  let kummu = {};

  for (let index = 1; index <= Object.keys(zeiten).length; index++) {
    if (
      zeiten[`z${index}`].rgfD &&
      typeof zeiten[`z${index}`].rgfD["jahre"] === "number" &&
      typeof zeiten[`z${index}`].rgfD["tage"] === "number"
    ) {
      //die letzte Dienstzeit wird nicht durch gegangen, da dann index+1 einen Fehler wirft.
      //die letze Dienstzeit wird dann in der else abgefragt.
      //Wenn gar nichts zu trifft, siehe unten
      if (
        Object.keys(zeiten).length >= 2 &&
        index !== Object.keys(zeiten).length
      ) {
        const a = moment(zeiten[`z${index}`].endDa);
        const b = moment(zeiten[`z${index + 1}`].begDa);

        if (+zeiten[`z${index + 1}`].za / +zeiten[`z${index + 1}`].n !== 1) {
          stop = true;
        } else if (+zeiten[`z${index}`].za / +zeiten[`z${index}`].n !== 1) {
          stop = true;
        } else if (zeiten[`z${index}`].endDa === "1991-12-31") {
          stop = true;
        } else if (b.diff(a, "days") === 1) {
          skippedTimes += 1;
        } else {
          stop = true;
        }

        if (stop === true) {
          kummu[`z${index}`] = calcDateDiff({
            begDa: zeiten[`z${index - skippedTimes}`].begDa,
            endDa: zeiten[`z${index}`].endDa,
            za: zeiten[`z${index}`].za,
            n: zeiten[`z${index}`].n,
          });
          stop = false;
          skippedTimes = 0;
          if (zeiten[`z${index}`].endDa <= "1991-12-31") {
            jahre_vor_92 += kummu[`z${index}`][0];
            tage_vor_92 += kummu[`z${index}`][1];
          } else {
            jahre_nach_92 += kummu[`z${index}`][0];
            tage_nach_92 += kummu[`z${index}`][1];
          }
        }
      } else if (
        index === Object.keys(zeiten).length &&
        Object.keys(kummu).length !== 0
      ) {
        kummu[`z${index}`] = calcDateDiff({
          begDa: zeiten[`z${index - skippedTimes}`].begDa,
          endDa: zeiten[`z${index}`].endDa,
          za: zeiten[`z${index}`].za,
          n: zeiten[`z${index}`].n,
        });
        if (zeiten[`z${index}`].endDa <= "1991-12-31") {
          jahre_vor_92 += kummu[`z${index}`][0];
          tage_vor_92 += kummu[`z${index}`][1];
        } else {
          jahre_nach_92 += kummu[`z${index}`][0];
          tage_nach_92 += kummu[`z${index}`][1];
        }
      }
    } else {
      jahre_vor_92 = "NaN";
      tage_vor_92 = "NaN";
      jahre_nach_92 = "NaN";
      tage_nach_92 = "NaN";
    }
  }

  const dezi_vor_92 =
    Math.round((jahre_vor_92 + tage_vor_92 / 365) * 100) / 100;
  const zusätzliches_jahr = tage_vor_92 > 182 ? 1 : 0;
  const Zeiten_vor_92 =
    jahre_vor_92 + zusätzliches_jahr <= 15
      ? (jahre_vor_92 + zusätzliches_jahr) * 2
      : (jahre_vor_92 + zusätzliches_jahr) * 2 -
        (jahre_vor_92 + zusätzliches_jahr) +
        15;
  const ten_jahre_frist = 10 - jahre_vor_92 > 0 ? 10 - jahre_vor_92 : 0;
  const ten_jahre_frist_tage = 10 - jahre_vor_92 > 0 ? tage_vor_92 : 0;
  const Zeiten_nach_92 =
    Math.round(
      (jahre_nach_92 -
        ten_jahre_frist +
        ten_jahre_frist_tage / 365 +
        tage_nach_92 / 365) *
        100
    ) / 100;
  const ten_years =
    Math.round((10 - jahre_vor_92 - tage_vor_92 / 365) * 100) / 100;
  const rgs_vor_faktor = 35 + Zeiten_nach_92 + Zeiten_vor_92;
  const rgs_nach_faktor = Math.round(rgs_vor_faktor * 0.95667 * 100) / 100;
  const rgs = rgs_nach_faktor > 71.75 ? 71.75 : rgs_nach_faktor;
  return [
    jahre_vor_92,
    tage_vor_92,
    dezi_vor_92,
    jahre_nach_92,
    tage_nach_92,
    ten_years,
    Zeiten_vor_92,
    Zeiten_nach_92,
    rgs_vor_faktor,
    rgs_nach_faktor,
    rgs,
    false,
    0,
    kummu,
  ];
};

export const getDeziJahre = (zeiten) => {
  let jahre = 0;
  let tage = 0;

  // z. B. für dezi beim Abschlag
  jahre += zeiten[0];
  tage += zeiten[1];

  return Math.round((jahre + tage / 365) * 100) / 100;
};

//Zeiten addiert, ohne Altersbegrenzung für Abschlagsfreiheit
export const calcGesamtzeitOhneAlter = (zeiten) => {
  let jahre = 0;
  let tage = 0;
  let deziJahre = 0;
  let skippedTimes = 0;
  let stop = false;
  let kummu = {};

  for (let index = 1; index <= Object.keys(zeiten).length; index++) {
    if (
      zeiten[`z${index}`].rgfD &&
      typeof zeiten[`z${index}`].rgfD["jahre"] === "number" &&
      typeof zeiten[`z${index}`].rgfD["tage"] === "number"
    ) {
      //die letzte Dienstzeit wird nicht durch gegangen, da dann index+1 einen Fehler wirft.
      //die letze Dienstzeit wird dann in der else abgefragt.
      //Wenn gar nichts zu trifft, siehe unten
      if (
        Object.keys(zeiten).length >= 2 &&
        index !== Object.keys(zeiten).length
      ) {
        const a = moment(zeiten[`z${index}`].endDa);
        const b = moment(zeiten[`z${index + 1}`].begDa);

        if (+zeiten[`z${index + 1}`].za / +zeiten[`z${index + 1}`].n !== 1) {
          stop = true;
        } else if (+zeiten[`z${index}`].za / +zeiten[`z${index}`].n !== 1) {
          stop = true;
        } else if (b.diff(a, "days") === 1) {
          skippedTimes += 1;
        } else {
          stop = true;
        }

        if (stop === true) {
          kummu[`z${index}`] = calcDateDiff({
            begDa: zeiten[`z${index - skippedTimes}`].begDa,
            endDa: zeiten[`z${index}`].endDa,
            za: zeiten[`z${index}`].za,
            n: zeiten[`z${index}`].n,
          });
          stop = false;
          skippedTimes = 0;
        }
      } else if (
        index === Object.keys(zeiten).length &&
        Object.keys(kummu).length !== 0
      ) {
        kummu[`z${index}`] = calcDateDiff({
          begDa: zeiten[`z${index - skippedTimes}`].begDa,
          endDa: zeiten[`z${index}`].endDa,
          za: zeiten[`z${index}`].za,
          n: zeiten[`z${index}`].n,
        });
      }
    }
  }

  //Wenn bisher nichts kummuliert wurde. D. h. die Dienstzeit läuft von Anfang bis Ende durch
  if (Object.keys(kummu).length === 0) {
    kummu[`z${Object.keys(zeiten).length}`] = calcDateDiff({
      begDa: zeiten[`z1`].begDa,
      endDa: zeiten[`z${Object.keys(zeiten).length}`].endDa,
      za: 1,
      n: 1,
    });
  }
  for (let index = 1; index <= Object.keys(zeiten).length; index++) {
    if (
      zeiten[`z${index}`].rgfD &&
      typeof zeiten[`z${index}`].rgfD["jahre"] === "number" &&
      typeof zeiten[`z${index}`].rgfD["tage"] === "number"
    ) {
      if (kummu[`z${index}`]) {
        jahre += kummu[`z${index}`][0];
        tage += kummu[`z${index}`][1];
      }
    } else {
      jahre = "NaN";
      tage = "NaN";
    }
  }

  deziJahre = Math.round((jahre + tage / 365) * 100) / 100;

  return [jahre, tage, deziJahre, kummu];
};

export const getAbschlag = (data, detail, gesamtZeit) => {
  if (detail.grund === "1" || detail.grund === "5") {
    return [0, 0, 0, 0];
  }

  //maximaler Abschlag
  let maxAb = null;
  if (detail.grund === "2" || detail.grund === "6" || detail.grund === "7") {
    maxAb = 14.4;
  } else if (detail.grund === "3" || detail.grund === "4") {
    maxAb = 10.8;
  }

  //Referenzalter
  let refAl = null;
  if (detail.grund === "2" || detail.grund === "6" || detail.grund === "7") {
    refAl = calc_regel(data.birthDate);
  } else if (detail.grund === "3") {
    refAl = calc_abschlag_schwerb(data.birthDate);
  } else if (detail.grund === "4") {
    refAl = calc_abschlag_du(data.birthDate, detail.beg);
  }

  //Referenzalter aufbereiten für calc diff
  const refAlNew = moment(refAl).subtract(1, "d");

  //Calc Abschlag
  const zeiten = { begDa: detail.beg, endDa: refAlNew, za: 1, n: 1 };
  const dateDiff = calcDateDiff(zeiten);
  const deziJ = getDeziJahre(dateDiff);
  const abschlag = Math.round(deziJ * 3.6 * 100) / 100;

  //Prüfen ob 40/45 Dienstjahre erreicht, falls ja gleich wieder austeigen
  const alter_64 = calc_64(data.birthDate);

  if (detail.beg > alter_64) {
    if (calcGesamtzeitOhneAlter(gesamtZeit)[2] > 45 && detail.grund === "2") {
      return [0, 0, 0, 0, calcGesamtzeitOhneAlter(gesamtZeit)[2]];
    }
    if (
      calcGesamtzeitOhneAlter(gesamtZeit)[2] > 40 &&
      (detail.grund === "3" || detail.grund === "4")
    ) {
      return [0, 0, 0, 0, calcGesamtzeitOhneAlter(gesamtZeit)[2]];
    }
  }
  //nach dem referenzalter in ruhestand?
  if (abschlag <= 0) {
    return [0, 0, 0, 0];
  } else if (!abschlag) {
    return [0, 0, 0, 0];
  }

  //Referenzalter für ausgabe aufbereiten
  const renderRefAl = moment(refAl).subtract(1, "d").format("DD.MM.yyyy");
  return [maxAb, renderRefAl, abschlag, deziJ];
};

export const getUnabMind = (date, fzPer, kinder) => {
  const grundgehalt = !grundg("A03", 9, date)
    ? 0
    : grundg("A03", 9, date).betrag;
  const fz = !zulage("fz1", date, "A03")
    ? 0
    : Math.round(zulage("fz1", date, "A03").betrag * +fzPer) / 100;
  const fz_ub = calc_fz_ub({ beg: date, besO: "A", besG: "03" }, +kinder);
  const unabM = Math.round(((grundgehalt + fz) * 0.665 + fz_ub) * 100) / 100;
  return unabM;
};

export const getAufschlag = (data, detail) => {
  //nur bei Grund Regelalter und Schulhalbjahr
  if (
    detail.grund === "1" &&
    detail.schule > detail.beg &&
    detail.schuldienst
  ) {
    const regel = calc_regel(data.birthDate);
    //Referenzalter aufbereiten für calc diff
    const refAlNew = moment(detail.schule).subtract(1, "d");
    const dateDiff = calcDateDiff({
      begDa: regel,
      endDa: refAlNew,
      za: 1,
      n: 1,
    });
    const deziJ = getDeziJahre(dateDiff);
    const aufschlag = Math.round(deziJ * 3.6 * 100) / 100;

    //Referenzalter für ausgabe aufbereiten
    const renderRefAl = moment(detail.schule)
      .subtract(1, "d")
      .format("DD.MM.yyyy");

    return [renderRefAl, aufschlag, deziJ];
  } else {
    return [0, 0, 0];
  }
};

export const calc_fz_ub = (detail, fzK_a_n) => {
  const fzK =
    Math.round(
      zulage("fzK", detail.beg, detail.besO + detail.besG).betrag *
        (fzK_a_n > 2 ? 2 : fzK_a_n) *
        100
    ) / 100;
  const fzK1 =
    Math.round(
      zulage("fzK1", detail.beg, detail.besO + detail.besG).betrag *
        (fzK_a_n > 1 ? 1 : fzK_a_n) *
        100
    ) / 100;
  const fzK2 =
    fzK_a_n >= 2
      ? Math.round(
          zulage("fzK2", detail.beg, detail.besO + detail.besG).betrag *
            (fzK_a_n - 1) *
            100
        ) / 100
      : 0;
  const fzK3 =
    fzK_a_n >= 3
      ? Math.round(
          zulage("fzK3", detail.beg, detail.besO + detail.besG).betrag *
            (fzK_a_n - 2) *
            100
        ) / 100
      : 0;

  return Math.round((fzK + fzK1 + fzK2 + fzK3) * 100) / 100;
};

export const get_stva = (date, bes, stkl) => {
  const Mdate = moment(date).format("YYYY-MM-DD");

  const filterf = (value, index, array) => {
    return (
      value.von <= stkl &&
      value.bis >= stkl &&
      value.beg <= Mdate &&
      value.end > Mdate &&
      value.gruppe === bes
    );
  };

  const stva_pro = stva.filter(filterf);

  if (stva_pro.length === 0) {
    stva_pro.satz = 0;
  }

  return stva_pro[0] ? stva_pro[0] : stva_pro;
};

export const kuerzVA = (oldVal, inc) => {
  return Math.round(((oldVal * inc) / 100) * 100) / 100;
};

export const indiVA = (date, besO, besG, stufe, zul, sockel) => {
  const bes = grundg(besO + besG, stufe, date)["betrag"];
  const erhoehung = Math.round(((+sockel * 100) / (+bes + +zul)) * 100) / 100;
  if (bes) {
    return [erhoehung, +bes + +zul];
  } else {
    return [0, 0];
  }
};

export const DmToEuro = (dm) => {
  return Math.round((dm / 1.95583) * 100) / 100;
};

//Art. 26 Abs. 6
export const Art26Abs6 = (versB, rente85) => {
  if (versB.versBezugAfterFz > +versB.versBezugAfterMin + +versB.stva_eur) {
    return false;
  } else {
    const kuerzung =
      Math.round((+versB.versBezugAfterMin - +versB.versBezugAfterFz) * 100) /
      100;
    const restversorgung =
      Math.round(
        (+versB.versBezugAfter85Rente - +versB.stva_eur - +kuerzung) * 100
      ) / 100;
    const gesamtversorgung =
      Math.round((+restversorgung + +rente85) * 100) / 100;
    const aufstockung =
      Math.round(
        (+versB.versBezugAfterMin - +gesamtversorgung < 0
          ? 0
          : +versB.versBezugAfterMin - +gesamtversorgung) * 100
      ) / 100;
    const restversAufstock =
      Math.round((+restversorgung + +aufstockung) * 100) / 100;
    const verblZahlbetrag =
      +versB.versBezugAfterFz > restversAufstock
        ? +versB.versBezugAfterFz
        : restversAufstock;
    const weitererRuhensbetrag =
      Math.round(
        (+versB.versBezugAfterMin + +versB.rente85 - verblZahlbetrag) * 100
      ) / 100;
    return weitererRuhensbetrag;
  }
};

export const convStatus = (status) => {
  switch (status) {
    case 1:
      return "In Bearbeitung";
    case 2:
      return "Abgeschlossen";
    default:
      break;
  }
};

export const convCreator = (creator) => {
  switch (creator) {
    case 1:
      return "L. Baumgartl";
    case 2:
      return "M. Vers";
    default:
      break;
  }
};

export const fetchBVG = async (date, mde) => {
  try {
    const response = await fetch(
      //`http://localhost/versorgung/rest-api/other.php?typ=bvg`,
      `https://versorgung.hosting142616.a2e76.netcup.net/rest-api/other.php?typ=bvg`,
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify({ date: `${date}`, mde: mde }),
      }
    );
    if (response) {
      const fetchDatabase = await response.json();
      return await fetchDatabase.value;
    }
  } catch (e) {
    console.error(e);
  } finally {
  }
};

export const calc_rgfB = (detail, data, date) => {
  const grundgehalt = grundg(
    detail.besO + detail.besG,
    detail.stufe,
    date
  ).betrag;
  const fam =
    data.fam === "1" || data.fam === "2" || data.fam === "5" || data.fam === "9"
      ? zulage("fz1", date, detail.besO + detail.besG).betrag
      : 0;
  const strZulage = zulage("struk", date, detail.besO + detail.besG).betrag;

  return [grundgehalt, fam, strZulage];
};

export const calc_versB = (
  gesamtzeit,
  detail,
  date,
  data,
  reduxZeiten,
  va,
  rente,
  hgr85
) => {
  let mindestversorgung = false;
  let rgs =
    gesamtzeit.gesamtZ[4] >= gesamtzeit.gesamtZMisch[10]
      ? gesamtzeit.gesamtZ[4]
      : gesamtzeit.gesamtZMisch[10];

  const rgfB = calc_rgfB(detail, data, date);
  const grundG = rgfB[0];
  const fam = rgfB[1];
  const strZ = rgfB[2];
  const versBezug =
    Math.round(((rgs * (rgfB[0] + rgfB[1] + rgfB[2])) / 100) * 100) / 100;
  //Abschlag
  //Prüfung ob Abschlagsfrei nach 40/45 Jahren in der Funktion getAbschlag
  const abschlag = getAbschlag(data, detail, reduxZeiten.zeiten);
  const abschlagPro = abschlag[2] > abschlag[0] ? abschlag[0] : abschlag[2];
  const abschlagfreiJahre = abschlag[4] ? abschlag[4] : 0;
  const abschlagEur =
    -Math.round(((versBezug * abschlagPro) / 100) * 100) / 100;

  //Aufschlag
  const aufschlag = getAufschlag(data, detail);
  const aufschlagEur =
    Math.round(((versBezug * aufschlag[1]) / 100) * 100) / 100;

  //Neuer Versorgungsbezug
  const versBezugAfterAbAuf =
    Math.round((versBezug + abschlagEur + aufschlagEur) * 100) / 100;

  //Familienzuschlag kind
  const newFzK = data.fzK.replace(",", ".");
  const fzK = calc_fz_ub(detail, newFzK);
  const versBezugAfterFz = Math.round((versBezugAfterAbAuf + fzK) * 100) / 100;

  //abgleich amtsunabh. Mindestversorgung
  const newFzPer = data.fzPer.replace(",", ".");
  const unabMind = getUnabMind(date, newFzPer, newFzK);

  //abgleich amtsabhän. Mindestversorgung
  const abhMind =
    Math.round((rgfB[0] + rgfB[1] + rgfB[2]) * 0.35 * 100) / 100 + fzK;

  //überschreiben der versorgungsbezüge
  let versBezugAfterMin = 0;
  if (versBezugAfterFz <= unabMind && unabMind > abhMind) {
    versBezugAfterMin = unabMind;
    mindestversorgung = true;
  } else if (versBezugAfterFz <= abhMind && abhMind > unabMind) {
    versBezugAfterMin = abhMind;
    mindestversorgung = true;
  } else {
    versBezugAfterMin = versBezugAfterFz;
  }

  //VA
  const versBezugAfterVA =
    Math.round((versBezugAfterMin + va.kuerzung) * 100) / 100;

  //Unfallausgleich
  let unfallausgleich = 0;
  if (detail.grund === "5") {
    unfallausgleich = fetchBVG(detail.beg, hgr85.mde);
  }
  const versBezugAfterUnfall =
    Math.round((versBezugAfterVA + unfallausgleich) * 100) / 100;

  //Stva
  let stva_eur = 0;
  let stva = 0;
  //wenn unabh. Mindestv. dann 0
  if (versBezugAfterMin === unabMind) {
    stva_eur = 0;
    stva = 0;
  } else {
    stva = get_stva(detail.beg, detail.besO + detail.besG, data.stkl).satz;
    stva_eur = -Math.round(((stva * versBezugAfterMin) / 100) * 100) / 100;
  }
  const versBezugAfterStva =
    Math.round((versBezugAfterUnfall + stva_eur) * 100) / 100;

  //Rente-VNG
  const versBezugAfterVNGRente =
    Math.round((versBezugAfterStva - rente.renteVNGnachHöher) * 100) / 100;

  //Rente-85
  const versBezugAfter85Rente =
    Math.round((versBezugAfterVNGRente + hgr85.ruhe85) * 100) / 100;

  //Rente-85-26abs6
  const versBezugAfter26abs6 =
    Math.round((versBezugAfter85Rente - hgr85.art26abs6) * 100) / 100;

  return [
    grundG,
    fam,
    strZ,
    versBezug,
    abschlagEur,
    aufschlagEur, //5
    versBezugAfterAbAuf,
    fzK,
    versBezugAfterFz,
    unabMind,
    abhMind, //10
    versBezugAfterMin,
  ];
};

export const getHGR83 = (
  redux83,
  detail,
  bezug,
  data,
  gesamtzeit,
  reduxZeiten,
  allHGR,
  va,
  rente,
  hgr85
) => {
  //Höchstgrenze
  const stufeArray = getMaxStufe(detail.besO, detail.besG);
  const stufe = Math.max(...stufeArray);
  const grundgehalt = grundg(
    detail.besO + detail.besG,
    stufe,
    redux83.beg
  ).betrag;

  const newFzPer = data.fzPer.replace(",", ".");
  let fam = zulage("fz1", redux83.beg, detail.besO + detail.besG)["betrag"];
  fam =
    data.fam === "1" || data.fam === "2" || data.fam === "5" || data.fam === "9"
      ? Math.round(fam * +newFzPer) / 100
      : 0;
  const strZulage = zulage("struk", redux83.beg, detail.besO + detail.besG)[
    "betrag"
  ];
  const rgfB =
    Math.round((+grundgehalt + +fam + +bezug.sumZulagen + +strZulage) * 100) /
    100;

  const rgs = detail.grund === "4" ? 71.75 : 100;

  const erh = detail.grund === "4" ? 525 : 0;
  const ruhegehalt = Math.round(((rgfB * rgs) / 100) * 100) / 100;

  //Familienzuschlag kind
  const newFzK = data.fzK.replace(",", ".");
  const fzK = calc_fz_ub(
    { beg: redux83.beg, besO: detail.besO, besG: detail.besG },
    newFzK
  );

  const hgr = +ruhegehalt + +fzK + erh;

  //Gesamteinkünfte
  const kirchVers = calc_versB(
    gesamtzeit,
    detail,
    redux83.beg,
    data,
    reduxZeiten,
    va,
    rente,
    hgr85
  );
  //mehrere Einküfte zu diesem Zeitraum?
  let einkommen = 0;
  for (let index = 0; index < allHGR.count; index++) {
    if (
      allHGR[`hgr83_${index + 1}`].beg <= redux83.beg &&
      allHGR[`hgr83_${index + 1}`].end >= redux83.beg
    ) {
      einkommen += +allHGR[`hgr83_${index + 1}`].einkommen;
    }
  }
  const gesamtVers = kirchVers[11] + +einkommen - 83.33;

  //Grunds Ruhensbetrag
  let ruhe83 =
    Math.round((gesamtVers - hgr) * 100) / 100 > 0
      ? Math.round((gesamtVers - hgr) * 100) / 100
      : 0;

  //restvers
  let versBezugAfter83 = Math.round((kirchVers[11] - ruhe83) * 100) / 100;
  //Mindest
  const mind = Math.round(kirchVers[11] * 0.2 * 100) / 100;
  let versBezugAfterMin = versBezugAfter83;
  let ruhe83AfterMin = ruhe83;
  if (redux83.mindest === true) {
    if (versBezugAfter83 < mind) {
      versBezugAfterMin = mind;
      ruhe83AfterMin = kirchVers[11] - mind;
    }
  }
  if (versBezugAfterMin < 0) {
    ruhe83AfterMin = kirchVers[11];
  }
  return [
    grundgehalt,
    stufe,
    fam,
    strZulage,
    rgs,
    erh,
    hgr,
    ruhegehalt,
    gesamtVers,
    ruhe83,
    versBezugAfter83, //10
    versBezugAfterMin,
    ruhe83AfterMin,
    mind,
    kirchVers[11],
    fzK, //15
    einkommen,
  ];
};

export const get_dates_with_changed_values = (start, hgr83, hgr85) => {
  let dates = [start];
  //besoldungserhöhungen aus der json der zulagen
  //anhand des FZ-Kind für 1 kind, da diese zeile nur einmal gefunden werden kann
  const besE = zulagen.filter(
    (value, index, array) => value.art === "fzK1" && value.beg > start
  );
  besE.forEach((element) => {
    dates.push(element.beg);
  });

  //einkommensanrechnungen
  for (let index = 0; index < hgr83.count; index++) {
    if (
      hgr83[`hgr83_${index + 1}`].beg !== "0000-00-00" &&
      hgr83[`hgr83_${index + 1}`].beg !== ""
    ) {
      if (hgr83[`hgr83_${index + 1}`].beg > start) {
        dates.push(hgr83[`hgr83_${index + 1}`].beg);
      }
    }
  }

  return dates;
};
